import React from 'react';
import { AvatarGroup, Avatar, Dropdown, DropdownTrigger, DropdownItem, DropdownMenu, CardFooter, Chip, Card, CardBody, CardHeader, Tooltip } from '@nextui-org/react';
import { StringLimit, timeDifference, titleCreater, colorPicker, avatarName } from '../controllers/basicControllers';
import FetchUserDataAndRenderAvatar from '../pages/Collections/FetchUserDataAndRenderAvatar';
import AvatarComponent from "../NextUI/Avatar";

const UserActionsDropdown = ({ data, EditOpen, DeleteWarningHandle }) => (
    <Dropdown placement="bottom-end">
        <DropdownTrigger>
            <div className='flex items-center justify-evenly cursor-pointer'>
                <span className='text-md font-bold text-slate-500'>...</span>
            </div>
        </DropdownTrigger>
        <DropdownMenu aria-label="Profile Actions" variant="flat">
            <DropdownItem key="edit" onClick={() => EditOpen(data)}>
                <div className='flex items-center gap-2'>
                    <lord-icon
                        src="https://cdn.lordicon.com/pflszboa.json"
                        colors={`primary:#000000`}
                        style={{ width: "16px", height: "16px" }}>
                    </lord-icon>
                    Edit
                </div>
            </DropdownItem>
            <DropdownItem key="system">
                <div className='flex items-center gap-2'>
                    <lord-icon
                        src="https://cdn.lordicon.com/vuiggmtc.json"
                        trigger="hover"
                        colors={`primary:#000000`}
                        style={{ width: "16px", height: "16px" }}>
                    </lord-icon>
                    Archive
                </div>
            </DropdownItem>
            <DropdownItem onClick={() => DeleteWarningHandle(data)} key="configurations" className='text-danger' color="danger">
                <div className='flex items-center gap-2'>
                    <lord-icon
                        src="https://cdn.lordicon.com/wpyrrmcq.json"
                        colors="primary:#e1426c"
                        style={{ width: "16px", height: "16px" }}>
                    </lord-icon>
                    Delete
                </div>
            </DropdownItem>
        </DropdownMenu>
    </Dropdown>
);

const CardTags = ({ tags, colorIndex }) => (
    <div className='flex pt-2 gap-3'>
        {tags && tags !== null && tags?.slice(0, 3).map((tag, index) => (
            <Chip key={index} size='sm' color={colorIndex} variant='flat'>
                {tag}
            </Chip>
        ))}
        {tags?.length > 3 && (
            <Tooltip
                content={tags.slice(3).map((tag, index) => (
                    <div key={index}>{tag}</div>
                ))}
                placement='bottom'
                size='sm'
                closeDelay={10}
            >
                <Chip size='sm' color={colorIndex} variant="flat">
                    + {tags?.length - 3}
                </Chip>
            </Tooltip>
        )}
    </div>
);

const CardAvatarGroup = ({ MurgedUsers, templates }) => (
    MurgedUsers && <AvatarGroup
        size='sm'
        isBordered
        max={3}
        total={MurgedUsers?.length}
        renderCount={(count) => (
            <p className="text-xs text-foreground font-medium ms-2">{count > 3 ? `+${count - 3}` : ""}</p>
        )}
    >
        {MurgedUsers?.map((user, idx) => (
            templates ? <AvatarComponent size="sm" color={colorPicker(user?.id)} name={avatarName(user?.attributes?.Name)} />
                : <FetchUserDataAndRenderAvatar key={idx} userId={user.id} />
        ))}
    </AvatarGroup>
);

const Cards = ({ data, userId, MurgedUsers, colorIndex, EditOpen, Navigate, DeleteWarningHandle, ActionVisible, Logo, templates }) => {
    const UserOptions = data?.attributes?.author?.data?.id === userId;

    return (
        <Card className="max-w-[340px] min-w-[340px] min-h-[240px] h-full p-2 border border-divider cursor-pointer" shadow='none' onClick={() => Navigate(`${data.id}`)}>
            <CardHeader className="justify-between flex items-center">
                <div className="flex gap-5">
                    <Avatar isBordered radius="full" src={Logo || ''} name={avatarName(data?.attributes?.Name)} size="md" color={colorIndex} />
                    <div className="flex flex-col gap-1 items-start justify-center">
                        <h4 className="text-small font-semibold leading-none text-default-600">{titleCreater(data?.attributes?.Name)}</h4>
                        <h5 className="text-small tracking-tight text-default-400">Updated {timeDifference(data?.attributes?.createdAt)}</h5>
                    </div>
                </div>
                {ActionVisible && UserOptions && <UserActionsDropdown data={data} EditOpen={EditOpen} DeleteWarningHandle={DeleteWarningHandle} />}
            </CardHeader>
            <CardBody className="px-3 py-0 pb-4 text-small flex flex-col justify-between text-default-500">
                <p className='pb-4'>
                    {StringLimit(data?.attributes?.Description || data?.attributes?.Context, 120)}
                </p>
                <CardTags tags={data?.attributes?.Tags?.tags} colorIndex={colorIndex} />
            </CardBody>
            <CardFooter className="gap-8 h-12 flex items-center justify-between">
                <CardAvatarGroup MurgedUsers={MurgedUsers} templates={templates} />
                <div className='flex items-center gap-2'>
                    <div className='flex items-center'>
                        <lord-icon
                            src="https://cdn.lordicon.com/prjooket.json"
                            colors={`primary:#000000`}
                            trigger="hover"
                            style={{ width: "20px", height: "20px" }}>
                        </lord-icon>
                        <p className="text-xs text-foreground font-medium">
                            {data?.attributes?.Tags?.tags?.length || 0}
                        </p>
                    </div>
                </div>
            </CardFooter>

        </Card>
    );
}

export default Cards;
