import React from 'react';
import { toast } from "react-hot-toast";
import { iconRender } from '../controllers/basicControllers';

const CustomToast = (message, status) => {

    return (
        toast.custom((t) => (
            <div
                className={`${t.visible ? 'animate-enter' : 'animate-leave'
                    } max-w-xs w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
            >
                <div className="p-2 flex flex-row gap-2 items-center">
                    {status === "success" ? iconRender("https://cdn.lordicon.com/oqdmuxru.json", "success") : iconRender("https://cdn.lordicon.com/ygvjgdmk.json", "error")}
                    <p className="mt-1 text-sm text-gray-500">
                        {message}
                    </p>
                </div>
            </div>
        ))

    )
}

export default CustomToast