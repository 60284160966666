import React from 'react';
import { Card, CardHeader, CardBody, CardFooter, Tooltip, ModalHeader, ModalBody, TableRow, TableCell, useDisclosure } from "@nextui-org/react";
import AvatarComponent from "../../NextUI/Avatar";
import { avatarName, colorPicker, dateFormat, iconRender, StringLimit, timeDifference, titleCreater } from "../../controllers/basicControllers";
import TableComponent from "../../NextUI/Table";
import ModalPopup from "../../pages/Collections/ModalPopup";

const TemplateCard = ({ data, isSelected, onSelect }) => {
    const { isOpen: isTable, onOpen: onTable, onOpenChange: onTableChange } = useDisclosure();

    const headerComponent = ["Name", "Description", "Created At"];

    const bodyComponent = () => {
        return data?.attributes?.data_products?.data?.map((item, index) => (
            <TableRow key={`${index}`} className='cursor-pointer'>
                <TableCell>{titleCreater(item?.attributes?.Name)}</TableCell>
                <TableCell>{StringLimit(item?.attributes?.Description, 50)}</TableCell>
                <TableCell>{dateFormat(item?.attributes?.createdAt)}</TableCell>
            </TableRow>
        ));
    }

    const EditModal = () => (
        <>
            <ModalHeader>data products</ModalHeader>
            <ModalBody>
                <TableComponent headerComponent={headerComponent} bodyComponent={bodyComponent} emptyContent={"No rows created yet."} />
            </ModalBody>
        </>
    );

    return (
        <div onClick={() => onSelect()}>
            <Card
                className={`max-w-[340px] min-w-[340px] min-h-[240px] h-full p-2 border cursor-pointer ${isSelected ? 'border-secondary' : 'border-divider'}`}
                shadow='none'
                onClick={(e) => {
                    e.stopPropagation(); // Prevents click event from bubbling up to parent
                    onSelect();
                }}
            >
                <CardHeader className="justify-between flex items-center">
                    <div className="flex gap-5">
                        <AvatarComponent
                            isBordered
                            radius="full"
                            src={""}
                            className=""
                            name={avatarName(data?.attributes?.Name)}
                            size="md"
                            color={colorPicker(data?.id)}
                        />
                        <div className="flex flex-col gap-1 items-start justify-center">
                            <h4 className="text-small font-semibold leading-none text-default-600">{titleCreater(data?.attributes?.Name)}</h4>
                            <h5 className="text-small tracking-tight text-default-400">Updated {timeDifference(data?.attributes?.createdAt)}</h5>
                        </div>
                    </div>
                </CardHeader>
                <CardBody>
                    <p className='text-sm'>{StringLimit(data?.attributes?.Description, 200)}</p>
                </CardBody>
                <CardFooter>
                    <div className="w-full flex flex-row justify-end">
                        <Tooltip delay={0} closeDelay={0} content={data?.attributes?.data_products?.data?.map((item) => <div key={item?.attributes?.Name}>{item?.attributes?.Name}</div>)} placement='bottom' size='sm'>
                            <div className='flex flex-row'>
                                {iconRender("https://cdn.lordicon.com/prjooket.json", "Dataproduct")}
                                <p className="text-xs text-foreground font-medium">
                                    {data?.attributes?.data_products?.data?.length || 0}
                                </p>
                            </div>
                        </Tooltip>
                    </div>
                </CardFooter>
            </Card>
            <ModalPopup
                isOpen={isTable}
                size="2xl"
                onOpenChange={onTableChange}
                ModalBodyData={EditModal}
                footer={""}
                scrollBehavior="inside"
            />
        </div>
    )
}

export default TemplateCard;
