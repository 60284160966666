import React from "react";
import ViewChart from "../Charts/ViewChart";
import { convertKeysToLowercase } from "../../controllers/basicControllers";

const HeatAnomaly = ({ resp, config, data }) => {
  console.log(resp, config, data);

  const heatConfig = resp.payload["heat-config"];
  const sql = resp.payload.sql;
  const dbConfig = {
    ...convertKeysToLowercase(config.config),
    dbType: config.data_product.toLowerCase(),
  };
  console.log(sql, dbConfig);

  return (
    <div className="flex items-center justify-center">
      <ViewChart
        chartHeight="h-full"
        showAdditionalDetails={false}
        showCard={false}
        cardClasses="w-full px-4"
        sql={sql}
        chartType="heat map"
        chartInfo={heatConfig}
        data={{ query_result: data }}
        dbConfig={dbConfig}
      />
    </div>
  );
};

export default HeatAnomaly;
