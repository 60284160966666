import { Avatar, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, useDisclosure } from '@nextui-org/react';
import React, { useEffect, useState } from 'react'
import { CommonCollectionFilterApi, DeleteSingleAttribute,userLoginDetails } from '../../controllers/strapiController';
import { StringLimit, titleCreater } from "../../controllers/basicControllers";
import { tableSkeleton } from '../../components/Skeleton';
import { useParams, useNavigate } from "react-router-dom";
import { DatabaseLogo } from './ConnectorData';
import DropdownActions from '../../components/DropdownActions';
import DeleteModal from "../../components/DeleteModal";
import { useDispatch } from "react-redux";
import { reDirect } from '../../redux/actions';
import EmptyArray from "../../components/EmptyArray";
import { toast } from "react-hot-toast";
import NotFound from "../../components/NotFound";
import ServerError from "../../components/ServerIssue";
import UnAuthorized from "../../components/UnAuthorized";
import Forbbiden from "../../components/Forbbiden";
import Paginate from '../../components/Pagination';
import { delete_document, getSingleConnection } from '../../controllers/vectordbController';

const ConnectorTable = () => {
    // INITIALIZATION
    const [Connector, setConnector] = useState([]);
    const [Loader, setLoader] = useState(true);
    const [selectedItem, setSelectedItem] = useState('');
    const [notFound, setNotFound] = useState(false);
    const [forbidden, setForbidden] = useState(false);
    const [serverIssue, setServerIssue] = useState(false);
    const [authorized, setAuthorized] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [reloader, setReloader] = useState('')
    const CollectionName = "collections";
    const type="connectors";
    const params = useParams();
    const navigate = useNavigate();
    const LogoData = DatabaseLogo;
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onOpenChange: onDeleteOpenChange } = useDisclosure();
    const dispatch = useDispatch();
    const userData=userLoginDetails();
    const userID=userData?.userId

    // API RENDERING
    useEffect(() => {
        setLoader(true)
        const url = `filters[space][Name][$eq]=${params._spaceid}&filters[Type][$eq]=${type}&sort[0]=createdAt:desc&[populate][author][on]&[populate][connector_list][on]&[populate][viewed][on]&[populate][requests][on]&[populate][teams][on]&[populate][space][on]&[populate][database][on]&[populate][data_product][on]`
        CommonCollectionFilterApi(CollectionName, url)
            .then((data) => {
                if (data?.error) {
                    if (data?.error?.status === 404) { setNotFound(true) }
                    else if (data?.error?.status === 403) { setForbidden(true) }
                    else if (data?.error?.status === 500) { setServerIssue(true) }
                    else if (data?.error?.status === 401) { setAuthorized(true) }
                    else {
                        toast.error("Something went wrong with collections, please try after some time.")
                    }
                }
                else if (data?.data?.length > 0) {
                    setLoader(false);
                    setConnector(data.data);
                }
                else { setLoader(false) }
            })
            .catch((error) => {
                toast.error("Something went wrong with collections, please try after some time.")
            })
    }, [reloader])


    // EDIT POPUP OPEN HANDLER
    const EditOpen = (data) => {
        navigate(`${data.id}`);
        dispatch(reDirect("Settings"))
    }

    // DELTE WARMING POPUP OPEN HANDLER
    const DeleteWarningHandle = (data) => {
        onDeleteOpen();
        setSelectedItem(data);
    }

    // DELETE TOOLS HANDLER
    
  const DeleteHandler = async () => {
    setLoader(true)
    try {
      const response = await DeleteSingleAttribute('collections', selectedItem.id)
        
        if (response) {
            setReloader(response);
          setLoader(false);
        }

        const vectorName='data-products';
        const documentsDataPromise = getSingleConnection(vectorName, params?._spaceid, selectedItem.id);
        
        const [documentsDataResult] = await Promise.all([documentsDataPromise]);

        const documentsDataArray = documentsDataResult?.documents || [];
        documentsDataArray.forEach(element => {
            delete_document(vectorName, element?.payload?.id, params?._spaceid)
                .catch(error => console.error("Error deleting documentData:", error));
        });

    } catch (error) {
        console.error("Error deleting collection or handling documents:", error);
    }
};

    const navigateHandler = (data) => {
        navigate(`${data.id}`);
        dispatch(reDirect("Workbench"))
    }

    const recordsPerPage = 10;

    // Calculate indexes for pagination
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = Connector && Connector.slice(indexOfFirstRecord, indexOfLastRecord);
  

    // OTHER ERROR PAGES
    if (notFound) return <NotFound />;
    if (serverIssue) return <ServerError />;
    if (authorized) return <UnAuthorized message='Authorization error.' />;
    if (forbidden) return <Forbbiden />;

    // RETURN UI
    return (
        <div className=''>
            {Loader ? tableSkeleton() :
                <>{Connector?.length > 0 ?
                    <Table removeWrapper aria-label="Example static collection table">
                        <TableHeader>
                            <TableColumn>App</TableColumn>
                            <TableColumn>Context</TableColumn>
                            <TableColumn></TableColumn>
                        </TableHeader>
                        <TableBody>
                            {currentRecords?.map((data, index) => {
                                return <TableRow key={`${index}`} className='cursor-pointer hover:bg-grey-200' onClick={() => navigateHandler(data)}>
                                    <TableCell>
                                        <div className='flex gap-4 items-center'>
                                            <Avatar size='sm' src={data?.attributes?.data_product?.data?.attributes?.Image} />
                                            <div>
                                                <div className='font-semibold'>
                                                    {titleCreater(data?.attributes?.Name)}
                                                </div>
                                                <div className='text-xs text-gray'>
                                                    {data?.attributes?.data_product?.data !== null ? data?.attributes?.data_product?.data?.attributes?.Name: '--'}
                                                </div>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className='flex gap-2 items-center'>
                                            {StringLimit(data?.attributes?.Description, 100)}
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <DropdownActions  EditOpen={() => EditOpen(data)} DeleteWarningHandle={() => DeleteWarningHandle(data)} />
                                    </TableCell>
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                    : <EmptyArray message={`No connectors created yet. Click on "Add a connector" to create your connector`} />
                }
                    <>
                        {Connector.length > 10 && <div className='flex justify-center pt-8'>
                            <Paginate
                                totalPage={Connector}
                                initialPage={"1"}
                                id={userID}
                                currentRecords={currentRecords}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                recordsPerPage={recordsPerPage}
                            />
                        </div>
                        }
                    </>
                </>
            }
            <DeleteModal
                isOpen={isDeleteOpen}
                onOpenChange={onDeleteOpenChange}
                deleteData={""}
                setDeleteData={""}
                Collectionname={"tools"}
                setLoader={setLoader}
                DeleteHandler={DeleteHandler}
            />
        </div>
    )
}

export default ConnectorTable;