import { Button, Chip, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Switch, Textarea, Tooltip } from '@nextui-org/react'
import React, { useState } from 'react'
import { colorPicker } from '../../controllers/basicControllers';
import { Info } from 'lucide-react';

const SaveModal = ({isOpen,setIsOpen ,name,setName,desc,setDesc,dataProduct}) => {
    const [tags, setTags] = useState([]);
      const [isPublic, setIsPublic] = useState(true);
      const [tagInput, setTagInput] = useState("");
      const handleKeyDown = (e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          if (tags.includes(tagInput.trim()) || tagInput.trim() === "") {
            return;
          } else {
            setTags((prev) => [...prev, tagInput.trim()]);
            setTagInput("");
          }
        } else {
          return;
        }
      };
  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalContent>
            <ModalHeader>Save Anomaly</ModalHeader>
            <ModalBody className='pb-20' >
                <Input labelPlacement='outside' size='sm' value={name} onChange={(e) => setName(e.target.value)} label="Name" />
                <Textarea labelPlacement='outside' size='sm' value={desc} onChange={(e) => setDesc(e.target.value)} label="Description" />
                <div className="flex items-center justify-between ">
            <div className="flex items-center gap-1" >
            <div>Public</div>
            <Tooltip content="Allowed only if Data Product is public" ><Info height={16} /></Tooltip>
            </div>
            <Switch isDisabled={!dataProduct?.payload?.isPublic} isSelected={isPublic} onValueChange={setIsPublic} />
          </div>
          <div className="flex flex-col gap-2">
            <div>Tags</div>
            <div className="bg-default-100 py-2 rounded-lg">
              <div className="flex flex-wrap gap-2 px-2">
                {tags.map((it, index) => (
                  <Chip
                    onClose={() =>
                      setTags((prev) => prev.filter((item) => it !== item))
                    }
                    variant="flat"
                    key={index}
                    size="sm"
                    color={colorPicker(it.length)}
                  >
                    {it}
                  </Chip>
                ))}
              </div>
              <Textarea
                classNames={{
                  inputWrapper: "data-[hover=true]:bg-default-100 shadow-none ",
                }}
                placeholder="Type then press enter"
                onKeyDown={handleKeyDown}
                value={tagInput}
                onChange={(e) => setTagInput(e.target.value)}
              />
            </div>
          </div>
            </ModalBody>
            <ModalFooter className='flex items-center justify-end' >
                <Button color='primary' >Save</Button>
            </ModalFooter>
        </ModalContent>
    </Modal>
  )
}

export default SaveModal
