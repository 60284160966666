import React, { useState, useEffect } from 'react';
import { Button, Chip } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { CreateData, getAllData } from "../../controllers/strapiController";
import { InviteUser } from '../../controllers/strapiController'
import CryptoJS from "crypto-js";
import { createCollection } from '../../controllers/vectordbController';
import { colorPicker } from '../../controllers/basicControllers';
import { toast } from "react-hot-toast";

const Invitedusers = ({ onPrevious, onNext, formData, setFormData, userID, setLoader }) => {
    const [tagInput, setTagInput] = useState('');
    const [emails, setEmails] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const [displayedItems, setDisplayedItems] = useState();
    const [validatetags, setValidatetags] = useState('');
    const [user, setUser] = useState([]);
    const text = tagInput.trim().toLowerCase();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const navigate = useNavigate();

    const handleShowMore = () => {
        setDisplayedItems(emails);
        setShowAll(true);
    };

    const handleTagRemove = (tagToRemove) => {
        setEmails(emails.filter(tag => tag !== tagToRemove));
        setFormData(() => ({ ...formData, members: emails.filter(tag => tag !== tagToRemove) }))
    };

    const handleShowLess = () => {
        emails && setDisplayedItems(emails.slice(0, 5));
        setShowAll(false);
    };

    const validForm = () => {
        var isValid = true;

        if (text !== '' && emails.includes(text)) {
            isValid = false;
            setValidatetags("Entered email already exists.")
        }

        if (text !== '' && !emailRegex.test(text)) {
            isValid = false;
            setValidatetags("Enter a valid email.")
        }

        return isValid;
    }

    const handleKeyPress = (e) => {
        const validData = validForm();
        if (tagInput.trim() !== '' && e.key === "Enter") {
            if (validData) {
                setEmails((previous) => [text, ...previous]);
                setValidatetags("")
                const relatedUser = user && user.find((data) => data.email.includes(text))
                if (relatedUser) {
                    setFormData((previous) => ({ ...previous, users: [...previous.users, relatedUser.id] }));
                }
                setTagInput('');
            }
        }
    }

    useEffect(() => {
        if (showAll) {
            handleShowMore()
        } else {
            setDisplayedItems(emails.slice(0, 5));
        }
    }, [emails]);

    useEffect(() => {
        getAllData("users")
            .then((data) => setUser(data))
            .catch((error) => toast.error("Oops!, something went wrong, please try after some time."))
    }, [])

    const dataproducts = formData?.templateSelected?.attributes?.data_products?.data?.map((data, index) => {
        if (index === 0) {
            return { "id": parseInt(data?.id), "position": { "end": true } }
        }
        return { "id": parseInt(data?.id), "position": { "before": formData?.templateSelected?.attributes?.data_products?.data[index - 1]?.id } }
    })

    const renderTags = () => {
        return displayedItems && displayedItems.map((email, index) => (
            <> <Chip
                key={index}
                className=""
                size='sm'
                color={colorPicker(6)}
                variant="flat"
                onClose={() => handleTagRemove(email)}
            >
                {email}
            </Chip>
            </>
        ));
    };

    const makeArray = formData.users && formData.users.map((data, index) => {
        if (index === 0) {
            return { "id": data, "position": { "end": true } }
        }
        return { "id": data, "position": { "before": formData.users[index - 1] } }
    })

    const submitHandler = async (e) => {
        e.preventDefault();
        setLoader(true);
        const payload = {
            Name: formData?.spaceName,
            SpaceName: formData.Name,
            type: formData.type,
            admin: { disconnect: [], connect: [{ id: userID, position: { end: true } }] },
            members: { disconnect: [], connect: makeArray },
            data_products: { disconnect: [], connect: dataproducts },
            template: { disconnect: [], connect: [{ id: formData?.templateSelected?.id, position: { end: true } }] },
            VerctorDBCollection: formData?.Name
        }

        await createCollection(formData?.Name);
        const response = await CreateData("spaces", payload);

        if (response) {
            setLoader(false);
            navigate(`/${response?.data?.attributes?.Name}`)
        }
        await InviteHandler(formData?.Name);
    }

    const InviteHandler = async (spaceName) => {
        const invitations = (emails || []).map((data) => {
            const secretKey = `${process.env.REACT_APP_ENCRYPT_SECRET_KEY}`;
            const inputString = data;
            const encrypted = CryptoJS.AES.encrypt(inputString, secretKey);
            const AESHash = encrypted.toString();
            const url = `${process.env.REACT_APP_IP_ADDRESS}/${spaceName}/?${btoa(AESHash)}`
            const payload = {
                "to": data,
                "subject": "Invitation from Haya.",
                "html": `<html><body><a href="${url}">${url}</a></body></html>`
            };
            return InviteUser(payload);
        });
        const response = await Promise.all(invitations);
        setEmails([]);
        return response
    }

    return (
        <div>
            <div className="relative pt-2">
                <lable className="text-base">Invite users</lable>
                <div style={{ Background: "#f4f4f5" }} className={`border rounded-xl flex flex-col border-divider p-2`}>
                    <input
                        className={`bg-transparent border-0 rounded text-base`}
                        key={"outside"}
                        style={{ outline: 'none' }}
                        type="text"
                        variant="bordered"
                        labelPlacement={"outside"}
                        placeholder="Press enter to add multiple users"
                        value={tagInput && tagInput.trim()}
                        onChange={(e) => { setTagInput(e.target.value) }}
                        onKeyPress={(e) => { if (e.key === "Enter") { handleKeyPress(e) } }} />
                    <div className="pt-4 flex flex-wrap gap-2">
                        {renderTags()}
                        {!showAll ? (<>{emails && emails.length > 5 ? <Chip className="" size='sm' onClick={handleShowMore}>Show More</Chip> : ""}</>
                        ) : (<Chip className="" size='sm' onClick={handleShowLess}>Show less</Chip>)}
                    </div>
                    <p className="pt-2 text-xs text-red-400">{emails.includes(text) || validatetags || !emailRegex.test(text) ? validatetags : ""}</p>
                </div>
            </div>
            <div className="flex flex-row justify-end gap-4 pt-8">
                <Button color="secondary" variant="bordered" onClick={onPrevious}>Previous</Button>
                <Button color="secondary" onClick={submitHandler}>Create space</Button>
            </div>
        </div>
    )
}

export default Invitedusers;