import React, { useEffect, useRef, useState } from "react";
import { AgentManager } from "../../../Utils/Agent";
import { Avatar, Button, Image, Textarea } from "@nextui-org/react";
import Logo from "../../../Images/haya-logo.png";
import { RightArrow } from "../../../Images/RightArrow";
import { colorPicker } from "../../../controllers/basicControllers";
import { renderBoldText } from "../../../Utils/renderingMethods";
import HayaTableRender from "../../Haya/HayaTableRender";
import { renderResponseWithCodeBlocks } from "./Response";

const ChatBox = ({ title, role, goal, backstory }) => {
  const [thread, setThread] = useState([]);
  const responseTable = [];
  const [inputValue, setInputValue] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);
  const userInfo = JSON.parse(sessionStorage.getItem(`userData`)).user;
  const messagesEndRef = useRef(null);
  const handleKeyPress = () => {
    if (inputValue.trim() !== "") {
      setInputValue("");
      AgentManager.getInstance(setThread).sendMessage(
        inputValue.trim(),
        setThread,
        title,
        role,
        goal,
        backstory
      );
    }
  };
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
    setDisabled(true);
    const disableTimeout = setTimeout(() => setDisabled(false), 1000);

    const interval = setInterval(() => {
      if (
        AgentManager.getInstance(setThread).checkConnection() === false &&
        thread.length > 0 &&
        !thread[thread.length - 1].response
      ) {
        setError(true);
      } else {
        setError(false);
      }
    }, 4000);
    return () => {
      clearTimeout(disableTimeout);
      clearInterval(interval);
    };
  }, [thread]);
  console.log(thread);
  return (
    <div className="flex flex-col gap-8 w-full h-full py-4 overflow-y-auto">
      {thread.length === 0 ? (
        <div className="flex-1 text-warmGray-400 flex items-center justify-center">
          Start conversation with your&nbsp;
          <span className="font-bold">"{title}"</span>&nbsp;agent
        </div>
      ) : (
        <div className="flex-1 flex flex-col gap-2 overflow-y-auto">
          <div
            ref={messagesEndRef}
            className="h-full flex flex-col gap-4 px-8 pt-4 overflow-y-auto"
          >
            {thread.map((it, index) => (
              <div className="flex flex-col gap-4 w-full">
                <div className="flex px-2 flex-col">
                  <div className="flex flex-row gap-4 items-center">
                    <Avatar
                      size="sm"
                      src={userInfo?.Picture?.url}
                      color={colorPicker(userInfo?.id)}
                      name={userInfo?.email?.slice(0, 1).toUpperCase()}
                    />
                    <p className="font-semibold">You</p>
                  </div>
                  <p className="pl-12">
                    {it?.query?.split("\n")?.map((value) => (
                      <p>{value}</p>
                    ))}
                  </p>
                </div>
                <div className="flex flex-col px-2 pb-4 rounded-md group">
                  <div className="flex flex-row gap-4 items-center pt-10">
                    <Avatar src={Logo} size="sm" />
                    <p className="font-semibold">Haya</p>
                  </div>
                  <>
                    {it?.response ? (
                      <div className="mb-2">
                        <div className="flex items-center pl-12">
                          <div className="w-full">
                            {renderResponseWithCodeBlocks(
                              it.response,
                              responseTable
                            )}
                          </div>
                        </div>
                      </div>
                    ) : index === thread.length - 1 ? (
                      error ? (
                        <div className="pl-12">Error connecting to model</div>
                      ) : (
                        <div className="w-full pl-12 pt-2 items-center justify-start">
                          <lord-icon
                            src="https://cdn.lordicon.com/lqxfrxad.json"
                            trigger="loop"
                            delay="100"
                            style={{ width: "30px", height: "30px" }}
                          ></lord-icon>
                        </div>
                      )
                    ) : (
                      <div className="w-full pl-12 pt-2 items-center justify-start">
                        Error 404
                      </div>
                    )}
                  </>
                </div>
              </div>
            ))}
            <div className="h-0" ref={messagesEndRef} />
          </div>
        </div>
      )}
      <div className="w-full h-auto flex flex-col gap-4 px-8 ">
        <Textarea
          disabled={
            disabled ||
            (thread.length > 0 && !thread[thread.length - 1]?.response)
          }
          className={`w-full ${
            inputValue === "" ? "h-12" : "h-auto"
          } py-1 px-8`}
          size="sm"
          radius="md"
          startContent={
            <Image
              src={Logo}
              width={25}
              radius="full"
              className="text-large text-default-400 pointer-events-none flex-shrink-0 "
            />
          }
          endContent={
            (disabled ||
              (thread.length > 0 && !thread[thread.length - 1]?.response)) &&
            !error ? (
              <Button
                isIconOnly
                onClick={() =>
                  AgentManager.getInstance(setThread).stopMessage(setThread)
                }
                size="sm"
                radius="full"
                className=" bg-transparent rounded-full pb-2 hover:bg-transparent"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-circle-dot"
                >
                  <circle cx="12" cy="12" r="10" />
                  <circle cx="12" cy="12" r="1" />
                </svg>
              </Button>
            ) : (
              <div className="pt-1 mr-4">
                <RightArrow textdisabledState={inputValue} />
              </div>
            )
          }
          placeholder="Message Haya..."
          variant="flat"
          value={inputValue}
          minRows={1}
          onChange={(event) => {
            setInputValue(event.target.value);
          }}
          onKeyPress={(event) => {
            if (
              disabled ||
              (thread.length > 0 && !thread[thread.length - 1]?.response)
            ) {
              return;
            }
            if (event.key === "Enter") {
              event.preventDefault();
              if (event.shiftKey) {
                setInputValue((prev) => prev + "\n");
              } else {
                handleKeyPress();
              }
            }
          }}
        />
      </div>
    </div>
  );
};

export default ChatBox;
