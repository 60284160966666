import React from 'react'

const Credits = () => {


    
    return (
        <div>
            <div className="bg-[#F1F2F3] gap-1 p-1 flex items-center rounded-md">
                <svg width="1em" height="1em" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 pointer-events-none text-accent-onion-default"><path fill-rule="evenodd" clip-rule="evenodd" d="M8 13.605A5.333 5.333 0 108 2.938a5.333 5.333 0 000 10.667zm1.213-8.672a.494.494 0 00-.812-.517L4.944 7.922a.494.494 0 00.35.843H7.82l-1.034 2.844a.494.494 0 00.812.518l3.456-3.507a.494.494 0 00-.348-.842H8.179l1.034-2.845z" fill="#fa3ec2"></path></svg>
                <div className="text-sm mr-2 font-semibold">
                    647
                </div>
            </div>
        </div>
    )
}

export default Credits