import React from 'react';
import { Tabs, Tab, Button, Tooltip, Card } from "@nextui-org/react";
import { iconRender } from '../../../controllers/basicControllers';
import { toast } from "react-hot-toast";
import CustomToast from '../../../components/CustomToast';

const ExecuteCurlComponent = () => {
    const [selected, setSelected] = React.useState("curl");

    const curlCommand = `
    curl --location 'http://core.haya.cloud/agent/:uuid/execute' \
    --header 'Content-Type: application/json' \
    --data '{
        "query": "Design a detailed marketing plan for launching our new seasonal boba tea flavors at the terminal."
    }'
    `;

    // Function to handle copying the cURL command
    const copyCurlCode = () => {
        navigator.clipboard.writeText(curlCommand).then(() => {
            toast.custom((t) => (
                <div className={`${t.visible ? 'animate-enter' : 'animate-leave'} max-w-xs w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}>
                    <div className="p-2">
                        <p className="mt-1 text-sm text-gray-500">Curl command copied to clipboard!</p>
                    </div>
                </div>
            ));
        }).catch(() => {
            CustomToast("Oops! Something went wrong. Unable to copy code.", "error");
        });
    };

    const formatCurlCommand = () => {
        return (
            <pre className="code-container">
                <code>
                    <span className="text-success">curl</span> <span className="text-success">--location</span> <span className="text-secondary">'http://core.haya.cloud/agent/:uuid/execute'</span><br />
                    <span className="text-success">--header</span> <span className="text-warning">'Content-Type: application/json'</span><br />
                    <span className="text-success">--data</span> <span className="text-default">{'{'}</span><br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="text-warning">"query"</span>: <span className="text-default">"Design a detailed marketing plan for launching our new seasonal boba tea flavors at the terminal."</span><br />
                    {'}'}
                </code>
            </pre>
        );
    };

    return (
        <Card className='text-background bg-black border overflow-x-auto border-divider' shadow='none'>
            <div className='flex justify-end relative'>
                <Tooltip content="Copy" delay={0} closeDelay={0}>
                    <Button isIconOnly color="warning" variant="none" aria-label="Copy" onClick={copyCurlCode}>
                        {iconRender("https://cdn.lordicon.com/vdjwmfqs.json", "Copy")}
                    </Button>
                </Tooltip>
            </div>
            <Tabs
                aria-label="Options"
                className='absolute text-foreground'
                selectedKey={selected}
                variant="underlined"
                onSelectionChange={setSelected}
                color={"white"}
            >
                <Tab id="tabsData" key="curl" title="curl" className='px-6 text-default overflow-x-auto'>
                    {formatCurlCommand()}
                </Tab>
            </Tabs>
        </Card>
    );
};

export default ExecuteCurlComponent;
