import React, { useEffect, useState } from 'react';
import { CommonCollectionFilterApi } from '../../../controllers/strapiController';
import { Avatar, Card, CardBody, CardHeader, Chip, Tooltip, Spinner } from '@nextui-org/react'; // Import Spinner for the loader
import { colorPicker, StringLimit, timeDifference, titleCreater } from '../../../controllers/basicControllers';
import { useNavigate } from 'react-router-dom';
import { HayaFilter } from '../../../controllers/vectordbController';
import { spaceData } from '../../../redux/actions';
import { useSelector } from "react-redux";

const DomainDataProd = ({ SelectDomain, setLoader, setReload }) => {

    const collectionname = "data-products";
    const id = SelectDomain?.id;
    const navigate = useNavigate();
    const [dataProducts, setDataProducts] = useState([]);
    const reduxdata = useSelector(spaceData);
    const vectorname = reduxdata?.[0]?.attributes?.VerctorDBCollection;
    const [loading, setLoading] = useState(true); // State to manage loading
    const filters = {
        "filters": [
            {
                "key": "domain",
                "value": id?.toString()
            }
        ]
    };

    useEffect(() => {
        if (id) {
            setLoading(true); // Start loading
            HayaFilter(collectionname, reduxdata?.[0]?.attributes?.SpaceName, filters)
                .then((data) => {
                    setDataProducts(data || []); // Safeguard against undefined data
                    setLoading(false); // Stop loading when data is fetched
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    setLoading(false); // Stop loading in case of error
                });
        } else {
            setLoading(false); // Stop loading if id is not defined
        }
    }, [id]); // Add id as a dependency to rerun when id changes

    console.log("dataProducts:", dataProducts);

    const CardTags = ({ tags, colorIndex }) => (
        <div className='flex pt-2 gap-3'>
            {(tags?.slice(0, 3) || []).map((tag, index) => (
                <Chip key={`${index}`} size='sm' color={colorIndex} variant='flat'>
                    {tag}
                </Chip>
            ))}
            {tags?.length > 3 && (
                <Tooltip
                    content={tags.slice(3).map((tag, index) => (
                        <div key={`${index}`}>{tag}</div>
                    ))}
                    placement='bottom'
                    size='sm'
                    closeDelay={10}
                >
                    <Chip size='sm' color={colorIndex} variant="flat">
                        + {tags?.length - 3}
                    </Chip>
                </Tooltip>
            )}
        </div>
    );

    return (
        <div>
            {loading ? (
                <div className="flex justify-center items-center h-full">
                    <Spinner size="lg" />
                </div>
            ) : (
                <>
                    <div className='mt-4'>
                        {dataProducts.length > 0 ? (
                            <div className='grid md:grid-cols-2 gap-8 pt-8 sm:grid-cols-1 lg:grid-cols-3'>
                                {dataProducts.map((item) => (
                                    <div key={item?.payload?.id} onClick={() => navigate(`${item?.payload?.id}`)}>
                                        <Card
                                            className="max-w-[340px] min-w-[340px] min-h-[240px] h-full p-2 border border-divider cursor-pointer"
                                            shadow='none'
                                        >
                                            <CardHeader>
                                                <div className="flex gap-5">
                                                    <Avatar isBordered radius="full" name={item?.payload?.name?.slice(0, 1).toUpperCase()} size="md" color={colorPicker(item?.payload?.id)} />
                                                    <div className="flex flex-col gap-1 items-start justify-center">
                                                        <h4 className="text-small font-semibold leading-none text-default-600">{titleCreater(item?.payload?.name)}</h4>
                                                        <h5 className="text-small tracking-tight text-default-400">Updated {timeDifference(item?.attributes?.createdAt)}</h5>
                                                    </div>
                                                </div>
                                            </CardHeader>
                                            <CardBody className="px-3 py-0 pb-4 text-small flex flex-col justify-between text-default-500">
                                                <p className='py-4'>
                                                    {StringLimit(item?.payload?.description || item?.payload?.context, 120)}
                                                </p>
                                                <CardTags tags={item?.payload?.Tags?.tags || []} colorIndex={colorPicker(item?.id)} />
                                            </CardBody>
                                        </Card>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="flex justify-center items-center h-full mt-4">
                                <p>No data products are present inside {SelectDomain?.attributes?.Name}.</p>
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default DomainDataProd;
