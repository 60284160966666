import React, { useState } from 'react';
import CreateAgentDetails from './CreateAgents/CreateAgentDetails';
import AgentTools from './CreateAgents/AgentTools';
import ChooseModel from './CreateAgents/ChooseModel';

const initialState = { "Name": '', "Context": '', "DataProducts": [], "SelectAll": false }

const BuildAgent = () => {

  const [currentPage, setCurrentPage] = useState(1);
  const [formValues, setFormValues] = useState(initialState);

  const nextHandler = () => {
    setCurrentPage(currentPage + 1)
  }

  const previousHandler = () => {
    setCurrentPage(currentPage - 1);
  }

  const renderPage = () => {
    switch (currentPage) {
      case 1:
        return <CreateAgentDetails setFormValues={setFormValues} formValues={formValues} onNext={nextHandler} />
      case 2:
        return <ChooseModel setFormValues={setFormValues} formValues={formValues} onNext={nextHandler} onPrevious={previousHandler} />
      case 3:
        return <AgentTools setFormValues={setFormValues} formValues={formValues} onNext={nextHandler} onPrevious={previousHandler} />
      default:
        return null;
    }
  }
  return (
    <div>
      {renderPage()}
    </div>

  )
}

export default BuildAgent