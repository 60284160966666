import React, { useEffect, useState } from 'react'
import { CommonCollectionFilterApi } from '../../controllers/strapiController';
import {avatarName, colorPicker} from "../../controllers/basicControllers";
import { useParams } from 'react-router-dom';
import {Avatar, Tabs, Tab} from "@nextui-org/react";
import NotFound from '../../components/NotFound';
import ServerIssue from '../../components/ServerIssue';
import Forbidden from "../../components/Forbbiden";
import { ConnectorSkeleton } from '../../components/Skeleton';
import Settings from "../../pages/subPages/Settings";
import { Applications, Dataproducts, Explore } from './TempDataProducts';

const SingleTemplate = () => {
  const [templates, settemplates] = useState(null);
  const [dataProducts,setDataproducts]=useState([]);
  const [selected, setSelected] = useState();
  const [loader, setLoader] = useState(true);
  const [reload,setReload] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [forbidden, setForbidden] = useState(false);
  const [serverIssue, setServerIssue] = useState(false);
  const [connectionRefused, setConnectonRefused] = useState(false);
  const params = useParams();
  const CollectionName = "templates";


  useEffect(() => {
    const url = `filters[id][$in][0]=${params._id}&filters&[populate][spaces][on]&[populate][data_products][on]&[populate][spaces][populate][data_products][on]`
    CommonCollectionFilterApi(CollectionName, url)
      .then((data) => {
        setLoader(false)
        if (data.error) {
          if (data.error.status !== 404 && data.error.status !== 403) { setServerIssue(true) }
          if (data.error.status === 404) { setNotFound(true) }
          if (data.error.status === 403) { setForbidden(true) }
        } else if (data.data.length > 0) {
          setLoader(false)
          settemplates(data.data?.[0])
        } else {
          setNotFound(true)
        }
      })
      .catch((error) => { setLoader(false); setConnectonRefused(true) })

  }, [reload, params._id, params._spaceid])

  useEffect(() => {
     
      CommonCollectionFilterApi("data-products")
          .then((data) => setDataproducts(data.data))
          .catch((error) => console.error("Oops!, something went wrong, please try after some time."));
  }, []);

  const formData=templates?.attributes?.data_products?.data?.map(data=>data.id)

  if (loader) {
    return ConnectorSkeleton();
  }

  if (notFound)   {
    return <NotFound />
  }

  if (serverIssue) {
    return <ServerIssue />
  }

  if (connectionRefused) {
    return <ServerIssue />
  }

  if (forbidden) {
    return <Forbidden />
  }

  return (
    <div>
      <div className='flex gap-4 items-center'>
        <Avatar
          radius='sm'
          src=""
          width={30} 
          color={colorPicker(templates?.id)}
          name={avatarName(templates?.attributes?.Name)}/>
        <div className='text-3xl font-medium'>{templates?.attributes?.Name}</div>
      </div>
      <div className='mt-8'>
        {templates?.attributes?.Description}
      </div>
      <div className='pt-8'>
        <Tabs
          aria-label="Options"
          color={colorPicker(templates?.id)}
          variant="underlined"
          selectedKey={selected}
          defaultSelectedKey={""}
          onSelectionChange={setSelected}>
            <Tab key="Data products" title="Data Products">
             <Dataproducts filteredProducts={templates?.attributes?.data_products?.data }/>
            </Tab>
          <Tab key="Explore" title="Explore">
           <Explore dataProducts={dataProducts} handleChange={()=>{}} formData={formData} params={params}/>
          </Tab>
          <Tab key="Applications" title="Applications">
            <Applications data={templates}/>
          </Tab>
          <Tab key="Settings" title="Settings">
            <Settings collection={templates} setReload={setReload} setLoader={setLoader} isHide={true} collectionName="templates"/>
          </Tab>
        </Tabs>
      </div>
    </div>
  )
}

export default SingleTemplate