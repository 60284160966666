import { Accordion, AccordionItem, Button, Input } from "@nextui-org/react";
import React, { useEffect, useState } from "react";

const ContextCols = ({
  formData,
  setFormData,
  selectedProduct,
  schema,
  handlePrev,
  handleNext,
}) => {
  const [finalSchema, setFinalSchema] = useState({});
  function filterTables(data) {
    const result = {};

    Object.keys(data).forEach((tableName) => {
      const table = data[tableName];
      const filteredColumns = table.column_Schema.filter(
        (column) => column.context.trim() !== ""
      );

      if (table.table_context.trim() !== "" || filteredColumns.length > 0) {
        result[tableName] = {
          table_context: table.table_context,
          column_Schema: filteredColumns,
        };
      }
    });

    return result;
  }
  const handleSubmit = () => {
    const finalRes = filterTables(finalSchema);
    setFormData((prev) => ({
      ...prev,
      additional_context: { properties: finalRes },
    }));
    handleNext();
  };
  useEffect(() => {
    let final = {};
    if (schema?.tables) {
      Object.keys(schema?.tables).forEach((k) => {
        final[k] = {
          table_context: formData?.additional_context?.properties?.[k]?.["table_context"] ?? "",
          column_Schema: schema?.tables[k].map((a) => ({ ...a, context: formData?.additional_context?.properties?.[k]?.["column_Schema"]?.find((t) => t?.name === a?.name )?.context ?? "" })),
        };
      });
      setFinalSchema(final);
    }
  }, [schema]);
  return (
    <>
      <div>
        <div className="pb-8">
          <h1 className="text-3xl font-medium pb-4">Add additional context</h1>
          <p>
            Enhance querying in your data mesh by adding context like metadata,
            schema relationships, and domain-specific labels.
          </p>
        </div>
        <Accordion selectionMode="multiple">
          {schema?.tables &&
            Object.keys(schema?.tables)?.map((tableName) => (
              <AccordionItem
                key={tableName}
                aria-label={tableName}
                title={tableName}
              >
                <Input
                  label="Table Context"
                  labelPlacement="outside"
                  placeholder={`Add context to ${tableName}`}
                  value={finalSchema[tableName]?.table_context}
                  onChange={(e) =>
                    setFinalSchema((prev) => ({
                      ...prev,
                      [tableName]: {
                        ...prev[tableName],
                        table_context: e.target.value,
                      },
                    }))
                  }
                />
                <div className="mt-8">
                  <table
                    className="w-full table-auto"
                    aria-label={`Table data for ${tableName}`}
                  >
                    <thead className="text-left bg-default-100 ">
                      <th className="text-tiny font-semibold text-foreground-500 py-3 px-2 rounded-l-xl">
                        Column Name
                      </th>
                      <th className="text-tiny font-semibold text-foreground-500 py-3 px-2">
                        Type
                      </th>
                      <th className="text-tiny font-semibold text-foreground-500 py-3 px-2 rounded-r-xl">
                        Context
                      </th>
                    </thead>
                    <tbody className="px-2">
                      {schema &&
                        schema?.tables?.[tableName]?.map((column, index) => {
                          // const data = finalJson[
                          //   tableName
                          // ]?.column_schema?.find(
                          //   (data) => data.name === column.name
                          // );
                          return (
                            <tr
                              key={column.name}
                              value={index}
                              className="px-4"
                            >
                              <td className="pl-4">{column.name}</td>
                              <td>{column.type}</td>
                              <td className="pr-4">
                                <div className="py-2">
                                  <Input
                                    size="sm"
                                    value={
                                      (Array.isArray(
                                        finalSchema[tableName]?.column_Schema
                                      ) &&
                                        finalSchema[
                                          tableName
                                        ]?.column_Schema.find(
                                          (a) => a.name === column.name
                                        )?.context) ||
                                      ""
                                    }
                                    onChange={(e) =>
                                      setFinalSchema((prev) => ({
                                        ...prev,
                                        [tableName]: {
                                          ...prev[tableName],
                                          column_Schema: prev[
                                            tableName
                                          ]?.column_Schema?.map((a) =>
                                            a.name === column.name
                                              ? {
                                                  ...a,
                                                  context: e.target.value,
                                                }
                                              : a
                                          ),
                                        },
                                      }))
                                    }
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </AccordionItem>
            ))}
        </Accordion>
        <div className="pt-8 pr-2 flex flex-row gap-4 justify-end">
          <Button variant="light" onClick={handlePrev}>
            Back
          </Button>
          <Button variant="flat" color="danger" onClick={handleSubmit}>
            Next
          </Button>
        </div>
      </div>
    </>
  );
};

export default ContextCols;
