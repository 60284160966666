import React from 'react'
import KnowledgeBaseStatistics from './KnowledgeBaseStatistics'
import UserFeedback from './UserFeedback'
import TouringAnalytics from './TouringAnalytics'
import ContentTouring from './ContentTouring'
import { Tab, Tabs } from '@nextui-org/react'

const Analytics = () => {
  return (
    <div>
      <div className='text-3xl font-medium'>Analytics</div>
      <div className='mt-4 leading-relaxed'>
        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ab quas nihil maxime impedit obcaecati officiis totam odit aperiam nam molestias.</p>
      </div>
      <div className='mt-8'>
        <Tabs  variant='underlined'>
          <Tab key="Haya Insights" title="Haya Insights">
            <KnowledgeBaseStatistics  />
            <UserFeedback  />
            <TouringAnalytics  />
            <ContentTouring  />
          </Tab>
          <Tab key="Your dashboards" title="Your dashboards">

          </Tab>
        </Tabs>
      </div>

    </div>
  )
}

export default Analytics