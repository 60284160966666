import React, { useRef, useEffect, useState } from "react";
import * as monaco from "monaco-editor";
import MonacoJsonEditor from "../../components/MonacoJSON";
import { Button } from "@nextui-org/react";
import toast, { Toaster } from "react-hot-toast";
import { postData } from "../../controllers/vectordbController";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { userData } from "../../redux/actions";

const ContextJSON = ({ formData, handlePrev }) => {
  const { _spaceid } = useParams();
  const navigate = useNavigate();
  const userInfo = useSelector(userData);
  const [meta, setMeta] = useState(
    JSON.stringify(
      {},
      null,
      2 
    )
  );
  const handleCreate = async (json) => {
    try {
      const id = Date.now();
      const resp = await postData(
        id,
        { ...formData, metadata: json, user_id : userInfo.id },
        _spaceid,
        "data-products"
      );
      console.log("resp", resp);
    } catch (e) {}
  };
  const handleSubmit = () => {
    try {
      const resp = JSON.parse(meta);
      handleCreate(resp);
    } catch (e) {
      toast.error("Incorrect JSON format");
      return;
    }
  };

  return (
    <div className="flex flex-col">
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          style: {
            boxShadow: "none",
            border: "1px solid #bdb5b5",
          },
          error: { duration: 6000 },
        }}
      />
      <div className="pb-8">
        <h1 className="text-3xl font-medium pb-4 gap-4 flex items-center">
          Metadata
        </h1>
        <p>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quaerat
          similique nostrum dignissimos ratione accusantium nulla reprehenderit
          eligendi ad, amet nam.
        </p>
      </div>
      <div className="w-full h-[400px]">
        <MonacoJsonEditor value={meta} setValue={setMeta} />
      </div>
      <div className="pt-8 pr-2 flex flex-row gap-4 justify-end">
        <Button variant="light" onClick={handlePrev}>
          Back
        </Button>
        <Button variant="flat" color="danger" onClick={handleSubmit}>
          Create
        </Button>
      </div>
    </div>
  );
};

export default ContextJSON;
