import { Button, Divider, useDisclosure, ModalHeader, ModalBody, Input, Textarea, Switch, Tooltip } from '@nextui-org/react';
import React, { useEffect, useState } from 'react';
import ModalPopup from './ModalPopup';
import { CreateData, DeleteSingleAttribute, getAllData, UpdateData, CommonCollectionFilterApi } from '../../controllers/strapiController';
import { useNavigate } from 'react-router-dom';
import UpdateCollection from './UpdateCollection';
import DeleteModal from '../../components/DeleteModal';
import Cards from '../../components/Cards';
import UnAuthorized from '../../components/UnAuthorized';
import Forbbiden from '../../components/Forbbiden';
import NotFound from '../../components/NotFound';
import ServerError from '../../components/ServerIssue';
import { useParams } from "react-router-dom";
import { cardSkeleton } from '../../components/Skeleton';
import Tags from '../../components/Tags';
import { colorPicker } from '../../controllers/basicControllers';
import { toast } from "react-hot-toast";
import CustomToast from '../../components/CustomToast';
import { delete_document, getSingleConnection } from '../../controllers/vectordbController';

const Collections = () => {
  // STATE INITIALIZATION
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const { isOpen: isWarningOpen, onOpen: onWarningOpen, onOpenChange: onWarningOpenChange } = useDisclosure();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onOpenChange: onDeleteOpenChange } = useDisclosure();
  const [allCollections, setAllCollections] = useState(null);
  const [loader, setLoader] = useState(true);
  const [reload, setReload] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const [showPublic, setShowpublic] = useState(false);
  const [showShared, setShared] = useState(false);
  const [selectCollection, setSelectCollection] = useState(null);
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState('');
  const [deleteData, setDeleteData] = useState(null);
  const [spaceData, setSpaceData] = useState(null);
  const [tagsData, setTagsData] = useState();
  const collectionname = "collections";
  const navigate = useNavigate();
  const [validatetags, setValidatetags] = useState('');
  const [notFound, setNotFound] = useState(false);
  const [forbidden, setForbidden] = useState(false);
  const [serverIssue, setServerIssue] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [validationCondition, setValidationCondition] = useState(false);
  const userDetails = JSON.parse(sessionStorage.getItem("userData"));
  const userID = userDetails?.user?.id;
  const [formData, setFormdata] = useState({ Name: '', Description: '', Tags: [], isPublic: false });
  const [formError, setFormError] = useState({ NameError: '', DescriptionError: '' });
  const params = useParams();

  // API RENDERING
  useEffect(() => {
    setLoader(true)
    const url = `filters[Name][$eq]=${params._spaceid}`;
    CommonCollectionFilterApi("spaces", url)
      .then((data) => { setLoader(false); setSpaceData(data.data) })
      .catch((error) => CustomToast("Oops!, something went wrong, please try after some time.", "error"))
  }, [reload])

  // DELETE WARNING MODAL OPEN
  const DeleteWarningHandle = (data) => {
    setDeleteData(data);
    onDeleteOpen();
  }

  // EDIT MODAL OPEN
  const EditOpen = (SelectedCollection) => {
    onWarningOpen();
    setSelectCollection(SelectedCollection);
  }

  // SHOW MORE OR SHOW LESS TOGGLE CHANGE FUNCTION
  const toggleVisible = () => {
    setShowAll(!showAll)
  }
  const publicToggleVisible = () => {
    setShowpublic(!showPublic)
  }
  const SharedToggleVisible = () => {
    setShared(!showShared)
  }

  // API RENDER
  useEffect(() => {
    setLoader(true);
    const url = `filters[space][Name][$eq]=${params._spaceid}&filters[Type][$eq]=${collectionname}&[populate][author][on]&[populate][inviteds][on]&[populate][viewed][on]&[populate][requests][on]&[populate][teams][on]&[populate][space][on]`
    CommonCollectionFilterApi(collectionname, url)
      .then((data) => {
        if (data?.error) {
          if (data?.error?.status === 404) { setNotFound(true) }
          else if (data?.error?.status === 403) { setForbidden(true) }
          else if (data?.error?.status === 500) { setServerIssue(true) }
          else if (data?.error?.status === 401) { setAuthorized(true) }
          else {
            CustomToast("Oops!, something went wrong, please try after some time.", "error")
          }
        }
        else if (data?.data?.length > 0) { setAllCollections(data.data) }
        else { setLoader(false) }
      })
      .catch((error) => {
        CustomToast("Oops!, something went wrong, please try after some time.", "error")
      })

    getAllData("tags")
      .then((data) => {
        if (data?.error) {
          if (data?.error?.status === 404) { setNotFound(true) }
          else if (data?.error?.status === 403) { setForbidden(true) }
          else if (data?.error?.status === 500) { setServerIssue(true) }
          else if (data?.error?.status === 401) { setAuthorized(true) }
          else {
            CustomToast("Oops!, something went wrong, please try after some time.", "error")
          }
        } else if (data.data.length > 0) {
          setTagsData(data.data)
        }
      })
      .catch((error) => {
        CustomToast("Oops!, something went wrong, please try after some time.", "error")
      })
  }, [reload, collectionname, userID, params._spaceid])

  // SORTING THE COLLECTIONS ACCORDING TO CREATED AT
  const SortedData = allCollections && allCollections.sort((a, b) => new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt))
  // FILTERING BY AUTHOR
  const MyCollections = SortedData && SortedData.filter((data) => data?.attributes?.author?.data?.id === userID);
  // FILTER BY ISPUBLIC
  const PublicCollections = SortedData && SortedData.filter((data) => data?.attributes?.Public === true);
  // FILTER FOR SHARED LIST
  const sharedCollections = SortedData && SortedData.map((data) => {
    const InvitedData = data?.attributes?.inviteds?.data;
    const myData = InvitedData && InvitedData.filter((datafilter) => datafilter.id === userID);
    return myData.length > 0 ? data : null;
  }).filter(item => item !== null);

  // SHOW MORE/SHOW LESS CONDITIONS
  const userCollections = showAll ? MyCollections : MyCollections?.slice(0, 6);
  const publicCollections = showPublic ? PublicCollections : PublicCollections?.slice(0, 6);
  const sharedPostCollections = showShared ? sharedCollections : sharedCollections?.slice(0, 6);

  // SHOW MORE ICON RENDERING DYNAMICALLY
  const iconRendering = (url) => {
    return <lord-icon
      src={url}
      trigger="hover"
      target="#ViewSpace"
      colors={`primary:#000000`}
      style={{ width: "22px", height: "22px" }}>
    </lord-icon>
  }


  // VALIDATION FORM
  const Validation = () => {
    let IsValid = true;

    if (!formData.Name) {
      IsValid = false;
      setFormError((previous) => ({ ...previous, NameError: "Name is required." }));
    } else {
      setFormError((previous) => ({ ...previous, NameError: null }))
    }

    if (!formData.Description) {
      IsValid = false;
      setFormError((previous) => ({ ...previous, DescriptionError: "Description is required." }))
    } else {
      setFormError((previous) => ({ ...previous, DescriptionError: null }))
    }
    return IsValid;
  }

  // TAGS FILTER DROPDOWN
  const FilteredUser = tagsData && tagsData.filter((data) => data?.attributes?.users_permissions_user?.data?.id === userID)
  // TAGS PRESENTS
  const TagsLength = FilteredUser?.[0]?.attributes?.Tags?.tags;
  // FILTER FOR INPUT TEXT RELATED TO TAGS
  const FilteredText = tagInput !== '' ? TagsLength && TagsLength.filter((data) => data && data.toLowerCase().includes(tagInput && tagInput.trim().toLowerCase())) : TagsLength;

  // FINAL DISPLAY TAGS
  const FilteredTags = FilteredText && FilteredText.length > 0 ? FilteredText : []

  const TagsRender = formData?.Tags && formData?.Tags !== null && TagsLength && formData.Tags.filter((data) => !TagsLength.includes(data));
  const NewRenderation = TagsRender && FilteredTags && TagsRender.concat(FilteredTags);
  const TagsID = FilteredUser && FilteredUser[0] && FilteredUser[0].id;
  const spaceID = spaceData && spaceData[0] && spaceData[0].id;

  const SubmitHandler = async (onClose) => {
    const Validate = Validation();
    const payload = {
      Name: formData.Name,
      Description: formData.Description,
      author: {
        disconnect: [],
        connect: [{ id: userID, position: { end: true } }]
      },
      Public: formData.isPublic,
      color: "",
      Tags: { "tags": formData.Tags },
      space: {
        disconnect: [],
        connect: [{ id: spaceID, position: { end: true } }]
      }
    }

    const TagsPayload = {
      Tags: { tags: NewRenderation }
    }

    if (Validate) {
      setLoader(true)
      setValidationCondition(false);
      onClose();
      const response = await CreateData(collectionname, payload)
      if (response) {
        if (FilteredUser && FilteredUser.length > 0) {
          const updateResponse = await UpdateData("tags", TagsID, TagsPayload)
          setReload(updateResponse)
          if (response) {
            setReload(response)
            navigate(`${response.data.id}`);
            setFormdata({ Name: '', Description: '', Tags: [], isPublic: false });
          }
        }
        if (FilteredUser && FilteredUser.length === 0) {
          const payload = {
            users_permissions_user: {
              disconnect: [],
              connect: [{ id: userID, position: { end: true } }]
            },
            Tags: { "tags": formData.Tags }
          }
          const response = await CreateData("tags", payload);
          if (response) {
            setReload(response);
          }
        }
      }
      if (response) {
        const updateResponse = await UpdateData("tags", TagsID, TagsPayload)
        setReload(updateResponse);
        setLoader(false);
        navigate(`${response.data.id}`);
      }
    } else {
      setValidationCondition(true);
    }
  }

  // TAGS INPUT ONCHANGE HANDLER
  const handleChange = (e) => {
    const userInput = e.target.value;
    const regex = /^[a-zA-Z_ -]*$/;
    if (regex.test(userInput)) {
      setFormdata({ ...formData, Name: userInput })
    }
  }


  // CREATE MODAL
  const ModalBodyData = () => {
    return <> <ModalHeader className="flex flex-col  gap-1">Create a new Collection</ModalHeader>
      <ModalBody className='gap-0 h-96'>
        <Input
          isRequired
          key="outside"
          type="text"
          value={formData.Name}
          onChange={handleChange}
          isInvalid={!formData.Name && validationCondition ? !formData.Name || validationCondition : ''}
          errorMessage={!formData.Name && validationCondition ? formError.NameError : ""}
          label="Collection Name"
          labelPlacement="outside"
          placeholder="Enter your collection folder name"
        />
        <Textarea
          className='pt-4'
          key={"description"}
          isRequired
          type="text"
          value={formData.Description}
          onChange={(e) => { setFormdata({ ...formData, Description: e.target.value }) }}
          isInvalid={!formData.Description && validationCondition ? !formData.Description || validationCondition : ''}
          errorMessage={!formData.Description && validationCondition ? formError.DescriptionError : ""}
          label="Decription"
          labelPlacement={"outside"}
          placeholder="Enter your collection's Description"
        />
        <div className='flex flex-col gap-1 pt-4'>
          <div className='flex gap-2 flex-col'>
            <p className='text-sm'>Make collection public</p>
            <Switch isSelected={formData.isPublic} onChange={(e) => { setFormdata({ ...formData, isPublic: !formData.isPublic }) }} size='sm' color="secondary"></Switch>
          </div>
          {formData.isPublic ? <p className='text-xs text-slate-400'>By making your collection public anyone inside the space can view and upload your documents.</p> : <></>}
        </div>
        <Tags

          tagsData={tagsData}
          setTagsData={setTagsData}
          tagInput={tagInput}
          setTagInput={setTagInput}
          tags={tags}
          validatetags={validatetags}
          setTags={setTags}
          setValidatetags={setValidatetags}
          setFormdata={setFormdata}
          formData={formData}
          background={"bg-transparent"}
        />
      </ModalBody>
    </>
  }

  // FOOTER FOR CREATE COLLECTIONS
  const footerCreate = (onClose) => {
    return <><Button color="default" variant="flat" onClick={() => { setFormdata({}); onClose(); setTags([]) }}> Cancel </Button>
      <Button color="secondary" onClick={() => { SubmitHandler(onClose) }}>Create</Button></>
  }

  // DELETE FUNCTION FOR DELETE PERTICULAR COLLECTION

  const DeleteHandler = async () => {
    setLoader(true)
    try {
      const response = await DeleteSingleAttribute(collectionname, deleteData.id)

      if (response) {
        setReload(response);
        setLoader(false);
      }

      const vectorName = 'data-products';
      const documentsDataPromise = getSingleConnection(vectorName, params?._spaceid, deleteData.id);

      const [documentsDataResult] = await Promise.all([documentsDataPromise]);

      const documentsDataArray = documentsDataResult?.documents || [];
      documentsDataArray.forEach(element => {
        delete_document(vectorName, element?.payload?.id, params?._spaceid)
          .catch(error => console.error("Error deleting documentData:", error));
      });

    } catch (error) {
      console.error("Error deleting collection or handling documents:", error);
    }
  };

  // OTHER ERROR PAGES
  if (notFound) return <NotFound />;
  if (serverIssue) return <ServerError />;
  if (authorized) return <UnAuthorized message='Authorization error.' />;
  if (forbidden) return <Forbbiden />;

  // RETURN UI
  return (
    <div>
      <div className='text-3xl font-medium'>Collections</div>
      <div className='mt-4 leading-relaxed'>
        Integrate unstructured data into your data mesh to unlock valuable insights. Connect, organize, and analyze data from various unstructured sources, such as documents and emails, enhancing your data ecosystem with comprehensive, actionable information.
      </div>
      <div>
        <div className='flex justify-end mt-4'>
          <Button color='secondary' onClick={() => navigate(`/${params._spaceid}/data-products/create/collection`)}>+ Create a new Collection</Button>
        </div>
        <div className='text-xl font-medium items-center pt-8 flex justify-between'>
          My Collections
        </div>
        <Divider className='mt-2' />
        {loader ? cardSkeleton() : <>
          {userCollections && userCollections.length > 0 ? (
            <>
              <div className='grid md:grid-cols-2 gap-8 pt-8 sm:grid-cols-1 lg:grid-cols-3' >
                {userCollections.map((data, index) => {
                  const InvitedUsers = data?.attributes?.inviteds?.data?.length > 0 ? data.attributes.inviteds.data : [];
                  const ViewedUsers = data?.attributes?.viewed?.data?.length > 0 ? data.attributes.viewed.data : [];
                  const MurgedUsers = ViewedUsers && ViewedUsers.concat(InvitedUsers && InvitedUsers)
                  const colorIndex = colorPicker(data.id);
                  // const documentCount = collectionCounts[data.id];
                  return <div key={index} className=' ' onClick={() => navigate(`${data.id}`)}>
                    <Cards
                      data={data}
                      MurgedUsers={MurgedUsers}

                      EditOpen={EditOpen}
                      userId={userID}
                      colorIndex={colorIndex}
                      ActionVisible={true}
                      DeleteWarningHandle={DeleteWarningHandle}
                      Logo={""}
                    />
                  </div>
                })}</div>
              {MyCollections?.length > 6 &&
                <Tooltip content={showAll ? "Show less" : "Show more"}>
                  <div onClick={toggleVisible} className='flex justify-center pt-4'>{showAll ?
                    iconRendering("https://cdn.lordicon.com/dwoxxgps.json")
                    :
                    iconRendering("https://cdn.lordicon.com/rmkahxvq.json")}
                  </div>
                </Tooltip>}
            </>) :
            <div className='italic font-medium pt-8 flex text-sm justify-center text-coolGray-400'> You do not have any collections.</div>}
        </>}
      </div>
      <div>
        <div className='text-xl font-medium pt-8'> Public Collections</div>
        <Divider className='mt-2' />
        {loader ? cardSkeleton() : <>
          {PublicCollections && PublicCollections.length > 0 ? (
            <>
              <div className='grid md:grid-cols-2 gap-8 pt-8 sm:grid-cols-1 lg:grid-cols-3'>
                {publicCollections?.map((data, index) => {
                  const InvitedUsers = data?.attributes?.inviteds?.data?.length > 0 ? data.attributes.inviteds.data : [];
                  const ViewedUsers = data?.attributes?.viewed?.data?.length > 0 ? data.attributes.viewed.data : [];
                  const MurgedUsers = ViewedUsers && ViewedUsers.concat(InvitedUsers && InvitedUsers)
                  const colorIndex = colorPicker(data.id);
                  // const documentCount = collectionCounts[data.id];
                  return <div key={index} className='flow gap-8' onClick={() => navigate(`${data.id}`)}>
                    <Cards
                      data={data}
                      MurgedUsers={MurgedUsers}
                      colorIndex={colorIndex}

                      EditOpen={EditOpen}
                      userId={userID}
                      ActionVisible={true}
                      // documentCount={documentCount}
                      DeleteWarningHandle={DeleteWarningHandle}
                      Logo={""}
                    />
                  </div>
                })}</div>
              {PublicCollections?.length > 6 &&
                <Tooltip content={showPublic ? "Show less" : "Show more"}>
                  <div onClick={publicToggleVisible} className='flex justify-center pt-4'>{showPublic ?
                    iconRendering("https://cdn.lordicon.com/dwoxxgps.json")
                    :
                    iconRendering("https://cdn.lordicon.com/rmkahxvq.json")}
                  </div>
                </Tooltip>}
            </>) :
            <div className='italic font-medium text-sm pt-8 flex justify-center text-coolGray-400'>You do not have any public collections</div>
          }
        </>}
      </div>
      <div>
        <div className='text-xl font-medium pt-8'> Shared Collections</div>
        <Divider className='mt-2' />
        {loader ? cardSkeleton() : <>
          {sharedPostCollections && sharedPostCollections.length > 0 ? (
            <>
              <div className='grid md:grid-cols-2 gap-8 pt-8 sm:grid-cols-1 lg:grid-cols-3' >
                {sharedPostCollections && sharedPostCollections !== null && sharedPostCollections.map((data, index) => {
                  const InvitedUsers = data?.attributes?.inviteds?.data?.length > 0 ? data.attributes.inviteds.data : [];
                  const ViewedUsers = data?.attributes?.viewed?.data?.length > 0 ? data.attributes.viewed.data : [];
                  const MurgedUsers = ViewedUsers && ViewedUsers.concat(InvitedUsers && InvitedUsers)
                  const colorIndex = colorPicker(data.id);
                  // const documentCount = collectionCounts[data.id];
                  return <div key={index} className='flow gap-8' onClick={() => navigate(`${data.id}`)}>
                    <Cards
                      data={data}
                      MurgedUsers={MurgedUsers}
                      colorIndex={colorIndex}

                      EditOpen={EditOpen}
                      userId={userID}
                      ActionVisible={true}
                      DeleteWarningHandle={DeleteWarningHandle}
                      Logo={""}
                    />
                  </div>
                })}</div>
              {sharedCollections?.length > 6 &&
                <Tooltip content={showShared ? "Show less" : "Show more"}>
                  <div onClick={SharedToggleVisible} className='flex justify-center pt-4'>{showShared ?
                    iconRendering("https://cdn.lordicon.com/dwoxxgps.json")
                    :
                    iconRendering("https://cdn.lordicon.com/rmkahxvq.json")}
                  </div>
                </Tooltip>}
            </>) :
            <div className='italic font-medium pt-8 flex justify-center text-sm text-coolGray-400'>
              You currently do not have any collections that are shared to you buy other members of the space.
            </div>}
        </>}
      </div>
      <ModalPopup
        size="2xl"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        ModalBodyData={ModalBodyData}
        footer={footerCreate}
        scrollBehavior="inside"
      />
      <UpdateCollection
        isWarningOpen={isWarningOpen}
        onWarningOpenChange={onWarningOpenChange}
        SelectCollection={selectCollection}
        setLoader={setLoader}

        setReload={setReload}
      />
      <DeleteModal
        isOpen={isDeleteOpen}
        onOpenChange={onDeleteOpenChange}
        deleteData={deleteData}
        setDeleteData={setDeleteData}
        Collectionname={"collection"}
        setLoader={setLoader}
        DeleteHandler={DeleteHandler}
      />
    </div >
  )
}

export default Collections;
