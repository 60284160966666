import React, { useEffect, useState } from "react";
import { Avatar, Tabs, Tab, Image, Chip } from "@nextui-org/react";
import { useParams } from "react-router-dom";
import { getFilteredData } from "../../controllers/strapiController";
import NotFound from "../../components/NotFound";
import WorkBench from "./WorkBench";
import { useSelector } from "react-redux";
import { ConnectorSkeleton } from "../../components/Skeleton";
import {
  colorPicker,
  convertKeysToLowercase,
} from "../../controllers/basicControllers";
import { pageRefereshData } from "../../redux/actions";
import {
  getSingleDataProduct,
  view_schema,
} from "../../controllers/vectordbController";
import ContextCols from "../DataProducts/ContextCols";
import Config from "../DataProducts/Config";
import SettingsNew from "../subPages/SettingsNew";
import Invite from "../../components/Invite";

const SingleConnector = () => {
  // STATE INITIALIZATION
  const [connectorData, setConnectorData] = useState();
  const [loader, setLoader] = useState(true);
  const [product, setProduct] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [reload, setReload] = useState(true);
  const [schema, setSchema] = useState({});
  const [selected, setSelected] = useState();
  const params = useParams();
  const [members, setMembers] = useState([]);
  const pageRender = useSelector(pageRefereshData);

  // API RENDERING
  useEffect(() => {
    const fetchCollection = async () => {
      try {
        const resp = await getSingleDataProduct(params._id, params._spaceid);
        if (resp && resp.length > 0) {
          setConnectorData(resp[0]);
          console.log("connectedData", resp[0]);
          const resp2 = await getFilteredData("data-products", [
            { filterKey: "Name", filterValue: resp[0].payload.data_product },
          ]);
          setProduct(resp2.data[0]);
          setMembers(resp[0]?.payload.members ?? []);
          const cfg = resp[0]?.payload.config;
          const ConnectionPayload = {
            source: resp2.data[0]?.attributes.Name,
            ...cfg,
          };
          const ConnectionPayloadLowerCase =
            convertKeysToLowercase(ConnectionPayload);
          const response = await view_schema(ConnectionPayloadLowerCase);
          setSchema(response);
        } else {
          setNotFound(true);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoader(false);
      }
    };
    if (params._spaceid && params._id) {
      fetchCollection();
    }
  }, [params._id, params._spaceid, reload, pageRender]);

  if (loader) {
    return ConnectorSkeleton();
  }

  if (notFound) {
    return <NotFound />;
  }

  return (
    <div className="p-8 flex overflow-y-auto flex-col h-full">
      <div className="text-3xl font-medium flex gap-4 items-center">
        <Image width={60} src={product?.attributes?.Image} />
        {connectorData?.payload?.name}
      </div>
      <div className="mt-8 gap-2 flex flex-wrap">
        {connectorData?.payload?.tags?.map((tag, index) => (
          <Chip
            key={index}
            color={colorPicker(tag.length)}
            variant="flat"
            size="sm"
          >
            {tag}
          </Chip>
        ))}
      </div>
      <div className="mt-4 leading-relaxed ">
        {connectorData && connectorData?.payload?.description}
      </div>
      <div className="pt-8">
        <Tabs
          aria-label="Options"
          color={colorPicker(connectorData?.payload?.id)}
          variant="underlined"
          selectedKey={selected}
          onSelectionChange={setSelected}
        >
          <Tab key="Workbench" title="Workbench"></Tab>
          <Tab key="Properties" title="Properties"></Tab>
          <Tab key="Configuration" title="Configuration"></Tab>
          <Tab key="Invite" title="Members" />
          <Tab key="Settings" title="Settings"></Tab>
        </Tabs>
      </div>
      <div className="flex-1 overflow-y-auto ">
        {selected === "Workbench" ? (
          <WorkBench
            dataName={product?.attributes?.Name}
            config={connectorData?.payload?.config}
          />
        ) : selected === "Properties" ? (
          <ContextCols
            setReload={setReload}
            schema={schema}
            id={connectorData?.payload?.id}
            option="update"
            formData={{
              additional_context: connectorData?.payload?.additional_context,
            }}
          />
        ) : selected === "Configuration" ? (
          <Config
            setReload={setReload}
            option="update"
            id={params._id}
            selectedProduct={product}
            formData={{ config: connectorData?.payload?.config }}
          />
        ) : selected === "Settings" ? (
          <SettingsNew data={connectorData} setReload={setReload} />
        ) : selected === "Invite" ? (
          <Invite
            productId={connectorData?.payload?.id}
            invitedIds={members}
            setInvitedIds={setMembers}
            adminId={connectorData?.payload?.user_id}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default SingleConnector;
