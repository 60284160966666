import React, { useState } from 'react';
import { Progress, Button } from "@nextui-org/react";
import SelectdataProduct from './SelectdataProduct';
import CreateProduct from './CreateProduct';
import DBSelector from './Database/DB_Selector';
import Context from './Database/Context';
import { useNavigate, useParams } from "react-router-dom";
import Redendent from './Redendent';
import { useSelector } from "react-redux";
import { spaceData } from "../../redux/actions"
import { userLoginDetails } from "../../controllers/strapiController";

const CreateDatabase = () => {
    // STATE INITIALIZATION
    const [step, setStep] = useState(1);
    const navigate = useNavigate();
    const params = useParams();
    const [selectProduct, setSelectProduct] = useState(null);
    const [finalJson, setFinalJson] = useState([]);
    const userId = userLoginDetails()?.userId;
    const convertedData = useSelector(spaceData);
    const [formData, setFormData] = useState({ type: '', payload: '', Tags: '', dataProduct: "", inputFields: '' });

    // WIZARD NEXT FUNCTION
    const handleNext = () => {
        setStep(prevStep => prevStep + 1);
    };

    // WIZARD PREVIOUS FUNCTION
    const handlePrevious = () => {
        setStep(prevStep => prevStep - 1);
    };

    // SWITCH PAGES
    const renderPage = () => {
        switch (step) {
            case 1:
                return <><div className='pb-8'>
                    <h1 className='text-3xl font-medium pb-4'>Add a new {params?._type ? params?._type : "data product"}</h1>
                    <p>Add a new data product to your datamesh and bring all your enterprise data products inside your space. Your data can be unstructured documents, structured data from databases, or from an exteral source </p>
                    <div className='flex justify-end pt-4'>
                        <Button color="danger" onClick={() => navigate(`${params?._type ? `/${params?._spaceid}/data-products/${params?._type}/update` : `/${params?._spaceid}/data-products/update`}`)}>Explore</Button>
                    </div>
                </div>
                    <SelectdataProduct onNext={handleNext} setFormData={setFormData} formData={formData} setSelectProduct={setSelectProduct} multiSelect={false} />
                </>
            case 2:
                return <>
                    <CreateProduct onNext={handleNext} onPrevious={handlePrevious} setFormData={setFormData} formData={formData} selectProduct={selectProduct} />
                </>
            case 3:
                return <>
                    <DBSelector onNext={handleNext} onPrevious={handlePrevious} formData={formData} setFormData={setFormData} selectProduct={selectProduct} />
                </>
            case 4:
                return <>
                    <Context onNext={handleNext} onPrevious={handlePrevious} formData={formData} setFormData={setFormData} selectProduct={selectProduct} option={"create"} finalJson={finalJson} setFinalJson={setFinalJson} />
                </>
            default:
                return null;
        }
    }

    const progressValue = () => {
        let value;
        if (selectProduct?.attributes?.Type !== "connectors") {
            if (selectProduct?.attributes?.Schema !== null) {
                value = step === 1 ? 0 : 33.33 * (step - 1)
            } else {
                value = step === 1 ? 0 : 50 * (step - 1)
            }
        } else {
            value = step === 1 ? 0 : 25 * (step - 1)
        }

        return value
    }

    return (
        <div>
            <div className='flex flex-row justify-center pt-8 pb-8'>
                <Progress
                    size="sm"
                    radius="sm"
                    classNames={{ base: "max-w-lg" }}
                    color='secondary'
                    value={progressValue()}
                />
            </div>
            <div className='pt-8'>
                {renderPage()}
            </div>
        </div>
    )
}

export default CreateDatabase;