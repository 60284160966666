import { Button, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from '@nextui-org/react'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { CommonCollectionFilterApi, userLoginDetails } from '../../controllers/strapiController'
import { StringLimit, titleCreater } from '../../controllers/basicControllers'

const Agents = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [agentList, setAgentList] = useState(null);
  const userId = userLoginDetails()?.userId;
  const collectionName = "agents";
  const spaceName = params?._spaceid;

  useEffect(() => {
    const url = `filters[space][Name][$eq]=${spaceName}`;
    CommonCollectionFilterApi(collectionName, url)
      .then(data => setAgentList(data?.data))
      .catch(error => error)
  }, [userId, spaceName])

  return (
    <div>
      <div className='text-3xl font-medium'>Agents</div>
      <div className='mt-4 leading-relaxed'>
        Agents are powerful mini AI agents designed to operate within your applications, harnessing the potential of your data mesh. These agents can autonomously process tasks, provide insights, and adapt to different use cases based on the structured and unstructured data they access. Leverage them to enhance decision-making, automate workflows, and build intelligent, data-driven features in your applications.
      </div>
      <div className='flex justify-end mt-4'>
        <Button color='danger' onClick={() => navigate(`/${params._spaceid}/agents/create`)}>+ Create an agent</Button>
      </div>
      <div className='mt-8'>
        <Table removeWrapper>
          <TableHeader>
            <TableColumn>NAME</TableColumn>
            <TableColumn>DESCRIPTION</TableColumn>
            <TableColumn></TableColumn>
          </TableHeader>
          <TableBody emptyContent={"No rows created yet."}>
            {agentList?.length > 0 && agentList?.map((data, index) => {
              return <TableRow className='cursor-pointer' key="1" onClick={()=>navigate(`/${spaceName}/agents/${data?.id}`)}>
                <TableCell>{titleCreater(data?.attributes?.Name)}</TableCell>
                <TableCell>{StringLimit(data?.attributes?.Description,150)}</TableCell>
                <TableCell>
                </TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default Agents
