import React, { useEffect, useState, useRef } from 'react'
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Button, Chip, Avatar, useDisclosure } from "@nextui-org/react";
import { InviteUser, CommonCollectionFilterApi, userLoginDetails } from '../../controllers/strapiController';
import ModalPopup from '../Collections/ModalPopup';
import { useParams } from "react-router-dom";
import { tableSkeleton } from '../../components/Skeleton';
import { useSelector } from "react-redux";
import NotFound from "../../components/NotFound";
import { colorPicker } from '../../controllers/basicControllers';
import EmptyArray from '../../components/EmptyArray';
import Paginate from '../../components/Pagination';
import {toast} from "react-hot-toast";

const Members = () => {
  // STATE INITIALIZATION
  const [userDataList, setUserData] = useState([]);
  const [Loader, setLoader] = useState(true);
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState('');
  const [Validatetags, setValidatetags] = useState('');
  const [formData, setFormdata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const TextRef = useRef();
  const params = useParams();
  const userDetails = userLoginDetails();
  const setdata = useSelector(state => state.space);
  const userVerify = setdata?.[0]?.attributes?.admin?.data?.id === userDetails.userId;
  const { isOpen: isModalOpen, onOpen: onModalOpen, onOpenChange: onModalOpenChange } = useDisclosure();

  // API RESPONSE
  useEffect(() => {
    setLoader(true)
    const url2 = `filters[$or][0][spaces][Name][$in]=${params._spaceid}&filters[$or][1][space][Name][$in]=${params._spaceid}&[populate][Picture][on]&[populate][spaces][on]&[populate][space][on]`
    CommonCollectionFilterApi("users", url2)
      .then((data) => {
        setTimeout(() => { setLoader(false) }, 200)
        setUserData(data)
      })
      .catch((error) => toast.error("Oops!, something went wrong, please try after some time."))
  }, [])

  // FUNCTION TO REMOVE TAGS
  const handleTagRemove = (tagToRemove) => {
    setTags(tags.filter(tag => tag !== tagToRemove));
    setFormdata(() => ({ Tags: tags.filter(tag => tag !== tagToRemove) }))
  };

  const Text = tagInput.trim().toLowerCase();
  const EmailExists = userDataList && userDataList.some((data) => data.email === Text)

  // FORM VALIDATION FUNCTION
  const Validation = () => {
    let isValid = true;

    if (tags.includes(Text)) {
      setValidatetags("Entered email already exists in invite list.");
      isValid = false;
    } else if (EmailExists) {
      setValidatetags("Entered email already exists.");
      isValid = false;
    } else if (!EmailRegex.test(Text)) {
      setValidatetags("Enter a valid email.");
      isValid = false;
    } else {
      setValidatetags(null)
    }
    return isValid;
  }

  // "ENTER" KEY TO CLEATE TAG
  const handleKeyPress = (e) => {
    if (tagInput.trim() !== '' && e.key === "Enter") {
      const Validate = Validation();
      if (Validate) {
        setTags((previous) => [...previous, Text]);
        setValidatetags("")
        setFormdata((previous) => ([...tags, Text]));
        setTagInput('');
      }
    }
  }

  // FUNCTION TO INVITE
  const InviteHandler = async (onClose) => {
    onClose();
    const invitations = (tags || []).map((data) => {
      const payload = {
        "to": data,
        "subject": "Invitation from Haya.",
        "html": "<html><body><p>Hello </p></body></html>"
      };
      return InviteUser(payload);
    });
    await Promise.all(invitations);
    setTags([]);
  }

  const recordsPerPage = 10;

  // Calculate indexes for pagination
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = userDataList && userDataList.slice(indexOfFirstRecord, indexOfLastRecord);

  // TAGS CHIP RENDERING
  const renderTags = () => {
    return tags && tags.map((tag, index) => (
      <Chip
        key={`${index}`}
        className=""
        size='sm'
        color={colorPicker(6)}
        variant="flat"
        onClose={() => handleTagRemove(tag)}
      >
        {tag}
      </Chip>
    ));
  };

  // MODAL 
  const ModalBodyData = () => {
    return (
      <div className='p-8'>
        <div className='text-lg'>
          Invite your team to your overview
        </div>
        <div className="relative pt-2">
          <div className="text-sm my-4">Enter your team members emails</div>
          <div style={{ Background: "#f4f4f5" }} className={`border rounded-xl  flex flex-col border-divider p-2`}>
            <input
              className={`bg-transparent border-0 rounded text-sm`}
              key={"outside"}
              ref={TextRef}
              style={{ outline: 'none' }}
              type="text"
              variant="bordered"
              labelPlacement={"outside"}
              placeholder="Press enter to add multiple emails"
              value={tagInput && tagInput.trim()}
              onChange={(e) => { setTagInput(e.target.value) }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleKeyPress(e)
                }
              }}
            />
            <div className="pt-4 flex flex-wrap gap-2">
              {renderTags()}
            </div>
            <p className="pt-2 text-xs text-red-400">{tags.includes(Text) || (EmailExists === true) || !EmailRegex.test(Text) ? Validatetags : " "}</p>
          </div>
        </div>
      </div>
    )
  }

  // MODAL FOOTER
  const footerCreate = (onClose) => {
    var Disable = tags && tags.length === 0;
    return <>
      <Button onClick={() => InviteHandler(onClose)} isDisabled={Disable} color="secondary">
        Invite
      </Button></>
  }

  if (!userVerify) {
    return <NotFound />
  }

  // RETURN UI
  return (
    <div>
      <div className='text-3xl font-medium'>Members</div>
      <div className='mt-4 leading-relaxed'>
        Here are the users that are contributing to your space. You can invite other users to your space to contribute to your overview.
      </div>
      <div className='flex mt-4 justify-end'>
        <Button color='danger' onPress={()=>{onModalOpen();setTagInput('')}}>Invite your team</Button>
      </div>
      <div className='pt-8'>
        {Loader ? tableSkeleton() : <>
          {currentRecords && currentRecords.length > 0 ?
            <Table removeWrapper aria-label="Example static collection table">
              <TableHeader>
                <TableColumn>Name</TableColumn>
                <TableColumn>Contributions </TableColumn>
                <TableColumn>Status</TableColumn>
              </TableHeader>
              <TableBody>
                {currentRecords && currentRecords.map((user, index) => {
                  return <TableRow key="1">
                    <TableCell>
                      <div className='flex flex-row items-center gap-4'>
                        <Avatar src={`${user.Picture && user.Picture.data !== null ? `${process.env.REACT_APP_STAGE === 'dev' ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${user.Picture.url}` : `${user?.Picture?.url}`}` : ""}`}
                          color={colorPicker(index)} name={user && user.email && user.email.slice(0, 1).toUpperCase()} size="sm" />
                        <div className='flex flex-col '>
                          <p className=''>{user.email && user.email.split('@')[0]}</p>
                          <p className='text-slate-400'>{user.username}</p>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>7</TableCell>
                    <TableCell>
                      <Chip variant='flat' color='success'>
                        Active
                      </Chip>
                    </TableCell>
                  </TableRow>
                })
                }
              </TableBody>
            </Table>
            : <EmptyArray message={"No members added."} />
          }
        </>}
      </div>
      {currentRecords.length > 10 && <div className='flex justify-center pt-8'>
        <Paginate
          totalPage={userDataList}
          initialPage={"1"}
          id={userDetails.userId}
          currentRecords={currentRecords}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          recordsPerPage={recordsPerPage}
        />
      </div>
      }
      <ModalPopup
        isOpen={isModalOpen}
        onOpenChange={onModalOpenChange}
        ModalBodyData={ModalBodyData}
        footer={footerCreate}
        size="3xl"
        scrollBehavior="inside"
      />
    </div>
  )
}

export default Members