import React, { useEffect, useState } from 'react'
import { CommonCollectionFilterApi } from '../../../controllers/strapiController';
import {avatarName, colorPicker} from "../../../controllers/basicControllers";
import { useParams } from 'react-router-dom';
import {Avatar, Tabs, Tab} from "@nextui-org/react";
import Settings from "../../../pages/subPages/Settings";
import Sandbox from './Sandbox';
import Logs from './Logs';
import Instructions from './Instructions';
import NotFound from '../../../components/NotFound';
import ServerIssue from '../../../components/ServerIssue';
import Forbidden from "../../../components/Forbbiden";
import { ConnectorSkeleton } from '../../../components/Skeleton';

const SingleAgent = () => {

  const [agent, setAgent] = useState(null);
  const [selected, setSelected] = useState();
  const [loader, setLoader] = useState(true);
  const [reload,setReload] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [forbidden, setForbidden] = useState(false);
  const [serverIssue, setServerIssue] = useState(false);
  const [connectionRefused, setConnectonRefused] = useState(false);
  const UserDetails = JSON.parse(sessionStorage.getItem("userData"));
  const UserID = UserDetails && UserDetails.user && UserDetails.user.id;
  const params = useParams();
  const CollectionName = "agents";


  useEffect(() => {
    const url = `filters[id][$in][0]=${params._id}&filters[space][Name][$eq]=${params._spaceid}&[populate][space][on]&[populate][data_product][on]&[populate][author][on]&[populate][inviteds][on]&[populate][requests][on]&[populate][viewed][on]`
    CommonCollectionFilterApi(CollectionName, url)
      .then((data) => {
        setLoader(false)
        if (data.error) {
          if (data.error.status !== 404 && data.error.status !== 403) { setServerIssue(true) }
          if (data.error.status === 404) { setNotFound(true) }
          if (data.error.status === 403) { setForbidden(true) }
        } else if (data.data.length > 0) {
          setLoader(false)
          setAgent(data.data?.[0])
        } else {
          setNotFound(true)
        }
      })
      .catch((error) => { setLoader(false); setConnectonRefused(true) })

  }, [reload, params._id, params._spaceid])

  if (loader) {
    return ConnectorSkeleton();
  }

  if (notFound) {
    return <NotFound />
  }

  if (serverIssue) {
    return <ServerIssue />
  }

  if (connectionRefused) {
    return <ServerIssue />
  }

  if (forbidden) {
    return <Forbidden />
  }
  return (
    <div>
      <div className='flex gap-4 items-center'>
        <Avatar
          radius='sm'
          src=""
          width={30} 
          color={colorPicker(agent?.id)}
          name={avatarName(agent?.attributes?.Name)}/>
        <div className='text-3xl font-medium'>{agent?.attributes?.Name}</div>
      </div>
      {/* Description */}
      <div className='mt-8'>
        {agent?.attributes?.Description}
      </div>
      <div className='pt-8'>
        <Tabs
          aria-label="Options"
          color={colorPicker(agent?.id)}
          variant="underlined"
          selectedKey={selected}
          defaultSelectedKey={""}
          onSelectionChange={setSelected}>
            <Tab key="APICall" title="API Call">
              Api calls
            </Tab>
          <Tab key="Sandbox" title="Sandbox">
            <Sandbox agent={agent} />
          </Tab>
          <Tab key="Logs" title="Logs">
            <Logs agent={agent} />
          </Tab>
          <Tab key="Instructions" title="Instructions">
            <Instructions collection={agent} setReload={setReload} setLoader={setLoader} />
          </Tab>
          <Tab key="Settings" title="Settings">
            <Settings collection={agent} setReload={setReload} setLoader={setLoader} isHide={true} collectionName="agents"/>
          </Tab>
        </Tabs>
      </div>
    </div>
  )
}

export default SingleAgent