import React from 'react'

export const DBErrorHandler = (formValues,response) => {
  const errors = {};
  let isValid=true;
  
    if (!formValues?.Name) {
      errors.Name = "Name is required.";
      isValid=false;
    } else if (formValues?.Name?.length < 3) {
      errors.Name = "Name must be at least 3 characters long.";
      isValid=false;
    }else if(response?.data?.length!==0){
      isValid=false;
      errors.Name = "Name of the data product already exists. Try with new name.";
    }
  
    if (!formValues?.Context) {
      errors.Context = "Context is required.";
      isValid=false;
    }
    
    if (!formValues?.Domain||formValues?.Domain===null) {
      errors.Domain = "Domain is required.";
      isValid=false;
    }
  
    return {errors, isValid};
}
