import React from 'react';
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem } from "@nextui-org/react";

const DropdownActions = ({ EditOpen, DeleteWarningHandle }) => {
    return (
        <div>
            <Dropdown placement="bottom-end">
                <DropdownTrigger>
                    <div className='flex items-center justify-evenly cursor-pointer'>
                        <span className='text-md font-bold text-slate-500'>...</span>
                    </div>
                </DropdownTrigger>
                <DropdownMenu aria-label="Profile Actions" variant="flat">
                    <DropdownItem key="edit" onClick={EditOpen}>
                        <div className='flex items-center gap-2'>
                            <lord-icon
                                src="https://cdn.lordicon.com/pflszboa.json"
                                colors={`primary:#000000`}
                                style={{ width: "16px", height: "16px" }}>
                            </lord-icon>
                            Edit
                        </div>
                    </DropdownItem>
                    <DropdownItem onClick={DeleteWarningHandle} key="configurations" className='text-danger' color="danger">
                        <div className='flex items-center gap-2'>
                            <lord-icon
                                src="https://cdn.lordicon.com/wpyrrmcq.json"
                                colors="primary:#e1426c"
                                style={{ width: "16px", height: "16px" }}>
                            </lord-icon>
                            Delete
                        </div>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    )
}

export default DropdownActions;