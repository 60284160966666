import { Button, Input } from '@nextui-org/react'
import React, { useState, useEffect } from 'react'
import { getOneData, CommonCollectionFilterApi } from "../../controllers/strapiController";
import { iconRender } from "../../controllers/basicControllers";
import ServerIssue from "../../components/ServerIssue";
import Loading from "../../components/Loader/Loading";
import { toast } from "react-hot-toast";


const CreateSpace = ({ onNext, setFormData, formData }) => {
    const [userData, setUserData] = useState();
    const [serverError, setServerError] = useState(false);
    const [loader, setLoader] = useState(true);
    const [validate, setValidate] = useState([]);
    const userDetails = JSON.parse(sessionStorage.getItem("userData"));
    const userID = userDetails && userDetails.user && userDetails.user.id;

    useEffect(() => {
        if (!userData) {
            setLoader(true);
            getOneData("users", userID)
                .then((data) => { setLoader(false); setUserData(data) })
                .catch((error) => {
                    setServerError(true)
                })
        } else {
            setLoader(false)
        }
    }, [])


    const replaceAll = () => {
        let userInput = formData.spaceName;
        userInput = userInput.replace(/[0-9]/g, '');
        userInput = userInput.replace(/[^a-zA-Z ]+/g, '-');
        userInput = userInput.replace(/\s+/g, ' ');
        const newValue = userInput.replace(/ /g, '-');
        formData.spaceName = newValue;
        return newValue
    }

    useEffect(() => {
        const url = `filters[Name][$in][1]=${replaceAll()}&[populate][admin][on]`
        CommonCollectionFilterApi("spaces", url)
            .then((data) => setValidate(data.data))
            .catch((error) => toast.error("Oops!, something went wrong, please try after some time."))
    }, [formData.spaceName, userID])

    const validateForm = () => {
        var isValid = true;

        if (validate && validate.length > 0) {
            isValid = false;
        }
        return isValid;
    }

    const navigateHandler = () => {
        // setFormData((prev) => ({ ...prev, spaceName: formData.spaceName }))
        if (validateForm()) {
            onNext();
        }
    }

    // TAGS INPUT ONCHANGE HANDLER
    const handleChange = (e) => {
        let name = e.target.value;
        name = name.replace(/[0-9]/g, '');
        setFormData((prev) => ({ ...prev, Name: name, spaceName: name?.toLowerCase() }));
    };


    if (serverError) {
        return <ServerIssue />
    }

    if (loader) {
        return <div className={`absolute z-[100]  inset-0 h-screen w-screen ${'bg-white'}`}>
            <Loading />
        </div>
    }

    if (userData) {
        return (
            <div className="">
                <h1 className='sm:text-left text-5xl tracking-[-0.16px] text-slate-12 font-medium'>
                    Create your
                    <span className='text-purple leading-snug'>
                        {" "} data mesh {" "}
                    </span>
                    application
                </h1>
                <div className='mt-12'>
                    <Input
                        radius="sm"
                        className='border-slate-6'
                        key={"outside"}
                        variant={"faded"}
                        value={formData.Name}
                        labelPlacement={"outside"}
                        placeholder="Your name, team, repository, company...."
                        size='lg'
                        onChange={handleChange}
                    />
                    {formData && formData.spaceName !== '' &&
                        <p className='text-base pt-2 flex flex-row' >
                            {process.env.REACT_APP_IP_ADDRESS}/<p className={`${validate && validate.length > 0 ? "text-red-400" : "text-emerald-500"}`}>{replaceAll()}
                                <span className={`pl-1 ${validate && validate.length > 0 ? "text-red-400" : "text-emerald-500"}`}>
                                    {validate && validate.length > 0 ? "(unavailable)" : "(available)"}
                                </span>
                            </p>
                        </p>
                    }
                </div>
                <div className='mt-8'>
                    <Button isDisabled={formData.spaceName === ''} onClick={navigateHandler} id='CreateAccount' radius="sm" size='lg' className={`w-full gap-1 text-white bg-black hover:bg-gray-700 hover:text-white lt-500`}>
                        Get started
                        {iconRender("https://cdn.lordicon.com/vduvxizq.json","CreateAccount")}
                        {/* <lord-icon
                            src="https://cdn.lordicon.com/vduvxizq.json"
                            trigger="hover"
                            target="#CreateAccount"
                            
                            style={{ width: "18px", height: "20px", color: "#ffffff" }}>
                        </lord-icon> */}
                    </Button>
                </div>
                <p className='text-small text-default-400 mt-8'>
                    Don't worry, you can always change this later
                </p>
            </div>
        )
    }
}

export default CreateSpace