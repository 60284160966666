import React from 'react';
import { Button, ModalBody, ModalHeader, Input, Textarea } from "@nextui-org/react";
import ModalPopup from "../Collections/ModalPopup";


const UpdateKey = ({ setFormdata, formData, formError, ValidationConditions, SubmitHandler, isApikeyOpen, onApikeyOpenChange, keysAction, setKeysAction }) => {

    // TAGS INPUT ONCHANGE HANDLER
    const handleChange = (e) => {
        const userInput = e.target.value;
        const regex = /^[a-zA-Z_ -]*$/;
        if (regex.test(userInput)) {
            setFormdata({ ...formData, Name: userInput })
        }
    }
    // Create page Modal body
    const ModalBodyData = () => {
        return <> <ModalHeader className="flex flex-col  gap-1">{keysAction ? "Create a new Api key" : "Update an Api key"}</ModalHeader>
            <ModalBody>
                <Input
                    isInvalid={ValidationConditions && !formData.Name}
                    isRequired
                    key="outside"
                    type="text"
                    label="Name"
                    value={formData.Name}
                    labelPlacement="outside"
                    placeholder="Enter your api key name"
                    errorMessage={!formData.Name ? formError.NameError : ""}
                    onChange={handleChange}
                />
                <Textarea
                    className='pt-8'
                    key={"description"}
                    isRequired
                    type="text"
                    minRows={6}
                    value={formData && formData.Description}
                    isInvalid={ValidationConditions && formData && (!formData.Description || formData.Description && formData.Description.length > 120)}
                    onChange={(e) => setFormdata({ ...formData, Description: e.target.value })}
                    label="Decription"
                    labelPlacement={"outside"}
                    errorMessage={ValidationConditions && formData && (!formData.Description || formData.Description && formData.Description.length > 120) ? formError.DescriptionError : ""}
                    placeholder="Enter your api key's Description"
                />
            </ModalBody>
        </>
    }

    const footerCreate = (onClose) => {
        return <><Button color="danger" variant="flat" onClick={() => { setFormdata({ Name: '', Description: '' }); onClose() }}>
            Cancel
        </Button>
            <Button color="success" onClick={() => { SubmitHandler(onClose) }}>
                {keysAction ? "Create" : "Update"}
            </Button></>
    }


    return (
        <div>
            <ModalPopup
                size="xl"
                isOpen={isApikeyOpen}
                onOpenChange={onApikeyOpenChange}
                ModalBodyData={ModalBodyData}
                footer={footerCreate}
                scrollBehavior="inside"
            />
        </div>
    )
}

export default UpdateKey;