import React, { useState, useRef, useEffect } from 'react';
import { Input, Textarea } from "@nextui-org/react";
import { getAllData } from "../../controllers/strapiController";
import Tags from '../../components/Tags';
import { toast } from "react-hot-toast";


const CreateTeamCollection = ({ collections, FilterInput, filteredData, formError, FilteredTags, UserID, formData, selectData, setFormdata, setTagsData, setTagInput, tagInput, tagsData, validationCondition }) => {
  const [tags, setTags] = useState([]);

  const [Validatetags, setValidatetags] = useState('');
  const [Loader, setLoader] = useState(true);

  const ref = useRef();

  useEffect(() => {
    getAllData("tags")
      .then((data) => {
        setTagsData(data.data)
        setTimeout(() => { setLoader(false) }, 300)
      })
      .catch((error) => toast.error("Oops!, something went wrong, please try after some time."))

  }, [])


  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [tagInput])


  return (
    <div className="pt-4">
      <>
        <Input
          isRequired
          key="name"
          type="text"
          value={formData.Name}
          onChange={(e) => { setFormdata({ ...formData, Name: e.target.value }) }}
          isInvalid={(!formData.Name || (filteredData && filteredData.length !== 0)) && validationCondition ? (!formData.Name || (filteredData && filteredData.length !== 0)) && validationCondition : ''}
          errorMessage={(!formData.Name || (filteredData && filteredData.length !== 0)) && validationCondition ? formError.NameError : ""}
          label="Collection Name"
          labelPlacement="outside"
          placeholder="Enter your collection folder name"
        />
        <Textarea
          className='pt-4'
          key={"description"}
          isRequired
          type="text"
          value={formData.Description}
          onChange={(e) => { setFormdata({ ...formData, Description: e.target.value }) }}
          isInvalid={!formData.Description && validationCondition ? !formData.Description || validationCondition : ''}
          errorMessage={!formData.Description && validationCondition ? formError.DescriptionError : ""}
          label="Decription"
          labelPlacement={"outside"}
          placeholder="Enter your collection's Description"
        />
        <Tags

          tagsData={tagsData}
          setTagsData={setTagsData}
          tagInput={tagInput}
          setTagInput={setTagInput}
          tags={tags}
          validatetags={Validatetags}
          setTags={setTags}
          setValidatetags={setValidatetags}
          setFormdata={setFormdata}
          formData={formData}
          background={"bg-transparent"}
        />
      </>
    </div>
  )
}

export default CreateTeamCollection;