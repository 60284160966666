import React, { useState, useEffect } from 'react';
import { Input, Button } from "@nextui-org/react";
import { UpdateData } from "../../controllers/strapiController";
import { toast } from "react-hot-toast";
import { test_schema } from '../../controllers/vectordbController';
import ButtonSpinner from '../../components/Loader/ButtonSpinner';
import { convertKeysToLowercase } from '../../controllers/basicControllers';

const Configuration = ({ collection, setReload, setLoader }) => {
    const [formValue, setFormValue] = useState({});
    const [errors, setErrors] = useState({});
    const [buttonLoad, setButtonLoad] = useState(false);

    const JsonData = collection?.attributes?.data_product?.data?.attributes?.Schema;
    const jsonValue = collection?.attributes?.ConnectionJSON?.properties;

    useEffect(() => {
        JsonData?.properties?.map((data) => (
            setFormValue(prev => ({
                ...prev,
                [data?.name]: jsonValue?.[data?.name]
            })
            )))
    }, [JsonData, jsonValue])


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValue((prev) => ({
            ...prev,
            [name]: value,
        }));
        // Clear error on input change
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }

    const validateField = (field, value) => {
        let error = '';
        if (!value) {
            error = `${field.name} is required`;
        }
        return error;
    }

    const validation = () => {
        let isValid = true;
        const newErrors = {};

        JsonData?.properties?.forEach((field) => {
            const error = validateField(field, formValue[field.name]);
            if (error) {
                newErrors[field.name] = error;
                isValid = false;
            }
        });
        setErrors(newErrors);
        return isValid;
    }


    const handleSubmit = async () => {
        const validate = validation();
        if (validate) {
            setButtonLoad(true);
            if (collection?.attributes.Type === "connectors") {
                const ConnectionPayload = {
                    source: collection?.attributes?.data_product?.data?.attributes?.Name,
                    ...formValue
                };
                const ConnectionPayloadLowerCase = convertKeysToLowercase(ConnectionPayload);
                const response = await test_schema(ConnectionPayloadLowerCase)

                if (response?.tables) {
                    setButtonLoad(false);
                    const payload = {
                        ConnectionJSON: {
                            properties: formValue
                        }
                    }
                    const response = await UpdateData("collections", collection?.id, payload);
                    if (response) {
                        setReload(response);
                        toast.success("collection updated successfully.");
                    }
                } else {
                    setButtonLoad(false);
                    toast.error("Incorrect database credentials.")
                }
            } else {
                const payload = {
                    ConnectionJSON: {
                        properties: formValue
                    }
                }
                const response = await UpdateData("collections", collection?.id, payload);
                if (response) {
                    setReload(response);
                    toast.success("collection updated successfully");
                }
            }
        }
    }

    const formCreater = () => {
        return (
            <div className='flex flex-col gap-8 pt-8'>
                {JsonData?.properties?.map((data) => (
                    <Input
                        isRequired
                        key={data.name} // Ensure unique key for each input
                        type="text"
                        htmlFor={data.name}
                        name={data.name}
                        value={formValue?.[data.name] || ''} // Set value from formValues state
                        onChange={handleInputChange} // Update formValues state on change
                        label={data.name}
                        labelPlacement="outside"
                        placeholder={`Enter a ${data.name}`}
                        errorMessage={errors?.[data.name]} // Show error message
                        isInvalid={!!errors?.[data.name]}
                    />
                ))}
            </div>
        );
    };
    return (
        <div>
            <h1 className="pt-8 pb-8 text-xl font-medium">Configuration</h1>
            {formCreater()}
            <div className="pt-8 pr-2 flex flex-row gap-4 justify-end">
                {/* <Button variant="light" onClick={onPrevious}>Back</Button> */}
                <Button isLoading={buttonLoad} color="danger" spinner={buttonLoad ? ButtonSpinner() : ""} onClick={handleSubmit}>Update</Button>
            </div>
        </div>
    )
}

export default Configuration;