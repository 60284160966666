import { Button, TableRow, TableCell, AvatarGroup, useDisclosure, Tooltip } from '@nextui-org/react'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import TableComponent from '../../NextUI/Table';
import { getAllData, DeleteSingleAttribute } from "../../controllers/strapiController"
import { avatarName, colorPicker, dateFormat, iconRender, StringLimit, timeDifference, titleCreater } from "../../controllers/basicControllers";
import { ErrorHandler } from '../../Utils/ErrorHandler';
import NotFound from "../../components/NotFound";
import ServerIssue from "../../components/ServerIssue";
import Forbbiden from "../../components/Forbbiden";
import UnAuthorized from "../../components/UnAuthorized";
import DeleteModal from "../../components/DeleteModal";
import Paginate from '../../components/Pagination';
import AvatarComponent from '../../NextUI/Avatar';

const Templates = () => {
  // STATE INITIALITION
  const navigate = useNavigate();
  const [templates, setTemplates] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [forbidden, setForbidden] = useState(false);
  const [serverIssue, setServerIssue] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteData, setDeleteData] = useState();
  const [reload, setReloader] = useState();
  const { isOpen: isDelete, onOpen: onDelete, onOpenChange: onDeleteChange } = useDisclosure();

  // TEMPLETE API RESPONSE
  useEffect(() => {
    getAllData("templates")
      .then((data) => {
        if (data?.error) { ErrorHandler(data.error, { setNotFound, setForbidden, setServerIssue, setAuthorized }) }
        else { setTemplates(data?.data) }
      })
      .catch((error) => setServerIssue(true))
  }, [reload])

  // TABLE HEADER
  const headerComponent = ["Name", "Description", "Created At", "Data Products", ""];

  // DELETE HANDLE CHANGE
  const deleteOnchange = (data, e) => {
    e.stopPropagation();
    onDelete();
    setDeleteData(data);
  }

  // DELETE HANDLER
  const DeleteHandler = async () => {
    const response = await DeleteSingleAttribute("templates", deleteData.id)
    if (response) {
      setReloader(response);
    }
  }

  const recordsPerPage = 10;

  // Calculate indexes for pagination
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = templates && templates.slice(indexOfFirstRecord, indexOfLastRecord);

  // DATA PRODUCT LIST
  const DataProducts = (data, index) => {
    return data?.map((item) => {
      return <AvatarComponent data={""} name={avatarName(item?.attributes?.Name)} color={colorPicker(index)} />
    })
  }

  // TABLE BODY
  const bodyComponent = () => {
    return currentRecords?.map((data, index) => {
      return <TableRow key={`${index}`} className='cursor-pointer' onClick={()=>navigate(`${data?.id}`)}>
        <TableCell>{titleCreater(data?.attributes?.Name)}</TableCell>
        <TableCell>{StringLimit(data?.attributes?.Description, 80)}</TableCell>
        <TableCell>{dateFormat(data?.attributes?.createdAt)}</TableCell>
        <TableCell className='flex'>
          <AvatarGroup max={3} size="sm">
            {DataProducts(data?.attributes?.data_products?.data, index)}
          </AvatarGroup>
        </TableCell>
        <TableCell>
          <div onClick={(e) => deleteOnchange(data, e)}>
            <Tooltip content='Delete' placement='left'>
              {iconRender("https://cdn.lordicon.com/wpyrrmcq.json", "Delete")}
            </Tooltip>
          </div>
        </TableCell>
      </TableRow>
    })
  }

  // ERROR CONDITIONS
  if (notFound) return <NotFound />;
  if (serverIssue) return <ServerIssue />;
  if (forbidden) return <Forbbiden />;
  if (authorized) return <UnAuthorized />;

  return (
    <div>
      <div className='text-3xl font-medium'>Templates</div>
      <div className='mt-4 leading-relaxed'>
        Templates provide predefined structures and best practices for creating domains, data products, and governance frameworks, ensuring a consistent and scalable approach to data management across your organization. Easily customize templates to fit your specific business needs and accelerate your data mesh implementation.
      </div>
      <div className='flex w-full justify-end mt-4'>
        <Button color='primary' onClick={() => navigate('create')}>Create a Template</Button>
      </div>
      <div className='pt-8'>
        <TableComponent headerComponent={headerComponent} bodyComponent={bodyComponent} emptyContent={"No rows created yet."} />
      </div>
      {templates?.length > 10 && <div className='flex justify-end pt-8'>
        <Paginate
          color={'secondary'}
          totalPage={templates}
          currentRecords={currentRecords}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          recordsPerPage={recordsPerPage}
        />
      </div>}
      <DeleteModal
        isOpen={isDelete}
        onOpenChange={onDeleteChange}
        Collectionname={deleteData?.attributes?.Name}
        setLoader={""}
        DeleteHandler={DeleteHandler} />
    </div>
  )
}

export default Templates