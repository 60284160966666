import React from 'react';
import { Card, CardHeader, CardBody } from "@nextui-org/react";
import { InfoIcon } from '../../components/InfoIcon';

const ContentTouring = () => {
    // ANALYTIC CARDS TITLE RENDERING
    const titleRendering = (title, message) => {
        return <div className='flex flex-row gap-2 items-center'>
            <p>{title}</p>
            <InfoIcon

                message={message}
                placement={"right"}
                size={"sm"} />
        </div>
    }
    return (
        <div className='flex flex-col gap-2 w-full'>
            <h1 className='pt-8 pb-4 text-xl'>Content</h1>
            <div className='flex flex-row gap-4 w-full'>
                <div className=' w-full'>
                    <Card className='w-full h-[300px] mt-2 border border-divider' shadow='none' >
                        <CardHeader className='pb-0'>
                            <div className='flex fle-row justify-between w-full'>
                                {titleRendering("Most referred documents", 'Use variables in a form to automatically replace the variables in the prompt')}
                            </div>
                        </CardHeader>
                        <CardBody></CardBody>
                    </Card>
                </div>
                <div className=' w-full'>
                    <Card className='w-full h-[300px] mt-2 border border-divider' shadow='none'>
                        <CardHeader className='pb-0'>
                            <div className='flex fle-row justify-between w-full'>
                                {titleRendering("Least referred documents", 'Use variables in a form to automatically replace the variables in the prompt')}
                            </div>
                        </CardHeader>
                        <CardBody></CardBody>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default ContentTouring;