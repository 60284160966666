import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, useDisclosure, ModalBody, ModalHeader } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import ModalPopup from "../Collections/ModalPopup";
import CustomToast from "../../components/CustomToast";
import { getOneData, userLoginDetails } from "../../controllers/strapiController";

const Credits = () => {
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const navigate = useNavigate();
    const userData = userLoginDetails();
    const [userInfo,setUserInfo] = useState();
    const credits = sessionStorage.getItem('Credits');


    useEffect(()=>{
         getOneData("users",userData?.userDetails?.user?.id)
         .then(data=>setUserInfo(data))
         .catch(error=>CustomToast("Oops!, something went wrong, please try after some time.", "error"))
    },[])

    console.log("userInfo",userInfo)
    // const arrayData=

    const ModalBodyData = () => {
        return <>
            <ModalHeader className="-mt-4">Credits</ModalHeader>
            <ModalBody>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed consequatur nihil, vero earum ipsum, debitis cum neque vitae modi error quibusdam? Dignissimos eveniet ratione ex expedita, veniam dolor? Iure, commodi!
            </ModalBody>
        </>
    }

    const footerCreate = () => {
        return <></>
    }
    return (
        <div className='flex flex-col pt-8'>
            <div className='w-full m-auto flex flex-col gap-6 px-4 pt-8 h-[calc(100vh-80px)]'>
                <div className='text-slate-400 cursor-pointer' onClick={() => navigate(-1)}>
                    <Button
                        isIconOnly
                        color="danger"
                        className='mb-2 mr-2'
                        radius='full'
                        size='sm'
                        aria-label="Like"
                        onClick={() => navigate(-1)}
                        startContent={<FontAwesomeIcon icon={faArrowLeft} />}
                    ></Button>
                    Go back
                </div>
                <Card shadow="none" className='border border-divider min-h-[103px]'>
                    <CardBody>
                        <div className='flex justify-between'>
                            <div className='flex flex-row gap-1 items-center'>
                                <div>
                                    <svg width="1em" height="1em" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-24 h-24 -ml-3 -mr-1 -my-3 pointer-events-none text-accent-onion-default"><path fill-rule="evenodd" clip-rule="evenodd" d="M8 13.605A5.333 5.333 0 108 2.938a5.333 5.333 0 000 10.667zm1.213-8.672a.494.494 0 00-.812-.517L4.944 7.922a.494.494 0 00.35.843H7.82l-1.034 2.844a.494.494 0 00.812.518l3.456-3.507a.494.494 0 00-.348-.842H8.179l1.034-2.845z" fill="#fa3ec2"></path></svg>
                                </div>
                                <div>
                                    <p class='text-xs text-default-400 flex items-center gap-2'>
                                        CREDITS
                                        <lord-icon
                                            onClick={onOpen}
                                            src="https://cdn.lordicon.com/yxczfiyc.json"
                                            trigger="hover"
                                            style={{ width: "14px", height: "14px", cursor: 'pointer' }}>
                                        </lord-icon>
                                    </p>
                                    <h1 className='text-5xl text-default-700'>{credits ? credits :userInfo?.Credit}</h1>
                                </div>
                            </div>
                            <div class='flex items-center pr-3'>
                                <Button size='sm' color={"danger"}>BUY MORE</Button>
                            </div>
                        </div>
                    </CardBody>
                </Card>
                <div class='h-full pb-8'>
                    <Card shadow='none' className='h-full border border-divider'>
                        <CardBody>
                            {userInfo?.CreditsLog?.Logs?.map(item => {
                                return <div className='flex justify-between p-4 border-b border-divider'>
                                    <div>{item.path}</div>
                                    <div>{item.weight}</div>
                                </div>
                            })}
                        </CardBody>
                    </Card>
                </div>
            </div>
            <ModalPopup
                size="lg"
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                ModalBodyData={ModalBodyData}
                footer={footerCreate}
                scrollBehavior="inside"
            />
        </div>
    )
}

export default Credits;