import React, { useState } from 'react';
import { Progress } from "@nextui-org/react";
import SelectdataProduct from "../DataProducts/SelectdataProduct"
import TemplateForm from './TemplateForm';
import ButtonComponent from '../../NextUI/Button';
import { CreateData } from "../../controllers/strapiController";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

const initialState = { Name: '', Description: '', Switch: false };

const CreateTemplate = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({ form: initialState, type: [] });
  const navigate = useNavigate();

  const nextHandler = () => {
    setStep(previous => previous + 1)
  }

  const previousHandler = () => {
    setStep(previous => previous - 1)
  }

  const buildRelation = () => {
    const Connect = formData?.type?.map((data, index) => {
      if (index === 0) {
        return { id: data, position: { end: true } }
      } else {
        return { id: data, position: { before: formData?.type?.[index - 1] } }
      }
    })

    return Connect
  }


  const createTemplateHandler = async () => {
    const payload = {
      Name: formData?.form?.Name,
      Description: formData?.form?.Description,
      space_type: formData?.form?.Switch?"Enterprises":"Shared",
      data_products: {
        disConnect: [],
        connect: buildRelation()
      }
    }
    const response = await CreateData("templates", payload);
    if (response?.data) {
      navigate(-1);
      toast.success("Template Created Successfully.")
    } else if (response?.error) {
      toast.error("Something went wrong. try after some time.")
    }
  }

  const renderPage = () => {
    switch (step) {
      case 1:
        return <TemplateForm onNext={nextHandler} setFormData={setFormData} formData={formData} />
      case 2:
        return <><SelectdataProduct  onNext={nextHandler} setFormData={setFormData} formData={formData} multiSelect={true} />
          <div className='flex flex-row justify-end'>
            <ButtonComponent title="Previous" variant="none" color="none" func={() => previousHandler()} />
            <ButtonComponent title="Submit" color="danger" isDisabled={formData?.type?.length === 0} func={() => createTemplateHandler()} />
          </div>
        </>
      default: return null
    }
  }
  return (
    <div>
      <div className='text-3xl font-medium'>Create a new template</div>
      <div className='mt-4 leading-relaxed'>
        Create a customized data mesh template to meet your organization's unique data management needs. Define essential elements like domains and data products, tailored for specific use cases to enhance data governance and scalability.
      </div>
      <div className='pt-8'>
        <Progress
          size="sm"
          radius="sm"
          classNames={{ base: "max-w-lg" }}
          color='secondary'
          value={step === 1 ? 0 : 50 * (step - 1)}
        />
        <div className='pt-8'>
          {renderPage()}
        </div>
      </div>
    </div>
  )
}

export default CreateTemplate