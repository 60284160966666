import { Card, CardBody, CardHeader, Chip, Image, Tooltip } from "@nextui-org/react";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { colorPicker, getTimeDifference, getTimeDifferenceInNano, StringLimit } from "../controllers/basicControllers";

const CardTags = ({ tags }) => (
    <div className='flex pt-2 gap-3'>
        {tags && tags !== null && tags?.slice(0, 3).map((tag, index) => (
            <Chip key={index} size='sm' color={colorPicker(tag.length)} variant='flat'>
                {tag}
            </Chip>
        ))}
        {tags?.length > 3 && (
            <Tooltip
                content={tags.slice(3).map((tag, index) => (
                    <div key={index}>{tag}</div>
                ))}
                placement='bottom'
                size='sm'
                closeDelay={10}
            >
                <Chip size='sm' variant="flat">
                    + {tags?.length - 3}
                </Chip>
            </Tooltip>
        )}
    </div>
);

const ProductCards = ({ product, data_prod }) => {
  const navigate = useNavigate();
  const { _spaceid } = useParams();
  return (
    <Card
      className="max-w-[340px] min-w-[340px] h-[260px] p-2 border border-divider cursor-pointer"
      shadow="none"
      isPressable
      onClick={() =>
        navigate(`/${_spaceid}/${product.payload.type}/${product.payload.id}`)
      }
    >
      <CardHeader className="justify-between flex items-center">
        <div className="flex gap-5">
          <Image
            src={
              data_prod.find(
                (a) => a.attributes.Name === product.payload.data_product
              ).attributes.Image
            }
            width={40}
          />
          <div className="flex flex-col gap-1 items-start justify-center">
            <h4 className="text-small font-semibold leading-none text-default-600">
              {product?.payload?.name}
            </h4>
            <h5 className="text-small tracking-tight text-default-400">
              Created {getTimeDifferenceInNano(product?.payload?.id)}
            </h5>
          </div>
        </div>
      </CardHeader>
      <CardBody className="px-3 py-0 pb-4 text-small flex flex-col justify-between text-default-500">
                <p className='pb-4'>
                    {StringLimit(product?.payload?.description, 120)}
                </p>
                <CardTags tags={product?.payload?.tags} />
            </CardBody>
    </Card>
  );
};

export default ProductCards;
