import React from 'react';
import ChartGenerator from './ChartGenerator';

const areaData = [
  { name: 'Page A', uv: 4000, pv: 2400, amt: 2400 },
  { name: 'Page B', uv: 3000, pv: 1398, amt: 2210 },
  { name: 'Page C', uv: 2000, pv: 9800, amt: 2290 },
  { name: 'Page D', uv: 2780, pv: 3908, amt: 2000 },
  { name: 'Page E', uv: 1890, pv: 4800, amt: 2181 },
  { name: 'Page F', uv: 2390, pv: 3800, amt: 2500 },
  { name: 'Page G', uv: 3490, pv: 4300, amt: 2100 },
];

const barData = [
  { "name": "arun.n", "value": 1708 },
  { "name": "root", "value": 73 },
  { "name": "null", "value": 26 },
  { "name": "Manoj", "value": 23 },
  { "name": "jeadam", "value": 10 }
]


const pieData = [
  { name: 'Group A', value: 400, color: '#8884d8' },
  { name: 'Group B', value: 300, color: '#82ca9d' },
  { name: 'Group C', value: 300, color: '#ffc658' },
  { name: 'Group D', value: 200, color: '#ff7300' },
];

const scatterData = [
  { x: 1, y: 4000 },
  { x: 2, y: 3000 },
  { x: 3, y: 2000 },
  { x: 4, y: 2780 },
  { x: 5, y: 1890 },
  { x: 6, y: 2390 },
  { x: 7, y: 3490 },
];

const lineData = [
  { name: 'Page A', uv: 4000, pv: 2400 },
  { name: 'Page B', uv: 3000, pv: 1398 },
  { name: 'Page C', uv: 2000, pv: 9800 },
  { name: 'Page D', uv: 2780, pv: 3908 },
  { name: 'Page E', uv: 1890, pv: 4800 },
  { name: 'Page F', uv: 2390, pv: 3800 },
  { name: 'Page G', uv: 3490, pv: 4300 },
];

const radarData = [
  { subject: 'Math', A: 120, B: 110, fullMark: 150 },
  { subject: 'Chinese', A: 98, B: 130, fullMark: 150 },
  { subject: 'English', A: 86, B: 130, fullMark: 150 },
  { subject: 'Geography', A: 99, B: 100, fullMark: 150 },
  { subject: 'Physics', A: 85, B: 90, fullMark: 150 },
];

const Charts = () => {
  return (
    <div>
      <h2>Area Chart</h2>
      <ChartGenerator chartType="area" data={areaData} />

      <h2>Bar Chart</h2>
      <ChartGenerator chartType="bar" data={barData} />

      <h2>Pie Chart</h2>
      <ChartGenerator chartType="pie" data={pieData} />

      <h2>Scatter Chart</h2>
      <ChartGenerator chartType="scatter" data={scatterData} />

      <h2>Line Chart</h2>
      <ChartGenerator chartType="line" data={lineData} />

      <h2>Radar Chart</h2>
      <ChartGenerator chartType="radar" data={radarData} />
    </div>
  );
};

export default Charts;
