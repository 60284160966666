import { Button, Card, CardHeader, CardBody, Image, CardFooter, Link, Input, TableRow, TableCell, Avatar, Chip, Tooltip } from "@nextui-org/react";
import { StringLimit, titleCreater, avatarName, colorPicker, dateFormat } from "../../controllers/basicControllers";
import { useNavigate } from "react-router-dom";
import SearchIcon from '../../Images/Search';
import { useState } from "react";
import TableComponent from "../../NextUI/Table";

export const Dataproducts = ({ filteredProducts }) => {

    return <div className='max-w-[1200px] pt-8 mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8'>
        {filteredProducts?.length > 0 ? filteredProducts?.map((data) => (
            <div key={data.id}>
                <Card shadow='none' className={`border border-divider px-4 py-2 w-full h-[200px] cursor-pointer`}>
                    <CardHeader className='text-lg gap-2 flex items-center'>
                        <Image src={data.attributes.Image} width={20} />
                        <span>{data.attributes.Name}</span>
                    </CardHeader>
                    <CardBody>
                        <div className='text-default-500'>
                            {StringLimit(data.attributes.Description, 100)}
                        </div>
                    </CardBody>
                </Card>
            </div>
        )) : (
            <div className="text-center w-full col-span-3">No products found</div>
        )}
    </div>
}

export const Explore = ({ dataProducts, handleChange, formData, params }) => {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearch = (e) => {
        setSearchQuery(e.target.value.toLowerCase());
    }

    const filteredProducts = dataProducts?.filter((data) =>
        data.attributes.Name.toLowerCase().includes(searchQuery)
    );
    return <>
        <div className='py-8'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta neque quibusdam, ducimus sequi optio quo nobis repudiandae aliquam nostrum doloribus ea modi animi non distinctio suscipit iusto commodi veritatis rem!
        </div>
        <Input
            placeholder='Search for a data product'
            className='pb-8'
            startContent={<SearchIcon className={`text-large text- pointer-events-none flex-shrink-0 `} />}
            value={searchQuery}
            onChange={handleSearch}
        />
        <div className='max-w-[1200px] mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8'>
            {filteredProducts?.length > 0 ? filteredProducts?.map((data) => (
                <div key={data.id} onClick={() => handleChange(data)}>
                    <Card shadow='none' className={`border border-divider px-4 py-2 w-full h-[200px] cursor-pointer ${formData?.includes(data.id) ? "border-secondary" : "border-divider"}`}>
                        <CardHeader className='text-lg gap-2 flex items-center'>
                            <Image src={data.attributes.Image} width={20} />
                            <span>{data.attributes.Name}</span>
                        </CardHeader>
                        <CardBody>
                            <div className='text-default-500'>
                                {StringLimit(data.attributes.Description, 70)}
                            </div>
                        </CardBody>
                        <CardFooter>
                            <div className='flex flex-row justify-end w-full'>
                                <Button size="sm" color='default' variant='light'>
                                    <Link
                                        onClick={() => { navigate(`data-products/${data?.id}`) }}
                                        isExternal
                                        showAnchorIcon
                                        color="foreground"
                                        size="sm"
                                    >
                                        Learn More
                                    </Link>
                                </Button>
                            </div>
                        </CardFooter>
                    </Card>
                </div>
            )) : (
                <div className="text-center w-full col-span-3">No products found</div>
            )}
        </div>
    </>
}

export const Applications = ({ data }) => {
    const headerComponent = ['Name', 'Data Products', 'Created At '];
    const dataproductList = data?.attributes?.spaces?.data;

    const bodyComponent = () => {
        return dataproductList?.map((item, index) => (
            <TableRow key={`${index}`} className='cursor-pointer'>
                <TableCell>
                    <div className='flex flex-row items-center gap-2'>
                        <Avatar size='sm' src='' color={colorPicker(item?.id)} name={avatarName(item?.attributes?.Name)} />
                        {titleCreater(item?.attributes?.Name)}
                    </div>
                </TableCell>
                <TableCell>
                    <div className='flex flex-wrap gap-2'>
                        {item?.attributes?.data_products?.data?.slice(0, 5).map((dataproduct, i) => {
                            return <Chip color={colorPicker(item?.id)} size='sm' variant="flat">{dataproduct?.attributes?.Name}</Chip>
                        })}
                        {item?.attributes?.data_products?.data?.length > 5 &&
                            <Tooltip
                                key={"bottom"}
                                size='sm'
                                placement='bottom'
                                content={item?.attributes?.data_products?.data?.slice(5)?.map((data) => <p>{data?.attributes?.Name}</p>)}
                                color=""
                            >
                                <Chip size='sm' color={colorPicker(item?.id)} variant="flat">more...</Chip>
                            </Tooltip>}
                    </div>
                </TableCell>
                <TableCell>{dateFormat(item?.attributes?.createdAt)}</TableCell>
            </TableRow>
        ));
    }
    return <div className='pt-8'>
        <TableComponent headerComponent={headerComponent} bodyComponent={bodyComponent} emptyContent={"No rows created yet."} />
    </div>
}