import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader,Code } from "@nextui-org/react";
import React from "react";

const Details = ({ isOpen, setIsOpen, instruction }) => {
  const convertString = (arr) => {
    let str = "";
    arr?.map((it) => {
      if (str === "") {
        str = it;
      } else {
        str = `${str}, ${it}`;
      }
    });
    return str;
  };
  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <ModalContent>
        <ModalHeader className="flex items-center justify-center" >About {instruction?.InstructionName}</ModalHeader>
        <ModalBody className="flex flex-col gap-4">
          <div className="text-sm text-warmGray-400 ">
            {instruction?.Description}
          </div>
          <Code  className="bg-black text-white !whitespace-normal break-words" children={<p className="break-words" >{instruction?.InstructionPrompt}</p>} size="sm" color="default"></Code>
          <div className="flex gap-2 flex-col">
            <div>Variables</div>
            <div className="text-sm" >{convertString(instruction?.variables)}</div>
          </div>
        </ModalBody>
        <ModalFooter className="flex flex-col gap-4" >
            <div>Try this instruction?</div>
            <div className="flex items-center justify-between" >
                <Button size="sm" onClick={() => setIsOpen(false)} color="danger" >Cancel</Button>
                <Button size="sm" color="primary" >Use prompt</Button>
            </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Details;
