import React, { useState, useEffect } from 'react';
import { Select, SelectItem } from '@nextui-org/react';
import BreadcrumbsTabs from "../../components/BreadcrumbsTabs";
import { CommonCollectionFilterApi, userLoginDetails } from '../../controllers/strapiController';
import { useParams } from 'react-router-dom';

const AgentDropdown = ({ setSelectedAgent, selectedAgent }) => {

    const [agentList, setAgentList] = useState(null);
    const userId = userLoginDetails()?.userId;
    const params = useParams();
    const collectionName = "agents";
    const spaceName = params?._spaceid;


    useEffect(() => {
        const url = `filters[space][Name][$eq]=${spaceName}`;
        CommonCollectionFilterApi(collectionName, url)
            .then(data => setAgentList(data?.data))
            .catch(error => error)
    }, [userId])


    return (
        <div className='flex flex-row justify-between'>
            <div className='w-full'>
                <BreadcrumbsTabs />
            </div>
            {/* <div className='w-full flex justify-end'>
                <Select
                    defaultSelectedKeys={["Haya"]}
                    size="sm"
                    className="max-w-[200px]">
                    <SelectItem key='Haya'>Haya</SelectItem>
                    {agentList?.length>0&&agentList?.map((data,index)=>{
                        return <SelectItem key={data?.id}>{data?.attributes?.Name}</SelectItem>
                    })}
                </Select>
            </div> */}
        </div>
    )
}

export default AgentDropdown;