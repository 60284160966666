import ModalPopup from '../Collections/ModalPopup';
import React, { useState, useEffect } from 'react';
import { get_trash } from "../../controllers/vectordbController";
import { StringLimit } from "../../controllers/basicControllers";
import { Dropdown, Button, ModalBody, ModalHeader, useDisclosure, DropdownTrigger, DropdownItem, DropdownMenu, Chip, Card, CardHeader, CardBody, Image, Input, ModalFooter, } from '@nextui-org/react'
import { data } from "../../pages/Connectors/ConnectorData";
import { useNavigate, useParams } from "react-router-dom";
import CustomToast from '../../components/CustomToast';

const Trash = () => {
  // STATE INITIALIZATION
  const [inputData, setInputData] = useState(null);
  const { isOpen: isTrashOpen, onOpen: onTrashOpen, onOpenChange: onOpenTrashChange, onClose } = useDisclosure();
  const params = useParams();

  const DeleteHandler = () => {
    onClose()
  }
  const ModalBodyData = () => {
    return <>
      <ModalHeader className="flex flex-col gap-1">Empty trash</ModalHeader>
      <ModalBody className='gap-0'>
        Are you sure you want to empty your trash? This action is irreversible.
        <div className='mt-8'>
          <Input
            key="outside"
            type="text"
            radius="sm"
            className='border rounded-lg border-content4'
            label={<p className=''>Type <strong>DELETE</strong> to confirm.</p>}
            labelPlacement="outside"
            placeholder=' '
            onChange={(e) => setInputData(e.target.value)}
          />
        </div>
      </ModalBody>
    </>
  }

  const footerCreate = () => {
    return <>
      <ModalFooter >
        <Button className='text-bold text-white' isDisabled={inputData !== "DELETE"} color="danger" onClick={() => DeleteHandler(onClose)}>
          Empty Trash
        </Button>
        <Button onClick={() => { onClose() }} variant="light" >
          Cancel
        </Button>
      </ModalFooter>
    </>
  }
  const [documents, setDocuments] = useState([]);


  useEffect(() => {
    get_trash(params._spaceid)
      .then((data) => setDocuments(data.documents))
      .catch((error) => error)
  }, [params._spaceid])


  const filterUniqueEntries = (array) => {
    const uniqueEntries = [];
    const uniqueIds = new Set();

    array?.forEach(item => {
      if (!uniqueIds.has(item.file_document_id)) {
        uniqueIds.add(item.file_document_id);
        uniqueEntries.push(item);
      }
    });

    return uniqueEntries;
  }

  const uniqueDataArray = filterUniqueEntries(documents);

  const Navigate = useNavigate();
  const NavigateHandler = (id) => {
    Navigate(`/${params._spaceid}/doc/${id}`)
  }

  return (
    <div>
      <div className='text-3xl font-medium flex items-center gap-8 '>
        Trash
        <Button onPress={onTrashOpen} size='sm' className={`bg-white text-black`}>
          Empty Trash
        </Button>
      </div>
      <div className='mt-4 leading-relaxed'>
        Items in trash will be permanently deleted after 30 days.
      </div>
      <div className='pt-8 flex flex-wrap gap-8'>
        {uniqueDataArray && uniqueDataArray.length > 0 ?
          <div className='pt-8 flex flex-wrap gap-8'>
            {uniqueDataArray && uniqueDataArray.map((item) => {

              const filteredData = data && data.find((arr) => arr.value === item.payload.source);
              return <div key={item?.id} onClick={() => NavigateHandler(item.payload.file_document_id)}>
                <Card className="max-w-[550px] border border-divider cursor-pointer " shadow='none'>
                  <CardHeader className="flex gap-3 justify-between px-4 pt-4">
                    <div className='flex justify-between gap-3'>
                      <Image
                        alt="nextui logo"
                        height={40}
                        radius="sm"
                        src={filteredData && filteredData.avatar}
                        width={40}
                      />
                      <div className="flex flex-col">
                        <p className="text-md">{item.payload.document_title}</p>
                        <div className='text-sm text-warmGray-500'>
                          Sreevatsan • Uploaded 2 hours ago
                        </div>
                      </div>
                    </div>
                    <Dropdown placement="bottom-end">
                      <DropdownTrigger>
                        <div className='flex items-center justify-evenly cursor-pointer'>
                          <span className='text-md font-bold text-slate-500'>...</span>
                        </div>
                      </DropdownTrigger>
                      <DropdownMenu aria-label="Profile Actions" variant="flat">
                        <DropdownItem key="system">
                          <div className='flex items-center gap-2'>
                            <lord-icon
                              src="https://cdn.lordicon.com/vuiggmtc.json"
                              colors={`primary:"#000000"`}
                              trigger="hover"
                              style={{ width: "16px", height: "16px" }}>
                            </lord-icon>
                            Restore
                          </div>
                        </DropdownItem>
                        <DropdownItem key="configurations" className='text-danger' color="danger">
                          <div className='flex items-center gap-2'>
                            <lord-icon
                              src="https://cdn.lordicon.com/wpyrrmcq.json"
                              colors="primary:#e1426c"
                              style={{ width: "16px", height: "16px" }}>
                            </lord-icon>
                            Delete Permanently
                          </div>
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </CardHeader>
                  <CardBody>
                    {/* Make a limit of 4 tags and then +4 more */}
                    <div className='flex gap-4'>
                      {item && item.payload.user_tags && item.payload.user_tags.length > 0 && item.payload.user_tags.map((data) => (
                        <Chip
                          key={data}
                          size='sm'
                          color={filteredData && filteredData.color}
                          variant="flat">
                          {data}
                        </Chip>))}
                    </div>
                    <div className='mt-8 mb-4'>
                      <p>{item && item.payload && item.payload.description && StringLimit(item.payload.description, 190)}</p>
                    </div>
                  </CardBody>
                </Card>
              </div>
            })}
          </div>
          : <div className="flex flex-row items-center w-full justify-center  gap-4 pt-24 text-slate-400">
            <lord-icon
              src="https://cdn.lordicon.com/wpyrrmcq.json"
              trigger="hover"
              target="#Archive"
              state="morph-home-2"
              colors={`primary:#94a3b8`}
              style={{ width: "22px", height: "22px" }}>
            </lord-icon>
            No documents are inside your trash.
          </div>}
      </div>
      <ModalPopup
        size="xl"
        isOpen={isTrashOpen}
        onOpenChange={onOpenTrashChange}
        ModalBodyData={ModalBodyData}
        footer={footerCreate}
      />
    </div>
  )
}

export default Trash