import React from 'react';
import { TableRow, TableCell, Code } from "@nextui-org/react";
import TableComponent from "../../NextUI/Table";
import { formatDate } from '../../controllers/basicControllers';

const LogsTable = ({ logs }) => {

    const headerComponent = ["Time", "Path", "Weight"];


    const bodyComponent = () => {
        return logs?.logs?.map((item, index) => (
            <TableRow className='cursor-pointer'>
                <TableCell>{formatDate(item?.time)}</TableCell>
                <TableCell><Code>{item?.path}</Code></TableCell>
                <TableCell>{item?.weight}</TableCell>
            </TableRow>
        ));
    }

    return (
        <div>
            <div className='pt-8'>
                <TableComponent headerComponent={headerComponent} bodyComponent={bodyComponent} emptyContent={"No rows created yet."} />
            </div>
        </div>
    )
}

export default LogsTable