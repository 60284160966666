import CryptoJS from "crypto-js";
import { CommonCollectionFilterApi, UpdateData } from "./strapiController";

export const NavigateSign = async (email, password2, path) => {
  

    const response = await fetch(`${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/signin`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password2, path }),
    });

    const data = await response.json();
    const spaceName=path?.replaceAll("/","")
    const url=`filters[Name][$eq]=${spaceName}`;
    const spacedata=await CommonCollectionFilterApi("spaces",url);
    const userId=data?.userData?.user?.id;
    // Store the user data in session storage
    if (data.userData) {
        const relation={
            members: { disconnect: [], connect: [{ id: parseInt(userId), position: { end: true } }] },
        }
        const spaceId=spacedata?.data?.[0]?.id;
        
        const response=spacedata?.data?.length>0 && await UpdateData("spaces",parseInt(spaceId),relation);
       
        sessionStorage.setItem("userData", JSON.stringify(data.userData));
    }

    return data.navigate_route;
};
