import React from 'react';
import { Tabs, Tab, Button, Tooltip, Card } from "@nextui-org/react";
import { toast } from "react-hot-toast";
import CustomToast from '../../../components/CustomToast';
import { iconRender } from '../../../controllers/basicControllers';

const WebSocketComponent = () => {
    const [selected, setSelected] = React.useState("websocket");
    
    const wsCommand = `
wss://core.haya.cloud/agent/stream`;
    
    const wsMessage = `{
    "query": "Design a detailed marketing plan for launching our new seasonal boba tea flavors at the terminal."
}`;

    const copyWSCommand = () => {
        navigator.clipboard.writeText(`${wsCommand}\n${wsMessage}`).then(() => {
            toast.custom((t) => (
                <div className={`${t.visible ? 'animate-enter' : 'animate-leave'} max-w-xs w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}>
                    <div className="p-2">
                        <p className="mt-1 text-sm text-gray-500">
                            WebSocket command copied to clipboard!
                        </p>
                    </div>
                </div>
            ));
        }).catch(() => {
            CustomToast("Oops! Something went wrong, unable to copy code.", "error");
        });
    };

    return (
        <Card className='text-background bg-black border border-divider' shadow='none'>
            <div className='flex justify-end relative'>
                <Tooltip content="Copy" delay={0} closeDelay={0}>
                    <Button isIconOnly color="warning" variant="none" aria-label="" onClick={copyWSCommand}>
                        {iconRender("https://cdn.lordicon.com/vdjwmfqs.json", "Copy")}
                    </Button>
                </Tooltip>
            </div>
            <Tabs
                aria-label="Options"
                className='absolute text-foreground'
                selectedKey={selected}
                variant="underlined"
                onSelectionChange={setSelected}
                color="white"
            >
                <Tab id="tabsData" key="websocket" title="WebSocket" className='px-6 text-default overflow-x-auto'>
                    <pre className="code-container">
                        <code>
                            <span className="text-success">wss://core.haya.cloud/agent/stream</span><br /><br />
                            <span className="text-default">{wsMessage}</span>
                        </code>
                    </pre>
                </Tab>
            </Tabs>
        </Card>
    );
};

export default WebSocketComponent;