import React, { useEffect, useMemo, useState } from "react";
import OAuthButton from "../../components/OAuthButton";
import { Button, Input } from "@nextui-org/react";
import { useSelector } from "react-redux";
import { spaceData } from "../../redux/actions";
import ButtonSpinner from "../../components/Loader/ButtonSpinner";
import { convertKeysToLowercase } from "../../controllers/basicControllers";
import {
  patchAnyCollection,
  view_schema,
} from "../../controllers/vectordbController";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";

const Config = ({
  selectedProduct,
  setFormData,
  handlePrev,
  handleNext,
  formData,
  setSchema,
  option = "create",
  id,
}) => {
  const [conf, setConf] = useState({});
  const [errors, setErrors] = useState({});
  const spaceInfo = useSelector(spaceData);
  const { _spaceid } = useParams();
  const [buttonLoad, setButtonLoad] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setConf((prev) => ({
      ...prev,
      [name]: value,
    }));
    // Clear error on input change
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };
  const validateField = (field, value) => {
    let error = "";
    if (!value) {
      error = `${field.name} is required`;
    }
    return error;
  };

  const validation = () => {
    let isValid = true;
    const newErrors = {};

    selectedProduct.attributes.Schema?.properties
      ?.filter((a) => a.type === "input")
      ?.forEach((field) => {
        const error = validateField(field, conf[field.name]);
        if (error) {
          newErrors[field.name] = error;
          isValid = false;
        }
      });
    setErrors(newErrors);

    return isValid;
  };
  const handleUpdate = async () => {
    if (!validation()) {
      return;
    }
    try {
      await patchAnyCollection({ config: conf }, id, _spaceid, "data-products");
    } catch (e) {
      console.log(e);
    }
  };
  const handleSubmit = async () => {
    if (!validation()) {
      return;
    }
    if (
      selectedProduct?.attributes?.Name === "One Drive" &&
      !formData?.driveInfo
    ) {
      return;
    }
    setButtonLoad(true);
    localStorage.removeItem("one_drive");
    if (selectedProduct.attributes.Type === "connectors") {
      const ConnectionPayload = {
        source: selectedProduct?.attributes.Name,
        ...conf,
      };
      const ConnectionPayloadLowerCase =
        convertKeysToLowercase(ConnectionPayload);
      try {
        const response = await view_schema(ConnectionPayloadLowerCase);
        if (response?.tables) {
          setSchema(response);
          setButtonLoad(false);
          setFormData((prev) => ({
            ...prev,
            config: conf,
          }));
          handleNext();
        } else {
          setButtonLoad(false);
          toast.error("Incorrect database credentials.");
        }
      } catch (e) {
        console.log(e);
        setButtonLoad(false);
        toast.error("Incorrect database credentials.");
      }
    } else {
      setFormData((prev) => ({ ...prev, config: conf }));
    }
  };

  const formCreater = () => {
    return (
      <div className="flex flex-col gap-8 pt-8">
        <Toaster
          position="bottom-right"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            style: {
              boxShadow: "none",
              border: "1px solid #bdb5b5",
            },
            error: { duration: 6000 },
          }}
        />
        {selectedProduct.attributes.Schema?.properties?.map((data) =>
          data.type === "button" ? (
            <OAuthButton
              setFormData={setFormData}
              data={data}
              database={selectedProduct.attributes.Name}
            />
          ) : (
            <Input
              isRequired
              key={data.name} // Ensure unique key for each input
              type="text"
              htmlFor={data.name}
              name={data.name}
              value={conf?.[data.name] || ""}
              onChange={handleInputChange}
              label={data.name}
              labelPlacement="outside"
              placeholder={`Enter a ${data.name}`}
              errorMessage={errors?.[data.name]} // Show error message
              isInvalid={!!errors?.[data.name]}
            />
          )
        )}
      </div>
    );
  };
  useEffect(() => {
    selectedProduct.attributes.Schema?.properties
      ?.filter((a) => a.type === "input")
      .forEach((data) => {
        setConf((prev) => ({
          ...prev,
          [data?.name]: formData?.config?.[data?.name] ?? "",
        }));
      });
  }, [selectedProduct]);
  return (
    <div>
      {option === "create" && (
        <div className="pb-8">
          <h1 className="text-3xl font-medium pb-4">
            {selectedProduct?.attributes?.Type === "tools"
              ? `Integrate ${selectedProduct?.attributes?.Name} to ${spaceInfo?.[0]?.attributes?.SpaceName}`
              : "Connect to your data source"}
          </h1>
          <p>Connect to your source by providing your credentials</p>
        </div>
      )}
      {formCreater()}
      <div className="pt-8 pr-2 flex flex-row gap-4 justify-end">
        <Button variant="light" onClick={handlePrev}>
          Back
        </Button>
        <Button
          isLoading={buttonLoad}
          color="danger"
          spinner={buttonLoad ? ButtonSpinner() : ""}
          onClick={option === "create" ? handleSubmit : handleUpdate}
        >
          {option === "create" ? "Next" : "Update"}
        </Button>
      </div>
    </div>
  );
};

export default Config;
