import React, { useState } from 'react';
import { NextUIProvider, Card, CardBody, Select, SelectItem } from '@nextui-org/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UpdateOneUserData } from '../controllers/strapiController';
import Loading from "../components/Loader/Loading"

const Theme = ({ UserDetails, formData, setFormData, UserId, setResponse }) => {
    const [loader, setLoader] = useState(false);

    const HandleThemeChange = async (e) => {
        setLoader(true);
        setFormData({ ...formData, Theme: e.anchorKey === "undefined" ? UserDetails.Theme : e.anchorKey });

        let payload = {
            Theme: e.anchorKey
        }
        const response = await UpdateOneUserData("users", UserId, payload);
        // if (response) {
        //     if (response.Theme === "Dark") {
        //         setLoader(true);

        //         if (darkMode.value === false) {
        //             setResponse(response);
        //             window.location.reload();
        //         }
        //     } else if (response.Theme === "Light") {
        //         setLoader(true);
        //         darkMode.disable();
        //         if (darkMode.value === true) {
        //             setResponse(response);
        //             window.location.reload();
        //         }
        //     }
        // }
    }

    if (loader) {
        return <div className={`absolute z-[100]  inset-0 h-screen w-screen bg-white`}>
            <Loading />
        </div>
    }

    return (
        <NextUIProvider>
            {/* <Card className="shadow-none border border-divider rounded-2xl p-4">
                <CardBody>
                    <p className='text-lg font-normal'>Change your theme</p>
                    <p className='text-sm pt-4'>Choose how haya looks to you. Select a single theme, or sync with your system to automatically switch between day and night themes.</p>
                    {UserDetails && UserDetails.Theme && <Select
                        placeholder="Choose theme"
                        className='mt-8'
                        classNames={{
                            base: "max-w-44"
                        }}
                        defaultSelectedKeys={[formData && formData.Theme]}
                        onSelectionChange={(e) => HandleThemeChange(e)}
                    >
                        <SelectItem key="Dark" value="Dark" startContent={<FontAwesomeIcon icon="fa-solid fa-sun" />}>Dark</SelectItem>
                        <SelectItem key="Light" value="Light" startContent={<FontAwesomeIcon icon="fa-solid fa-sun" />}>Light</SelectItem>
                    </Select>}
                </CardBody>
            </Card> */}
        </NextUIProvider>
    )
}

export default Theme