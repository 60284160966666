import { Button, Textarea } from "@nextui-org/react";
import React, { useState } from "react";

const Redaction = ({ setFormData, handleNext, handlePrev,formData }) => {
  const [input, setInput] = useState( formData?.redaction ?? "");
  const handleSubmit = () => {
    setFormData((prev) => ({...prev, redaction : input}));
    handleNext();
  };
  return (
    <div>
      <div className="pb-8">
        <h1 className="text-3xl font-medium pb-4 gap-4 flex items-center">
          Redact Conditions
        </h1>
        <p>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quaerat
          similique nostrum dignissimos ratione accusantium nulla reprehenderit
          eligendi ad, amet nam.
        </p>
      </div>
      <Textarea
        variant=""
        label=" "
        labelPlacement="outside"
        placeholder="Enter your redact condition"
        description="Enter a concise redact conditions of your project."
        className="max-w-full"
        onChange={(e) => setInput(e.target.value)}
        value={input}
      />
      <div className="pt-8 pr-2 flex flex-row gap-4 justify-end">
        <Button variant="light" onClick={handlePrev}>
          Back
        </Button>
        <Button
            variant="flat"
          color="danger"
          onClick={handleSubmit}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default Redaction;
