import { Button } from "@nextui-org/react";
import React, { useState } from "react";
import TableModal from "./TableModal";
import ViewChart from "../Charts/ViewChart";
import { convertKeysToLowercase } from "../../controllers/basicControllers";

const ViewAnomaly = ({ sqlData, resp, config , timeSqlResult}) => {
  const [isOpen, setIsOpen] = useState(false);
  const timeConfig = resp.payload["time-config"];
  const sql = resp.payload["time-sql"];
  const dbConfig = {
    ...convertKeysToLowercase(config.config),
    dbType: config.data_product.toLowerCase(),
  };
  return (  
    <div className="flex flex-col px-8 ">
      {sqlData?.length > 0 && (
        <TableModal setIsOpen={setIsOpen} isOpen={isOpen} sqlData={sqlData} />
      )}
      <div className="flex justify-end">
        <Button size="md" color="primary" onClick={() => setIsOpen(true)}>
          Show data
        </Button>
      </div>
      <div className="flex items-center justify-center w-full">
        <ViewChart
          chartHeight="300px"
          showAdditionalDetails={false}
          showCard={false}
          cardClasses="w-full px-4"
          sql={sql}
          chartType="time series chart"
          chartInfo={timeConfig}
          data={{ query_result: timeSqlResult }}
          dbConfig={dbConfig}
        />
      </div>
    </div>
  );
};

export default ViewAnomaly;
