import {
  Button,
  Divider,
  useDisclosure,
  ModalHeader,
  ModalBody,
  Input,
  Textarea,
  Switch,
  Tooltip,
} from "@nextui-org/react";
import React, { useEffect, useMemo, useState } from "react";
import ModalPopup from "./ModalPopup";
import {
  CreateData,
  DeleteSingleAttribute,
  getAllData,
  UpdateData,
  CommonCollectionFilterApi,
} from "../../controllers/strapiController";
import { useNavigate } from "react-router-dom";
import UpdateCollection from "./UpdateCollection";
import DeleteModal from "../../components/DeleteModal";
import Cards from "../../components/Cards";
import UnAuthorized from "../../components/UnAuthorized";
import Forbbiden from "../../components/Forbbiden";
import NotFound from "../../components/NotFound";
import ServerError from "../../components/ServerIssue";
import { useParams } from "react-router-dom";
import { cardSkeleton } from "../../components/Skeleton";
import Tags from "../../components/Tags";
import { colorPicker } from "../../controllers/basicControllers";
import { toast } from "react-hot-toast";
import CustomToast from "../../components/CustomToast";
import {
  delete_document,
  getDataCollections,
  getSingleConnection,
} from "../../controllers/vectordbController";
import ProductCards from "../../components/ProductCards";

const Collections = () => {
  // STATE INITIALIZATION
  const [dataProd, setDataProd] = useState([]);
  const [collections, setCollections] = useState([]);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const userDetails = JSON.parse(sessionStorage.getItem("userData"));
  const userID = userDetails?.user?.id;
  const params = useParams();

  // API RENDERING

  useEffect(() => {
    const fetchProducts = async () => {
      setLoader(true);
      try {
        const resp2 = await getAllData("data-products");
        const resp = await getDataCollections(params._spaceid);
        setCollections(resp);
        setDataProd(resp2.data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoader(false);
      }
    };
    fetchProducts();
    // COLLECTIONS API
  }, [params._spaceid]);
  const filteredCollections = useMemo(() => {
    let owned = [],
      shared = [],
      publicCol = [];
    collections.forEach((a) => {
      if (String(a.payload.user_id) === String(userID)) {
        owned.push(a);
      } else if (
        a.payload?.members &&
        a.payload.members.includes(String(userID))
      ) {
        shared.push(a);
      } else if (a.payload?.public) {
        publicCol.push(a);
      }
    });
    return { owned, publicCol, shared };
  }, [collections]);
  // RETURN UI
  console.log("coll", filteredCollections);
  return (
    <div className="p-8">
      <div className="text-3xl font-medium">Collections</div>
      <div className="mt-4 leading-relaxed">
        Integrate unstructured data into your data mesh to unlock valuable
        insights. Connect, organize, and analyze data from various unstructured
        sources, such as documents and emails, enhancing your data ecosystem
        with comprehensive, actionable information.
      </div>
      <div>
        <div className="flex justify-end mt-4">
          <Button
            color="secondary"
            onClick={() =>
              navigate(
                `/${params._spaceid}/data-products/create?product=collections`
              )
            }
          >
            + Create a new Collection
          </Button>
        </div>
        <div className="text-xl font-medium items-center pt-8 flex justify-between">
          My Collections
        </div>
        <Divider className="mt-2" />
        {loader ? (
          cardSkeleton()
        ) : (
          <>
            {filteredCollections.owned.length > 0 ? (
              <div className=" flex items-center gap-8 flex-wrap py-8 ">
                {filteredCollections.owned.map((a) => (
                  <ProductCards product={a} data_prod={dataProd} />
                ))}
              </div>
            ) : (
              <div className="italic font-medium pt-8 flex text-sm justify-center text-coolGray-400">
                {" "}
                You haven't created any collection yet.
              </div>
            )}
          </>
        )}
      </div>
      <div>
        <div className="text-xl font-medium pt-8"> Public Collections</div>
        <Divider className="mt-2" />
        {loader ? (
          cardSkeleton()
        ) : (
          <>
            {filteredCollections.publicCol.length > 0 ? (
              <div className=" flex items-center gap-8 flex-wrap py-8 ">
                {filteredCollections.publicCol.map((a) => (
                  <ProductCards product={a} data_prod={dataProd} />
                ))}
              </div>
            ) : (
              <div className="italic font-medium pt-8 flex text-sm justify-center text-coolGray-400">
                {" "}
                You don't have any public collections.
              </div>
            )}
          </>
        )}
      </div>
      <div>
        <div className="text-xl font-medium pt-8"> Shared Collections</div>
        <Divider className="mt-2" />
        {loader ? (
          cardSkeleton()
        ) : (
          <>
            {filteredCollections.shared.length > 0 ? (
              <div className=" flex items-center gap-8 flex-wrap py-8 ">
                {filteredCollections.shared.map((a) => (
                  <ProductCards product={a} data_prod={dataProd} />
                ))}
              </div>
            ) : (
              <div className="italic font-medium pt-8 flex text-sm justify-center text-coolGray-400">
                {" "}
                You currently do not have any collections that are shared to you
                buy other members of the space.
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Collections;
