import { Tooltip } from "@nextui-org/react";

export const InfoIcon = ({ message, placement, size }) => {
    return <Tooltip
        size={size}
        placement={placement}
        content={message}
        delay={0}
        closeDelay={0}
        classNames={{
            base: [
                "before:bg-neutral-400 dark:before:bg-white",
            ],
            content: [
                "w-44",
                "p-3"
            ],
        }}
    >
        <lord-icon
            src="https://cdn.lordicon.com/yxczfiyc.json"

            style={{ width: "14px", height: "14px" }}>
        </lord-icon>
    </Tooltip>

}