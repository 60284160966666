import React, { useState, useEffect } from 'react';
import { CommonCollectionFilterApi, UpdateData, getOneData, userLoginDetails, InviteUser } from "../../controllers/strapiController";
import SearchIcon from '../../Images/Search';
import { useParams } from 'react-router-dom';
import { ModalHeader, Input, ModalBody, Avatar, Button, useDisclosure, Tooltip, Chip } from "@nextui-org/react";
import ModalPopup from "../Collections/ModalPopup";
import DeleteModal from "../../components/DeleteModal";
import CryptoJS from "crypto-js";
import { colorPicker } from '../../controllers/basicControllers';
import EmptyArray from '../../components/EmptyArray';
import { toast } from "react-hot-toast";

const TeamMembers = ({ isPeopleOpen, onPeopleOpenChange, teamsData, id, setreload, setTextValue, textValue }) => {
  const [searchResults, setSearchResults] = useState(null);
  const [EmailValidate, setEmailValidate] = useState('');
  const [AllUsers, setAllUsers] = useState(null);
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState('');
  const [SelectedId, setSelectedId] = useState('');
  const [Response, setResponse] = useState('');
  const [Loader, setLoader] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [Collection, setCollection] = useState(null);
  const [Forbidden, setForbidden] = useState(false);
  const [serverIssue, setServerIssue] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [ButtonLoader, setButtonLoader] = useState(false);
  const CollectionName = "collections";
  const params = useParams();
  const spaceName = params._spaceid
  const userID = userLoginDetails();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onOpenChange: onDeleteOpenChange } = useDisclosure();

  const [formData, setFormdata] = useState({
    Name: '',
    Description: ''
  });

  const invitedUsers = [];
  const remainingUsers = [];

  useEffect(() => {
    getOneData(CollectionName, params._id)
      .then((data) => {
        setLoader(false);
        if (data.error) {
          if (data.error.status !== 404 && data.error.status !== 403) {
            setServerIssue(true)
          }
          if (data.error.status === 404) {
            setNotFound(true)
          }
          if (data.error.status === 403) {
            setForbidden(true);
          }
        } else {
          setCollection(data.data)
          setTimeout(() => { setButtonLoader(false) }, 200)
        }
      })
      .catch((error) => toast.error("Oops!, something went wrong, please try after some time."))


    const url2 = `filters[$or][0][spaces][Name][$in]=${spaceName}&filters[$or][1][space][Name][$in]=${spaceName}&[populate][Picture][on]&[populate][spaces][on]&[populate][space][on]`
    CommonCollectionFilterApi("users", url2)
      .then((data) => setAllUsers(data))
      .catch((error) => toast.error("Oops!, something went wrong, please try after some time."))

  }, [Response])

  const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  useEffect(() => {

    if (textValue !== '' && searchResults.length === 0) {
      if (!EmailRegex.test(textValue)) {
        setEmailValidate("Please enter a valid email.")
      } else {
        setEmailValidate(null)
      }
    }
  }, [textValue])


  const userFilter = AllUsers && AllUsers.filter((data) => data.id !== userID.userId);
  const HandleChange = (event) => {
    const TextValue = event.target.value;
    setTextValue(TextValue);
    const Filterdata = userFilter && userFilter.filter((data) => data && data.email.toLowerCase().includes(TextValue.toLowerCase()));
    setSearchResults(Filterdata);

  }

  const InvitedUsers = teamsData.attributes.members.data;
  const ViewdUsers = teamsData?.attributes?.members?.data;
  const MergeUsers = InvitedUsers && InvitedUsers.concat(ViewdUsers);

  const handleKeyPress = (e) => {
    if (tagInput.trim() !== '' && e.key === "Enter") {
      if (!tags.includes(Text)) {
        setTags((previous) => [...previous, Text]);
        setFormdata((previous) => ({ ...previous, Tags: [...tags, Text] }));
        setTagInput('');
      } else {
      }
    }
  }

  useEffect(() => {
    textValue === '' && setSearchResults(userFilter)
  }, [AllUsers, textValue])

  const InvitedHandler = async (id) => {

    setSelectedId(id)
    setButtonLoader(true)
    const payload2 = {
      members: {
        disconnect: [],
        connect: [{
          id: id,
          position: {
            end: true
          }
        }]
      },
    }

    const response = await UpdateData("teams", params._id, payload2);
    if (response) {
      setResponse(response)
      setreload(response)
      setButtonLoader(false)
    }
  }

  const DeleteWarningHandle = (data) => {
    setDeleteData(data);
    onDeleteOpen();
  }

  const InviteToSpaceHandler = async () => {
    const secretKey = `${process.env.REACT_APP_ENCRYPT_SECRET_KEY}`;
    const inputString = textValue;
    const encrypted = CryptoJS.AES.encrypt(inputString, secretKey);
    const AESHash = encrypted.toString();
    const url = `${process.env.REACT_APP_IP_ADDRESS}/${spaceName}/?${btoa(AESHash)}`
    const payload = {
      "to": textValue,
      "subject": "Invitation from Haya.",
      "html": `<html><body><a href=${url}>${url}</a></body></html>`
    };
    InviteUser(payload);
    setTextValue('');
  }


  const DeleteHandler = async (onClose) => {
    setLoader(true)

    const payload = {
      members: {
        disconnect: [{
          id: deleteData,
        }],
        connect: []
      },
    }
    const response = await UpdateData("teams", params._id, payload);
    if (response) {
      setResponse(response)
      setreload(response)
      onClose()
    }
  }

  searchResults && searchResults.forEach((data) => {
    const InvitedTest = MergeUsers && MergeUsers.find((item) => item.id === data.id);


    if (InvitedTest) {
      invitedUsers.push(
        <div key={data.id} className='flex flex-row items-center justify-between py-2'>
          <div className='flex flex-row items-center gap-4  w-full'>
            <Avatar src={`${data?.Picture?.data !== null ? `${process.env.REACT_APP_STAGE === 'dev' ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${data?.Picture?.url}` : `${data?.Picture?.url}`}` : ""}`}
              color={colorPicker(data.id)} name={data && data.email && data.email.slice(0, 1).toUpperCase()} size="sm" />
            <div className='flex flex-col'>
              <p className=''>{data.email && data.email.split('@')[0]}</p>
              <p className='text-slate-400'>{data.username}</p>
            </div>
          </div>
          <div className='w-full  text-center'>
            <Chip size='sm' color="success" variant='flat'>Member</Chip>
          </div>
          <div className='w-full text-end flex justify-end gap-2'>
            <Tooltip placement='bottom' size='sm' content='Remove user from collection' delay={1000}>
              <lord-icon
                src="https://cdn.lordicon.com/wpyrrmcq.json"
                colors="primary:#e1426c"
                trigger="hover"
                onClick={() => DeleteWarningHandle(data.id)}
                style={{ width: "20px", height: "20px" }}>
              </lord-icon>
            </Tooltip>
          </div>
        </div>
      );
    } else {
      remainingUsers.push(
        <div key={data.id} className='flex flex-row items-center justify-between py-2'>
          <div className='flex flex-row items-center gap-4'>
            <Avatar src={`${data?.Picture?.data !== null ? `${process.env.REACT_APP_STAGE === 'dev' ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${data?.Picture?.url}` : `${data?.Picture?.url}`}` : ""}`}
              color={colorPicker(data.id)} name={data && data.email && data.email.slice(0, 1).toUpperCase()} size="sm" />
            <div className='flex flex-col '>
              <p className=''>{data.email && data.email.split('@')[0]}</p>
              <p className='text-slate-400'>{data.username}</p>
            </div>
          </div>
          <div>
            <Button onClick={() => InvitedHandler(data.id)} isLoading={SelectedId === data.id ? ButtonLoader : false} size='sm' radius='lg' color="danger">
              Invite
            </Button>
          </div>
        </div>
      );
    }
  });

  const UserModal = () => {
    return <>
      <ModalHeader className="flex flex-col  gap-1">
        <p>Manage users</p>
        <div className='pt-2'>
          <Input
            classNames=""
            onChange={HandleChange}
            value={textValue}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                handleKeyPress(event);
              }
            }}
            placeholder='Search by user content...'
            startContent={<SearchIcon className="text-large text-default-400 pointer-events-none flex-shrink-0" />}
          />
        </div>
      </ModalHeader>
      <ModalBody className='gap-0'>
        <div>
          <div className='divide-y divide-slate-300'>
            {searchResults && searchResults.length > 0 ? <>
              {invitedUsers}
              {remainingUsers}
            </>
              : <>{textValue !== "" ? <>{EmailValidate !== null ?
                <p className='text-red-500'>{EmailValidate}</p> :
                <div className='flex flex-row justify-between items-center'>
                  <div className='flex flex-row items-center gap-4'>
                    <Avatar
                      color={colorPicker(10)} name={textValue.slice(0, 1).toUpperCase()} size="sm" />
                    <div className='flex flex-col '>
                      <p className=''>{textValue}</p>
                    </div>
                  </div>
                  <div>
                    <p className='text-xs text-slate-400'>This email does not exists in the space.</p>
                  </div>
                  <div>
                    <Button variant='flat' color='success' onClick={InviteToSpaceHandler}>Invite to space</Button>
                  </div>
                </div>}</> : <EmptyArray message={"No members added."} />}</>}
          </div>
        </div>
      </ModalBody>
    </>
  }

  const UserFooterModal = () => {
    return <></>
  }
  return (
    <>
      <ModalPopup
        isOpen={isPeopleOpen}
        size="5xl"
        onOpenChange={onPeopleOpenChange}
        ModalBodyData={UserModal}
        footer={UserFooterModal}
        scrollBehavior="inside"
      />
      <DeleteModal
        isOpen={isDeleteOpen}
        onOpenChange={onDeleteOpenChange}
        deleteData={deleteData}
        setDeleteData={setDeleteData}
        Collectionname={"Member"}
        DeleteHandler={DeleteHandler}
        setLoader={setLoader} />
    </>
  )
}

export default TeamMembers