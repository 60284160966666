export class PlaygroundManager {
    constructor(setMessages) {
      this.ws = new WebSocket(`${process.env.REACT_APP_HAYA_WS_ADDRESS}/prompt/ws/execute`);
      this.init(setMessages);
    }
  
    static getInstance(setMessages) {
      if (!this.instance) {
        this.instance = new PlaygroundManager(setMessages);
      }
      return this.instance;
    }
  
    init(setMessages) {
      this.ws.onopen = () => {
        console.log("WebSocket connection established.");
      };
  
      this.ws.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message?.chunk_number === 1) {
          setMessages((prev) => [
            ...prev,
            {
              timestamp: Date.now(),
              sender: "agent",
              id: message.message_id,
              message: message.chunk,
            },
          ]);
        } else if (message?.chunk) {
          setMessages((prev) =>
            prev.map((it) =>
              it?.id === message.message_id ? { ...it, message: it.message + message.chunk } : it
            )
          );
        }
      };
  
      this.ws.onclose = () => {
        console.log("WebSocket connection closed. Attempting to reconnect...");
        this.reconnect(setMessages);
      };
    }
  
    reconnect(setMessages) {
      this.ws = new WebSocket(`${process.env.REACT_APP_HAYA_WS_ADDRESS}/prompt/ws/execute`);
      this.init(setMessages);
    }
  
    sendMessage(prompt, setMessages) {
      // Check if WebSocket is open before sending the message
      if (this.ws.readyState === WebSocket.CONNECTING) {
        console.log("WebSocket is still connecting. Message will be queued.");
        this.ws.onopen = () => this.sendMessage(prompt, setMessages);
        return;
      } else if (this.ws.readyState !== WebSocket.OPEN) {
        console.log("WebSocket is not open. Reconnecting...");
        this.reconnect(setMessages);
        this.ws.onopen = () => this.sendMessage(prompt, setMessages);
        return;
      }
  
      if (!(prompt && prompt.prompt && prompt.prompt !== "")) {
        return;
      }
  
      const query = prompt?.variables?.find((it) => it.variable === "query");
      if (!query || !query.value || query.value === "") {
        return;
      }
  
      setMessages((prev) => [
        ...prev,
        { timestamp: Date.now(), sender: "user", message: query.value },
      ]);
  
      this.ws.send(JSON.stringify(prompt));
    }
  }
  