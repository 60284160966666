import HayaTableRender from "../../Haya/HayaTableRender";
import "./response.css";
import { iconRender } from "../../../controllers/basicControllers";
import toast from "react-hot-toast";

export const renderResponseWithCodeBlocks = (response, responseTable) => {
  const parts = response.split(/(```[\w-]*\n[\s\S]*?```)/g); // Split by code blocks

  function append_table(props) {
    responseTable.push(props);
  }

  const renderBoldText = (text) => {
    const boldRegex = /\*\*(.*?)\*\*/g;
    return text.split(boldRegex).map((part, index) => {
      return index % 2 === 0 ? (
        <span key={index}>{part}</span>
      ) : (
        <strong key={index}>{part}</strong>
      );
    });
  };

  return parts.map((part, index) => {
    if (part.startsWith("```")) {
      // Extract language (optional)
      const match = part.match(/^```(\w+)?\n([\s\S]*?)```$/);
      const language = match ? match[1] || "plaintext" : "plaintext";
      const codeContent = match ? match[2] : part.slice(3, -3).trim();

      const copyToClipboard = () => {
        navigator.clipboard.writeText(codeContent);
        toast("Copied to clipboard!")
      };

      return (
        <div key={index} className="custom-code-block">
          {/* Top Bar */}
          <div className="top-bar">
            <span>{language}</span>
            <button className="copy-btn" onClick={copyToClipboard}>
              {iconRender("https://cdn.lordicon.com/vdjwmfqs.json", "Copy")}
            </button>
            
          </div>
          {/* Code Block */}
          <pre>
            <code>{codeContent}</code>
          </pre>
        </div>
      );
    } else {
      return (
        <div key={index}>
          {part.split("\n").map((line, lineIndex) => {
            if (line.trim().startsWith("|")) {
              const tablepart = line.trim();
              if (tablepart.includes("----")) {
                return (
                  <div className="max-h-100px overflow-y-auto" key={lineIndex}>
                    <HayaTableRender markdownTable={responseTable} />
                  </div>
                );
              } else {
                append_table(tablepart + "\n");
                return null;
              }
            } else if (line.trim().startsWith("-")) {
              return (
                <p key={lineIndex} className="leading-loose">
                  &bull; {renderBoldText(line.substring(1))}
                  <br />
                </p>
              );
            } else {
              return (
                <p key={lineIndex} className="leading-loose">
                  {renderBoldText(line)}
                  <br />
                </p>
              );
            }
          })}
        </div>
      );
    }
  });
};
