import React, { useState } from 'react';
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Textarea, Button } from "@nextui-org/react"
import { createDataproduct } from "../../controllers/dataProducts";
import ButtonSpinner from '../../components/Loader/ButtonSpinner';

const Redendent = ({ setFormData, formData, convertedData, userId, finalJson, setRenderPage }) => {
    const [buttonLoad, setButtonLoad] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async () => {
        const response = await createDataproduct(formData, convertedData, userId, finalJson);

        if (response?.status === "200") {
            setButtonLoad(false);
            toast.success("Data product created successfully.");
            navigate(`/${convertedData?.[0]?.attributes?.Name}/${response?.response?.data?.attributes?.Type}/${response?.response?.data?.id}`);
        } else {
            setButtonLoad(false);
            toast.error("Something went wrong. Try after some time.")
        }
    }

    const onChangeHandler = (e) => {
        const value = e.target.value;
        setFormData(prev => ({
            ...prev,
            inputFields: {
                ...prev.inputFields,
                redendent: value
            }
        }))
    }

    return (
        <div>
            <div className='pb-8'>
                <h1 className='text-3xl font-medium pb-4 gap-4 flex items-center'>
                    Redact Conditions
                </h1>
                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quaerat similique nostrum dignissimos ratione accusantium nulla reprehenderit eligendi ad, amet nam.</p>
            </div>
            <Textarea
                variant=""
                label=" "
                labelPlacement='outside'
                placeholder="Enter your redact condition"
                description="Enter a concise redact conditions of your project."
                className="max-w-full"
                onChange={onChangeHandler}
                value={formData?.inputFields?.redendent}
            />
            <div className="pt-8 pr-2 flex flex-row gap-4 justify-end">
                <Button variant="light" onClick={() => setRenderPage(false)}>Back</Button>
                <Button isLoading={buttonLoad} spinner={buttonLoad ? ButtonSpinner() : ""} color="danger" onClick={handleSubmit}>Create</Button>
            </div>
        </div>
    )
}

export default Redendent;