import React from 'react';
import { useSearchParams } from 'react-router-dom';
import ResetPassword from './ResetPassword';
import PasswordReset from './PasswordReset';
import NotFound from "../../components/NotFound";

const CombinedResetPassword = () => {
  const [searchParams] = useSearchParams();

  // Check if specific query parameters are present
  const email = searchParams.get('email');
  const limit = searchParams.get('limit');

  // Render different components based on the presence of query parameters
  if (email && limit) {
    return <PasswordReset />;
  } else if (!email && !limit) {
    return <ResetPassword />;
  } else {
    return <NotFound />
  }
};

export default CombinedResetPassword;
