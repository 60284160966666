import React, { useEffect, useState } from "react";
import { Button, Divider, Image, Input, Link } from "@nextui-org/react";
import Google from "../../Images/Google.jsx";
import { EyeSlashFilledIcon } from "../../Images/EyeSlashFilledIcon";
import { EyeFilledIcon } from "../../Images/EyeFilledIcon";
import { encryptedText, generateOtp, } from "../../controllers/basicControllers.js";
import { CommonCollectionFilterApi, sendCreateAccountOTP } from "../../controllers/strapiController.js";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import ButtonSpinner from "../../components/Loader/ButtonSpinner";
import { GoogleAuth } from "../../controllers/social.js";
import toast, { Toaster } from "react-hot-toast";

const initialState = { emailError: '', passwordError: '', FinalError: '' }

const SignUp = () => {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const path = searchParams?.get('path');
  const handleGoogleAuth = async (e) => {
    e.stopPropagation();
    const data = await GoogleAuth();
    window.location.href = data.urlWithQueryParams;
  };

  const handlePasswordlessAuth = async (e) => {
    e.stopPropagation();
    navigate("/passwordless-sign");
  };

  const [isVisible, setIsVisible] = React.useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [ValidationConditions, setValidationConditions] = useState(false);
  const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const PassWordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const Location = useLocation();
  const [LoginDetails, setLoginDetails] = useState({
    email: "",
    password: "",
  });
  const [LoginError, setLoginError] = useState(initialState);

  const commonProps = {
    size: "md",
    radius: "sm",
    variant: "faded",
    labelPlacement: "outside"
  }


  const navigate_forgot = () => {
    navigate("/auth/reset-password");
  };

  const Validation = () => {
    let IsValid = true;

    if (LoginDetails.email === "") {
      IsValid = false;
      setLoginError((previous) => ({
        ...previous,
        emailError: "Email is required.",
      }));
    } else if (!EmailRegex.test(LoginDetails.email)) {
      IsValid = false;
      setLoginError((previous) => ({
        ...previous,
        emailError: "Enter valid email format.",
      }));
    } else {
      setLoginError((previous) => ({ ...previous, emailError: "" }));
    }

    if (LoginDetails.password === "") {
      IsValid = false;
      setLoginError((previous) => ({
        ...previous,
        passwordError: "Password is required.",
      }));
    } else if (
      !PassWordRegex.test(LoginDetails.password) ||
      LoginDetails?.password?.trim()?.length < 8 ||
      LoginDetails?.password?.trim()?.length > 16
    ) {
      IsValid = false;
      setLoginError((previous) => ({
        ...previous,
        passwordError:
          "Password should contain lower-case, upper-case, digit, Special Character ,have minimum length 8 and maximum length 16.",
      }));
    } else {
      setLoginError((previous) => ({ ...previous, passwordError: null }));
    }

    return IsValid;
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      SubmitHandler();
    }
  };

  // USER API
  useEffect(() => {

    if (Location.pathname === "/sign-in") {
      sessionStorage.clear();
    }

    setLoginError(initialState)
  }, [LoginDetails]);

  const SubmitHandler = async () => {
    setButtonLoader(true);
    const Validate = Validation();

    if (Validate) {
      try {
        setValidationConditions(false);
        // USER API CALL
        const userEmail = LoginDetails.email.toLowerCase().trim();
        const url1 = `filters[email][$eq]=${userEmail}`;
        const data = await CommonCollectionFilterApi('users', url1);
        if (data?.length > 0) {
          setLoginError((previous) => ({ ...previous, emailError: "Email already exists, log in to your account." }));
          setButtonLoader(false);
        } else {
          const otpString = generateOtp();
          const limit = Date.now() + 5 * 60 * 1000;

          const encryptOtp = encryptedText(otpString);
          const encryptedEmail = encryptedText(LoginDetails.email?.trim());
          const encryptedPassword = encryptedText(LoginDetails.password?.trim());

          const encryptedLimit = encryptedText(limit.toString());
          const url = `${process.env.REACT_APP_IP_ADDRESS}/auth/verify-otp?email=${encryptedEmail}&limit=${encryptedLimit}&password=${encryptedPassword}`;

          await sendCreateAccountOTP({
            to: LoginDetails.email?.trim(),
            url,
            otp: otpString,
          });

          localStorage.setItem("user-otp", encryptOtp);
          toast.success("Email sent successfully, Check your email to verify the OTP!");

          if (path !== null) {
            navigate(`/auth/verify-otp/redirectTo?path=${path}&email=${encryptedEmail}&limit=${encryptedLimit}&password=${encryptedPassword}`);
          } else {
            navigate(`/auth/verify-otp?email=${encryptedEmail}&limit=${encryptedLimit}&password=${encryptedPassword}`);
          }
          setButtonLoader(false);
        }
      } catch (error) {

      }
    } else {
      setValidationConditions(true);
      setButtonLoader(false);
    }
  };



  const toggleVisibility = () => setIsVisible(!isVisible);

  return (
    <>
      <Toaster position="bottom-right" reverseOrder={true} />
      <div className={`flex h-screen items-center justify-center bg-white`}>
        <main className="mx-auto min-h-[590px] w-full max-w-[450px]">
          <Image
            width={50}
            alt="NextUI hero Image"
            src="Assets/haya-logo.png"
          />
          <h1 className="mb-1.5 mt-8 sm:text-left text-2xl tracking-[-0.16px] text-slate-12 font-bold ">
            Create an new Haya Account
          </h1>
          <p className="pb-8 sm:text-left text-base text-slate-11 font-normal"></p>
          <form data-gtm-form-interact-id="0" onKeyDown={handleKeyPress}>
            <div className="mb-4 space-y-2">
              <Input
                {...commonProps}
                className="border-slate-6"
                isInvalid={LoginError.emailError !== ""}
                errorMessage={LoginError.emailError}
                type="email"
                label="Email"
                onChange={(e) =>
                  setLoginDetails({ ...LoginDetails, email: e.target.value })
                }
                placeholder="Enter your email"
              />
            </div>
            <div className="mb-4 space-y-2">
              <Input
                {...commonProps}
                className="py-4"
                onChange={(e) =>
                  setLoginDetails({ ...LoginDetails, password: e.target.value })
                }
                isInvalid={
                  ValidationConditions && LoginError?.passwordError !== ""
                }
                errorMessage={LoginError?.passwordError}
                label={
                  <div
                    className="flex justify-between "
                    style={{ width: "442px" }}
                  >
                    <span>Password</span>
                    <span className="cursor-pointer" onClick={navigate_forgot}>
                      Forgot your password?
                    </span>
                  </div>
                }
                placeholder="Enter your password"
                endContent={
                  <button
                    className="focus:outline-none"
                    type="button"
                    onClick={toggleVisibility}
                  >
                    {isVisible ? (
                      <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                    ) : (
                      <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                    )}
                  </button>
                }
                type={isVisible ? "text" : "password"}
              />
            </div>
            <Button
              isLoading={buttonLoader}
              spinner={<ButtonSpinner />}
              id="CreateAccount"
              radius="sm"
              onClick={SubmitHandler}
              className={`w-full gap-1 lt-500 text-white bg-black hover:bg-gray-700 hover:text-white`}
            >
              Sign Up
              <lord-icon
                src="https://cdn.lordicon.com/vduvxizq.json"
                trigger="hover"
                target="#CreateAccount"
                colors="primary:#ffffff"
                style={{
                  width: "18px",
                  height: "20px",
                }}
              />
            </Button>
            <div className="text-sm flex items-center justify-end mt-4">
              <Link
                onClick={() => navigate("/sign-in")}
                className="cursor-pointer"
                size="sm"
              >
                Already have an account? Login here
              </Link>
            </div>
            <div className="mb-6 mt-6 flex items-center justify-center">
              <Divider className="my-2" style={{ maxWidth: "44%" }} />
              <span className="text-small text-default-400 px-4">OR</span>
              <Divider className="my-2" style={{ maxWidth: "44%" }} />
            </div>
          </form>

          <div className="flex w-full flex-row flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
            <Button
              radius="sm"
              variant={"faded"}
              className="flex gap-1"
              style={{ width: "48%" }}
              onClick={handlePasswordlessAuth}
            >
              <div className="flex items-center gap-2">
                <i class="fas fa-key"></i>
                Sign in Passwordless
              </div>
            </Button>
            <Button
              radius="sm"
              variant={"faded"}
              className="flex w-1/2 gap-1 bg"
              style={{ width: "48%" }}
              onClick={handleGoogleAuth}
            >
              <Google />
              Sign in with Google
            </Button>
          </div>
          <p className="text-small text-default-400 mt-8">
            By signing in, you agree to our{" "}
            <Link href="#" size="sm">
              Terms of Service{" "}
            </Link>{" "}
            and{" "}
            <Link href="#" size="sm">
              Privacy Policy{" "}
            </Link>
            .
          </p>
        </main>
      </div>
    </>
  );
};

export default SignUp;
