import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { spaceData } from "../redux/actions";
import { getFilteredBySingleRelation } from "../controllers/strapiController";
import { Avatar, Button, Chip, Input } from "@nextui-org/react";
import { Search } from "lucide-react";
import { colorPicker } from "../controllers/basicControllers";
import {
  patchAnyCollection,
  patchData,
} from "../controllers/vectordbController";
import toast from "react-hot-toast";

const Invite = ({ invitedIds, setInvitedIds, productId, adminId }) => {
  const [users, setUsers] = useState([]);
  const spaceInfo = useSelector(spaceData);
  const [search, setSearch] = useState("");
  const { _spaceid } = useParams();
  const fields = ["username", "email", "firstname", "lastname"];
  const populate = ["Picture"];
  const handleInvite = async (id) => {
    try {
      const resp = await patchAnyCollection(
        { members: [...invitedIds, String(id)] },
        productId,
        _spaceid,
        "data-products"
      );
      setInvitedIds((prev) => [...prev, String(id)]);
      toast("New Member added");
    } catch (e) {
      console.log(e);
    }
  };
  const filteredUsers = useMemo(() => {
    return users.filter(
      (a) =>
        `${a.firstname ?? ""} ${a.lastname ?? ""}`
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        a.username.toLowerCase().includes(search.toLowerCase())
    );
  }, [users, search]);
  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const resp = await getFilteredBySingleRelation(
          "users",
          "space",
          spaceInfo[0].id,
          fields,
          populate
        );
        const resp2 = await getFilteredBySingleRelation(
          "users",
          "spaces",
          spaceInfo[0].id,
          fields,
          populate
        );
        setUsers(
          resp.some((a) => a.id === resp2[0].id) ? resp : [...resp, ...resp2]
        );
      } catch (e) {
        console.log(e);
      }
    };
    if (spaceInfo?.[0].id) {
      fetchAllUsers();
    }
  }, [spaceInfo]);
  return (
    <div className="flex flex-col gap-4 pt-8">
      <div>Search and invite members</div>
      <Input
        placeholder="Search for users.."
        startContent={<Search className=" size-5 text-gray" />}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <div className="flex flex-col gap-4 py-4">
        {filteredUsers?.map((a) => (
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <Avatar
                color={colorPicker(a.id)}
                showFallback
                src={a?.Picture?.url}
                name={a.username.toUpperCase().charAt(0)}
              />
              <div>{a.username}</div>
            </div>
            {invitedIds.includes(String(a.id)) ||
            String(a.id) === String(adminId) ? (
              <Chip
                color={String(a.id) === String(adminId) ? "danger" : "warning"}
                variant="flat"
              >
                {String(a.id) === String(adminId) ? "Admin" : "Member"}
              </Chip>
            ) : (
              <Button
                onClick={() => handleInvite(a.id)}
                size="sm"
                color="danger"
                disabled={String(a.id) !== String(adminId)}
              >
                + Invite
              </Button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Invite;
