import React from 'react'
import { helix } from 'ldrs'

const Loading = () => {

  helix.register()

  return (
    <div className={`flex items-center justify-center h-full backdrop-opacity-10 backdrop-invert bg-white`}>
      <l-helix
        size="45"
        speed="2.5"
        color={"black"}
      ></l-helix>
    </div>
  )
}

export default Loading