import React, { useEffect, useState } from "react";
import {
  capitalizeString,
  getSQLQueryResult,
} from "../../controllers/basicControllers";
import {
  Bar,
  BarChart,
  Cell,
  Label,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { defaultChartConfigData } from "../../Utils/charts.utils";
import TableChart from "./TableChart";
import NullData from "../../components/NullData";
import { Chip } from "@nextui-org/react";

const getHeapData = (data, CustomMetric) => {
  console.log(data, CustomMetric);

  const total = data.length;
  const countavg = {};
  const groupbyValues = {};

  Object.keys(data?.[0]).forEach((key) => {
    groupbyValues[key] = new Set([]);
    countavg[key] = {};
  });

  data.forEach((row) => {
    Object.keys(row).forEach((key) => {
      if (key === CustomMetric) {
        return;
      }

      if (countavg[key]?.[row[key]]) {
        countavg[key][row[key]].count++;
        countavg[key][row[key]].avg += row[CustomMetric];
      } else {
        countavg[key][row[key]] = { count: 1, avg: row[CustomMetric] };
      }
      groupbyValues[key].add(row[key]);
    });
  });

  const heatMapData = [];

  for (const [key, set] of Object.entries(groupbyValues)) {
    for (const value of set) {
      heatMapData.push({
        groupBy: key,
        columnValue: value,
        value: (countavg[key][value]?.count / total) * 100,
        avg: Math.floor(
          countavg[key][value]?.avg / countavg[key][value]?.count
        ),
      });
    }
  }

  return heatMapData;
};

const formatDataForHeapChat = (data) => {
  const heatMapData = {};
  data.forEach((obj) => {
    if (!heatMapData[obj?.groupBy]) {
      heatMapData[obj?.groupBy] = {
        [obj?.columnValue]: {
          value: obj?.value,
          avg: obj?.avg,
        },
      };
    } else {
      heatMapData[obj?.groupBy][obj?.columnValue] = {
        value: obj?.value,
        avg: obj?.avg,
      };
    }
  });

  const finalheatMapData = [];

  Object.keys(heatMapData).forEach((key) => {
    finalheatMapData.push({
      groupBy: key,
      ...heatMapData[key],
    });
  });

  return finalheatMapData;
};

const CustomTooltip = ({ data, CustomMetric }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: -5,
        left: "40%",
        background: "rgba(0, 0, 0, 0.75)",
        color: "#fff",
        padding: "5px 10px",
        borderRadius: "4px",
        pointerEvents: "none",
        zIndex: 1000,
      }}
      className="flex flex-col gap-1 p-2"
    >
      <p>
        {capitalizeString(
          data?.groupBy?.split("_")[data?.groupBy?.split("_").length - 1]
        )}
        : {data?.barSection}
      </p>
      <p>
        {capitalizeString(
          CustomMetric?.split("_")[CustomMetric?.split("_").length - 1] || ""
        )}
        : {data?.avg}
      </p>
    </div>
  );
};
const breakpoints = ["rgb(66,135,245)", "#979899", "rgb(230,55,99)"];

const HeaderInfo = () => {
  return (
    <div className="flex items-center gap-2 justify-end mr-12">
      <div className="flex items-center gap-1">
        <div
          className={`w-4 h-4 rounded-sm`}
          style={{ backgroundColor: breakpoints[0] }}
        ></div>
        <span className="text-sm font-medium"> {`< lower limit`}</span>
      </div>
      <div className="flex items-center gap-1">
        <div
          className={`w-4 h-4 rounded-sm`}
          style={{ backgroundColor: breakpoints[1] }}
        ></div>
        <span className="text-sm font-medium">{`[lower, upper limit]`}</span>
      </div>
      <div className="flex items-center gap-1">
        <div
          className={`w-4 h-4 rounded-sm`}
          style={{ backgroundColor: breakpoints[2] }}
        ></div>
        <span className="text-sm font-medium">{`> upper limit`}</span>
      </div>
    </div>
  );
};

const getAllColumnName = (row, tableName) => {
  const result = Object.keys(row).map((key) => {
    return key.split(tableName + "_").pop() || "";
  });

  return result;
};
const HeatMap = ({
  chartInfo: chartConfigData,
  chartType,
  data: TableData,
  onAddCustomFilter = () => {},
  dbConfig,
  sql,
}) => {
  console.log(TableData);

  const [defaultData, setDefaultData] = useState(TableData);
  const [currentFilterList, setCurrentFilterList] = useState([]);

  const [newHeatMapData, setNewHeatMapData] = useState(TableData);
  const CustomMetric = chartConfigData?.xAxis || "t1_d23_aimx_metric";
  const lowerLimit = chartConfigData?.minThreshold || 10;
  const upperLimit = chartConfigData?.maxThreshold || 30;

  const [hoveredBar, setHoveredBar] = useState(null);
  const handleMouseEnter = (event, col, entry) => {
    setHoveredBar({
      x: event.x,
      y: event.y,
      groupBy: entry.groupBy,
      barSection: col,
      value: entry[col]?.value,
      avg: entry[col]?.avg,
    });
  };
  const handleMouseLeave = () => {
    setHoveredBar(null);
  };

  const addCustomFilter = async () => {
    const parts = hoveredBar?.groupBy.split("_");
    const selectedcolumn = parts.pop();
    const tableName = parts.join("_");
    const selectedValue = hoveredBar?.barSection;
    const data = {
      id: Date.now(),
      selectedFilterColumn: {
        table: tableName,
        column: selectedcolumn,
        type: "STRING",
      },
      filteredType: "is",
      filteredValue: [selectedValue],
      query: `${selectedcolumn} IN ('${selectedValue}')`,
      attachedTo: null,
      customChartFilter: {},
    };

    setCurrentFilterList((pre) => {
      return [...pre, data];
    });
  };

  const heapData = (getHeapData(newHeatMapData || [], CustomMetric) || []).sort(
    (a, b) => b.value - a.value
  );
  console.log(heapData);

  const data = formatDataForHeapChat(heapData);

  console.log(data);

  // Extract all possible columns dynamically
  const columns = [
    ...new Set(
      data.flatMap((d) => Object.keys(d).filter((key) => key !== "groupBy"))
    ),
  ];

  const getColor = (col) => {
    const value = col?.avg;
    let requiredColor = null;

    if (value <= lowerLimit) {
      const opacity = Math.max(0.2, (lowerLimit - value) / lowerLimit);
      requiredColor = `rgb(66,135,245,${opacity})`;
    } else if (value >= upperLimit) {
      const opacity = Math.max(0.2, (value - upperLimit) / upperLimit);
      requiredColor = `rgb(230,55,99,${opacity})`;
    } else {
      const opacity = Math.min(
        0.9,
        value - lowerLimit / lowerLimit,
        upperLimit - value / upperLimit
      );
      requiredColor = `rgb(245,245,245,${opacity})`;
    }
    return requiredColor ? requiredColor : "rgb(200, 200, 200)"; // Default color if no match
  };

  const removeFilter = (id) => {
    setCurrentFilterList(currentFilterList.filter((item) => item.id !== id));
  };

  function modifySQLQuery(originalQuery, newWhereConditions, newGroupBy) {
    const fromMatch = originalQuery.match(/FROM\s+([a-zA-Z0-9_]+)/i);
    if (!fromMatch) throw new Error("FROM clause not found in the query.");

    const tableName = fromMatch[1];

    const whereMatch = originalQuery.match(/WHERE(.*?)GROUP BY/i);
    const groupByMatch = originalQuery.match(/GROUP BY(.*?)ORDER BY/i);
    const orderByMatch = originalQuery.match(/ORDER BY/i);

    let finalQuery = originalQuery;

    if (whereMatch) {
      const originalWhereClause = whereMatch[1].trim();
      const combinedWhereClause = `${originalWhereClause} AND ${newWhereConditions}`;
      finalQuery = finalQuery.replace(
        /GROUP BY(.*?)ORDER BY/i,
        `GROUP BY ${newGroupBy} ORDER BY`
      );
      finalQuery = finalQuery.replace(
        /WHERE(.*?)GROUP BY/i,
        `WHERE ${combinedWhereClause} GROUP BY`
      );
    } else if (groupByMatch) {
      finalQuery = finalQuery.replace(
        /GROUP BY(.*?)ORDER BY/i,
        `WHERE ${newWhereConditions} GROUP BY ${newGroupBy} ORDER BY`
      );
    } else {
      if (orderByMatch) {
        finalQuery = originalQuery.replace(
          /FROM\s+([a-zA-Z0-9_]+)(.*?)(ORDER BY)/i,
          `FROM ${tableName} WHERE ${newWhereConditions} GROUP BY ${newGroupBy} $3`
        );
      } else {
        finalQuery = originalQuery.replace(
          /FROM\s+([a-zA-Z0-9_]+)/i,
          `FROM ${tableName} WHERE ${newWhereConditions} GROUP BY ${newGroupBy}`
        );
      }
    }

    return finalQuery;
  }
  useEffect(() => {
    const getNewData = async () => {
      if (currentFilterList?.length === 0) {
        return defaultData;
      }

      const oldSql = sql;

      const tableName = oldSql.match(/FROM\s+(\w+)/i)?.[1];
      const allColumnName = getAllColumnName(defaultData?.[0] || {}, tableName);

      const selectedColumnList = currentFilterList?.map(
        (data) => data?.selectedFilterColumn?.column
      );

      const customMetricColumn = CustomMetric.split(tableName + "_").pop();

      const reminingColumnName = allColumnName?.filter(
        (column) => column !== customMetricColumn
      );

      let whereQueryForContributor = "";

      if (currentFilterList?.length > 0) {
        currentFilterList.forEach((data) => {
          whereQueryForContributor += `${data?.selectedFilterColumn?.column} ='${data?.filteredValue?.[0]}' AND `;
        });
        whereQueryForContributor = whereQueryForContributor.slice(0, -4);
      }

      const groupByQuery = `${reminingColumnName?.join(", ")}`;

      const newQuery = modifySQLQuery(
        oldSql,
        whereQueryForContributor,
        groupByQuery
      );

      console.log(newQuery);

      const queryDataForHeatMap = {
        sql: newQuery,
        dbType: dbConfig?.dbType,
        dbConfig: { ...dbConfig, user: dbConfig?.username },
        queryOptions: "useMultistageEngine=true",
      };

      const newHeatMapData = await getSQLQueryResult(queryDataForHeatMap);
      return newHeatMapData;
    };

    getNewData().then((data) => {
      setNewHeatMapData(data || []);
    });
  }, [currentFilterList]);

  // console.log(chartConfigData, TableData, sql);

  const contributorData = newHeatMapData.slice(0, 10);
  return (
    <div className="flex flex-col gap-2 w-full">
      <div className="flex items-center gap-2 flex-wrap">
        {currentFilterList?.map((data, index) => {
          return (
            <Chip
              key={index}
              onClose={() => {
                removeFilter(data?.id);
              }}
              color="danger"
            >
              {data?.selectedFilterColumn?.column} is {data?.filteredValue}
            </Chip>
          );
        })}
      </div>

      <div
        className="relative"
        style={{ height: "100%", minHeight: "200px", width: "100%" }}
      >
        {hoveredBar && (
          <CustomTooltip data={hoveredBar} CustomMetric={CustomMetric} />
        )}
        <HeaderInfo />
        <ResponsiveContainer width="100%" height={"95%"}>
          <BarChart
            width={800}
            height={400}
            data={data}
            layout="vertical"
            barCategoryGap="0%" // Removes category gaps
            barGap={8}
            margin={{
              top:
                +chartConfigData?.topMargin ||
                defaultChartConfigData?.topMargin,
              right:
                +chartConfigData?.rightMargin ||
                defaultChartConfigData?.rightMargin,
              left:
                +chartConfigData?.leftMargin ||
                defaultChartConfigData?.leftMargin,
              bottom:
                +chartConfigData?.bottomMargin ||
                defaultChartConfigData?.bottomMargin,
            }}
          >
            <XAxis type="number" domain={[0, 100]}>
              <Label
                value={chartConfigData?.xAxisLabel || chartConfigData?.xAxis}
                offset={5}
                position="bottom"
                style={{ fontWeight: "bold" }}
              />
            </XAxis>
            <YAxis
              type="category"
              dataKey="groupBy"
              tickFormatter={(value) =>
                value?.split("_")[value?.split("_").length - 1]
              }
            />
            {/* <Tooltip /> */}
            {columns.map((col, barIndex) => {
              return (
                <Bar
                  key={col}
                  dataKey={`${col}.value`}
                  stackId="a"
                  onMouseEnter={(e, index) =>
                    handleMouseEnter(e, col, data[index])
                  }
                  onMouseLeave={handleMouseLeave}
                  onClick={(e) => {
                    // Prevent the tooltip from showing up when clicking on the bar
                    addCustomFilter();
                  }}
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${barIndex}-${index}`}
                      fill={getColor(entry[col])}
                      strokeWidth={0.1}
                      stroke={"#ffffff"}
                      className="cursor-pointer"
                    ></Cell>
                  ))}
                  <LabelList
                    dataKey={`${col}.avg`}
                    position="inside"
                    stroke={"#000000"}
                    style={{ fill: "#000000", fontSize: 10 }}
                  />
                </Bar>
              );
            })}
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className="flex flex-col w-full gap-2 my-4">
        <p className="text-lg font-semibold">Top Contributor</p>
        {contributorData?.length > 0 ? (
          <TableChart TableData={contributorData} />
        ) : (
          <NullData title="No Contributor found." />
        )}
      </div>
    </div>
  );
};

export default HeatMap;
