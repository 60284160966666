import {
  Button,
  Chip,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  Switch,
  Textarea,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import Dangerzone from "../../components/Dangerzone";
import {
  deleteDataProduct,
} from "../../controllers/vectordbController";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";

const SettingsNew = ({ data, setReload }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState([]);
  const navigate = useNavigate();
  const [redaction, setRedaction] = useState("");
  const [isPublic, setIsPublic] = useState(false);
  const { _spaceid } = useParams();
  const [error, setError] = useState("");
  const [inputTag, setInputTag] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [deleteInput, setDeleteInput] = useState("");
  const [reset, setReset] = useState(false);
  const handleAddTags = (e) => {
    if (e.key === "Enter" && e.target.value.trim() !== "") {
      const val = e.target.value.trim();
      if (tags.includes(val)) {
        setError("This tag already exists");
      } else {
        setTags((prev) => [...prev, val]);
        setError("");
        setInputTag("");
      }
    }
  };
  const handleDelete = async () => {
    try {
      await deleteDataProduct(data?.payload?.id, _spaceid);
      navigate(`/${_spaceid}/data-products`);
    } catch (e) {
      console.log(e);
    }
  };
  const handleUpdate = async () => {
    try {
      if (name.trim() === "" || description.trim() === "") {
        toast("Fields are empty");
        return;
      }
      const payload = {
        name,
        description,
        tags,
        public : isPublic,
        redaction,
      };
      const resp = await fetch(
        `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/dataproduct/${_spaceid}/${data?.payload?.id}`,
        {
          method: "PATCH",
          body: JSON.stringify(payload),
          mode: "cors",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      await resp.json();
      toast("Update Successfully");
      setReload((prev) => !prev);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    setName(data?.payload?.name);
    setDescription(data?.payload?.description);
    setTags(data?.payload?.tags);
    setRedaction(data?.payload?.redaction);
    setIsPublic(data?.payload?.public ? true : false);
  }, [data, reset]);
  return (
    <div>
      <Modal size="lg" isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalContent>
          <ModalBody className="gap-0">
            <span className="block text-sm font-normal mb-4 ">
              Are you sure you want to delete the {data.payload.name}?{" "}
              <span className="text-sm text-danger font-bold">
                This can not be undone.
              </span>
            </span>

            <Input
              key="outside"
              type="text"
              value={deleteInput}
              radius="sm"
              className="border rounded-lg border-divider mt-8"
              label={
                <p className="">
                  Type <strong>DELETE</strong> to confirm.
                </p>
              }
              labelPlacement="outside"
              placeholder=" "
              onChange={(e) => setDeleteInput(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              className="text-bold text-white"
              isDisabled={deleteInput !== "DELETE"}
              color="danger"
              onClick={handleDelete}
            >
              Delete {data?.payload?.name}
            </Button>
            <Button
              onClick={() => {
                setDeleteInput("");
                setIsOpen(false);
              }}
              variant="light"
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <h1 className="pt-8 pb-8 text-xl font-medium">Settings</h1>
      <div className="flex flex-col gap-8">
        <Input
          isRequired
          key="name"
          className=""
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          label="Collection Name"
          labelPlacement="outside"
          placeholder="Enter your collection folder name"
        />
        <Textarea
          key={"description"}
          isRequired
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          label="Decription"
          labelPlacement={"outside"}
          placeholder="Enter your collection's Description"
        />
        <div className="flex items-center justify-between">
          <div>Public Access</div>
          <Switch
            isSelected={isPublic}
            onValueChange={(e) => setIsPublic(e)}
          ></Switch>
        </div>
        <div className="">
          <div className="text-sm mb-2">Tags</div>
          <div className="w-full flex pb-2 min-h-[80px] justify-between flex-col gap-2 rounded-lg border border-divider">
            <Input
              classNames={{
                innerWrapper:
                  "data-[hover=true]:bg-white group-data-[focus=true]:bg-white shadown-none bg-white",
                inputWrapper:
                  "data-[hover=true]:bg-white group-data-[focus=true]:bg-white shadow-none bg-white",
              }}
              value={inputTag}
              onChange={(e) => setInputTag(e.target.value)}
              onKeyDown={(e) => handleAddTags(e)}
              placeholder="Enter tags"
              className=" border-none outline-none bg-white hover:bg-white rounded-lg"
            />
            <div className="flex px-4 flex-wrap gap-2">
              {tags.map((a) => (
                <Chip
                  className=" cursor-pointer"
                  onClose={() => setTags((prev) => prev.filter((t) => a !== t))}
                  size="sm"
                  variant="flat"
                  color="secondary"
                >
                  {a}
                </Chip>
              ))}
            </div>
          </div>
          {error !== "" && (
            <div className="pt-2 text-red-400 text-xs ">{error}</div>
          )}
        </div>
        <Textarea
          label="Redactions"
          labelPlacement={"outside"}
          placeholder="Empty"
          value={redaction}
          onChange={(e) => setRedaction(e.target.value)}
        />
        <div className="flex justify-end gap-4">
          <Button
            color="default"
            variant="flat"
            onClick={() => setReset((prev) => !prev)}
          >
            Reset
          </Button>
          <Button color="primary" onClick={handleUpdate}>
            Update{" "}
          </Button>
        </div>
        <Dangerzone
          collection={data?.payload?.name}
          onDeleteOpen={() => setIsOpen(true)}
        />
      </div>
    </div>
  );
};

export default SettingsNew;
