import { Button, Input, Checkbox, Accordion, AccordionItem } from '@nextui-org/react'
import React, { useEffect, useState } from 'react'
import { view_schema } from '../../../controllers/vectordbController'
import Loading from '../../../components/Loader/Loading';
import { convertKeysToLowercase } from '../../../controllers/basicControllers';
import { userLoginDetails, UpdateData, CommonCollectionFilterApi } from '../../../controllers/strapiController';
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import ButtonSpinner from '../../../components/Loader/ButtonSpinner';
import { useDispatch } from "react-redux";
import { pageRefresh, spaceData } from "../../../redux/actions";
import Redendent from '../Redendent';

const Context = ({ darkMode, formData, setFormData, onPrevious, onNext, selectProduct, option, setEditContext }) => {
    //  STATE INITIALIZATION
    const [loading, setLoading] = useState(true);
    const [buttonLoad, setButtonLoad] = useState(false);
    const [schema, setSchema] = useState(null);
    const [inputValue, setInputValue] = useState({});
    const [tableContext, setTableContext] = useState({});
    const [selectedKeys, setSelectedKeys] = useState([]);
    const databaseSource = selectProduct?.attributes?.Name;
    const [finalJson, setFinalJson] = useState([]);
    const [agents, setAgents] = useState();
    const [validator, setValidator] = useState(false)
    const [renderpage, setRenderPage] = useState(false);
    const dispatch = useDispatch();
    const params = useParams();
    const userId = userLoginDetails()?.userId;
    const convertedData = useSelector(spaceData);
    const json = option === "create" ? formData?.DBJson : selectProduct?.attributes?.ConnectionJSON?.properties;
    const dataSource = option === "create" ? databaseSource : selectProduct?.attributes?.data_product?.data?.attributes?.Name;
    const ConnectionPayload = {
        source: dataSource,
        ...json
    };

    const ConnectionPayloadLowerCase = convertKeysToLowercase(ConnectionPayload);

    useEffect(() => {
        const url2 = `filters[space][Name][$eq]=${params._spaceid}&filters[SelectAll][$eq]=${true}`;
        CommonCollectionFilterApi("agents", url2)
            .then((data) => { setAgents(data.data) })
            .catch((error) => toast.error("Oops!, something went wrong, please try after some time."))
    }, [])

    // SCHEMA RENDERING
    useEffect(() => {
        setLoading(true);

        view_schema(ConnectionPayloadLowerCase)
            .then((data) => { setSchema(data); setLoading(false) })
            .catch(error => toast.error("Oops!, something went wrong, please try after some time."))

    }, [databaseSource]);

    const dataList = selectProduct?.attributes?.Additional_Context?.properties

    useEffect(() => {
        if (option === "update") {
            setFinalJson(dataList);
            Object.keys(dataList)?.map(data => {
                setSelectedKeys((prev) => ({
                    ...prev,
                    [data]: [...dataList[data]?.column_schema]
                }))
                setTableContext(prev => ({
                    ...prev,
                    [data]: dataList[data]?.table_context
                }
                ));

            })
            const initialState = {};
            for (const table in dataList) {
                initialState[table] = {};
                dataList[table].column_schema.forEach(column => {
                    initialState[table][column.name] = column?.Context; // Default value for each column
                });
            }
            setInputValue(initialState)

        } else {
            setFinalJson([])
            setInputValue({})
            setSelectedKeys({})
        }
    }, [selectProduct, dataList])


    const mergeSchemas = (initialSchema, additionalContext) => {
        const result = {};

        for (const tableName in initialSchema?.tables) {
            const originalColumns = initialSchema?.tables[tableName];
            const contextColumns = additionalContext?.[tableName]?.column_schema || [];
            const tableContext = additionalContext?.[tableName]?.table_context || "";

            // Map over the original columns and add context if it exists
            result[tableName] = {
                columns: originalColumns?.map((col) => ({
                    ...col,
                    Context: contextColumns?.find((ctx) => ctx.name === col.name)?.Context || "",
                })),
                table_context: tableContext,
            };
        }

        return { tables: result };
    };


    const finalSchema = mergeSchemas(schema, selectProduct?.attributes?.Additional_Context?.properties);

    // HANDLE FOR EACH ROW CONTEXT
    const handleInputChange = (value, tableName, column) => {

        setInputValue((prev) => ({
            ...prev,
            [tableName]: {
                ...prev[tableName],
                [column.name]: value
            }
        }));

        // Clear the validation error as soon as the user starts typing
        setValidator((prevValidator) => ({
            ...prevValidator,
            [tableName]: {
                ...prevValidator[tableName],
                [column.name]: false
            }
        }));

        // Update the selectedKeys state
        setSelectedKeys((prev) => {
            const updatedKeys = { ...prev };

            updatedKeys[tableName] = updatedKeys[tableName]
                ? [...updatedKeys[tableName].filter((col) => col.name !== column.name), { ...column, Context: value }]
                : [{ ...column, Context: value }];

            return updatedKeys;
        });
    };

    // TABLE CONTEXT FOR EACH ACCORDIAN
    const handleContextChange = (tableName, value) => {
        setTableContext(prev => ({
            ...prev,
            [tableName]: value
        }
        ));

        setFinalJson((prev) => {
            const previousSchema = prev[tableName]?.column_schema ? prev[tableName].column_schema : [];
            return {
                ...prev,
                [tableName]: {
                    ...prev[tableName],
                    column_schema: previousSchema,
                    table_context: value // update the table_context value
                }
            };
        });
    }

    // HANDLE ROW SELECT HANDLER
    const handleRowSelection = (tableName, column, isChecked, inputValue) => {

        const Context = inputValue?.[tableName]?.[column?.name] || "";

        setSelectedKeys((prev) => {
            const updatedKeys = { ...prev };

            // If the row is checked (selected)
            if (isChecked) {
                if (Context !== "") {
                    setValidator((prevValidator) => ({
                        ...prevValidator,
                        [tableName]: {
                            ...prevValidator[tableName],
                            [column.name]: false, // No error if context is present
                        }
                    }));
                    updatedKeys[tableName] = updatedKeys[tableName]
                        ? [...updatedKeys[tableName], { ...column, Context: Context }]
                        : [{ ...column, Context: Context }];
                } else {
                    // Clear validation when row is unchecked
                    setValidator((prevValidator) => ({
                        ...prevValidator,
                        [tableName]: {
                            ...prevValidator[tableName],
                            [column.name]: true, // Set error if context is empty
                        }
                    }));
                }
            } else {
                if (selectedKeys[tableName]) {
                    updatedKeys[tableName] = selectedKeys[tableName]?.filter(
                        (col) => col.name !== column.name
                    );
                }
            }
            return updatedKeys;
        });
    };

    // FORMATING THE JSON DEPENDING ON CHECKED ROW 
    useEffect(() => {
        const updatedJson = Object.keys(selectedKeys).reduce((acc, tableName) => {
            const columnSchema = selectedKeys[tableName]; // selected columns
            const tableCtx = tableContext[tableName] || ""; // table context

            // Only include the table if column_schema is not empty or table_context is not an empty string
            if (columnSchema.length > 0 || tableCtx !== "") {
                acc[tableName] = {
                    column_schema: columnSchema,
                    table_context: tableCtx
                };
            }
            return acc;
        }, {});

        setFinalJson(updatedJson);
    }, [selectedKeys, inputValue, tableContext])

    useEffect(() => {

        const relation = agents?.map((data, index) => {
            if (index === 0) {
                return { "id": parseInt(data?.id), "position": { "end": true } }
            }
            return { "id": parseInt(data?.id), "position": { "before": parseInt(agents[index - 1]?.id) } }
        })
        setFormData && setFormData({ ...formData, agentRelation: relation })

    }, [agents])

    const handleSubmit = async () => {
        setButtonLoad(true);
        if (option === "create") {
            setRenderPage(true);
        } else {
            const payload = {
                Additional_Context: {
                    properties: finalJson
                }
            }
            const response = await UpdateData("collections", selectProduct?.id, payload);
            if (response) {
                setButtonLoad(false);
                toast.success("Connector updated successfully.")
                dispatch(pageRefresh(response));
                setEditContext(false);
            }
        }
    };

    if (loading) {
        return <Loading darkMode={darkMode} />
    }
    return (
        <div>
            {renderpage ? <Redendent setFormData={setFormData} setRenderPage={setRenderPage} formData={formData} convertedData={convertedData} userId={userId} finalJson={""} /> :
                <>
                    <div>
                        {option === 'create' && <div className='pb-8'>
                            <h1 className='text-3xl font-medium pb-4'>Add additional context</h1>
                            <p>Enhance querying in your data mesh by adding context like metadata, schema relationships, and domain-specific labels.</p>
                        </div>}
                        <Accordion selectionMode="multiple">
                            {finalSchema?.tables && Object.keys(finalSchema?.tables)?.map(tableName => (
                                <AccordionItem key={tableName} aria-label={tableName} title={tableName}>
                                    <Input
                                        label="Table Context"
                                        labelPlacement="outside"
                                        placeholder={`Add context to ${tableName}`}
                                        value={tableContext[tableName] || ''}
                                        onChange={(e) => handleContextChange(tableName, e.target.value)}
                                    />
                                    <div className='mt-8'>
                                        <table className='w-full table-auto' aria-label={`Table data for ${tableName}`}>
                                            <thead className='text-left bg-default-100 '>
                                                <th className='text-tiny font-semibold text-foreground-500 py-3 px-2 rounded-l-xl'>Column Name</th>
                                                <th className='text-tiny font-semibold text-foreground-500 py-3 px-2'>Type</th>
                                                <th className='text-tiny font-semibold text-foreground-500 py-3 px-2 rounded-r-xl'>Context</th>
                                            </thead>
                                            <tbody className='px-2'>
                                                {finalSchema && finalSchema?.tables?.[tableName]?.columns?.map((column, index) => {
                                                    const data = finalJson[tableName]?.column_schema?.find((data) => data.name === column.name)
                                                    return <tr key={column.name} value={index} className='px-4'>
                                                        <td className='pl-4'>
                                                            <Checkbox
                                                                isSelected={data ? true : false}
                                                                onChange={(e) => handleRowSelection(tableName, column, e.target.checked, inputValue)}
                                                            />
                                                            {column.name}
                                                        </td>
                                                        <td>{column.type}</td>
                                                        <td className='pr-4'>
                                                            <div className='py-2'>
                                                                <Input
                                                                    size='sm'
                                                                    // defaultValue={inputValue[tableName]?.[column.name] || ''}
                                                                    value={inputValue[tableName]?.[column.name] || ''}
                                                                    onChange={(e) => handleInputChange(e.target.value, tableName, column)}
                                                                    errorMessage={validator[tableName]?.[column.name] ? "Context is required." : ''}
                                                                    isInvalid={validator[tableName]?.[column.name]}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </AccordionItem>
                            ))}
                        </Accordion>
                    </div>
                    <div className="pt-8 pr-2 flex flex-row gap-4 justify-end">
                        <Button variant="light" onClick={onPrevious}>Back</Button>
                        <Button variant="flat" isLoading={buttonLoad} color="danger" isDisabled={finalJson ? false : true} spinner={buttonLoad ? ButtonSpinner() : ""} onClick={handleSubmit}>{option === 'create' ? 'Next' : 'Create'}</Button>
                    </div>
                </>}
        </div>
    )
}

export default Context;
