import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  CommonCollectionFilterApi,
  CreateData,
  userLoginDetails,
} from "../../controllers/strapiController";
import { StringLimit, titleCreater } from "../../controllers/basicControllers";
import { useSelector } from "react-redux";
import { spaceData, userData } from "../../redux/actions";

const Agents = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [agentList, setAgentList] = useState(null);
  const userId = userLoginDetails()?.userId;
  const collectionName = "agents";
  const spaceName = params?._spaceid;
  const spaceInfo = useSelector(spaceData);
  const userDetails = useSelector(userData);
  function createThreadUUID() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }
  useEffect(() => {
    const url = `filters[space][Name][$eq]=${spaceName}`;
    CommonCollectionFilterApi(collectionName, url)
      .then((data) => setAgentList(data?.data))
      .catch((error) => error);
  }, [userId, spaceName]);
  const handleCreate = async () => {
    const payload = {
      Name: "Untitled Agent",
      space: {
        disconnect: [],
        connect: [{ id: spaceInfo[0].id, position: { end: true } }],
      },
      users_permissions_user: {
        disconnect: [],
        connect: [{ id: userDetails.id, position: { end: true } }],
      },
      uuid : createThreadUUID()
    };
    try {
      setLoading(true);
      const resp = await CreateData("agents", payload);
      navigate(`/${params._spaceid}/agents/${resp.data.id}`);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="p-8">
      <div className="text-3xl font-medium">Agents</div>
      <div className="mt-4 leading-relaxed">
        Agents are powerful mini AI agents designed to operate within your
        applications, harnessing the potential of your data mesh. These agents
        can autonomously process tasks, provide insights, and adapt to different
        use cases based on the structured and unstructured data they access.
        Leverage them to enhance decision-making, automate workflows, and build
        intelligent, data-driven features in your applications.
      </div>
      <div className="flex justify-end mt-4">
        <Dropdown>
          <DropdownTrigger>
            <Button disabled={loading} color="danger">
              + Create an agent
            </Button>
          </DropdownTrigger>
          <DropdownMenu>
            <DropdownItem>
              Use a template
            </DropdownItem>
            <DropdownItem onClick={handleCreate}>
              Build from scratch
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
      <div className="mt-8">
        <Table removeWrapper>
          <TableHeader>
            <TableColumn>NAME</TableColumn>
            <TableColumn>DESCRIPTION</TableColumn>
            <TableColumn></TableColumn>
          </TableHeader>
          <TableBody emptyContent={"No rows created yet."}>
            {agentList?.length > 0 &&
              agentList?.map((data, index) => {
                return (
                  <TableRow
                    className="cursor-pointer hover:bg-grey-200 rounded-md"
                    key="1"
                    onClick={() => navigate(`/${spaceName}/agents/${data?.id}`)}
                  >
                    <TableCell>
                      {titleCreater(data?.attributes?.Name)}
                    </TableCell>
                    <TableCell>
                      {StringLimit(data?.attributes?.Description, 150)}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default Agents;
