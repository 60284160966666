export const data = [
  {
    id:1,
    label: "Drive",
    value: "drive",
    avatar: "https://w7.pngwing.com/pngs/78/40/png-transparent-google-drive-logo-icon.png",
    description: "The second most popular pet in the world",
    color: "primary"
  },


  {
    id:2,
    label: "Medium",
    value: "medium",
    avatar: "https://w7.pngwing.com/pngs/277/586/png-transparent-monogram-medium-hd-logo.png",
    description: "The second most popular pet in the world",
    color: "default"
  },


  {
    id:3,
    label: "Notion",
    value: "notion",
    avatar: "https://i.pinimg.com/originals/99/7b/0a/997b0a243df40b681d8c8177724f1b45.png",
    description: "Notion Notes",
    color: "default"
  },


  {
    id:4,
    label: "Documents",
    value: "doc",
    avatar: "https://images.news18.com/ibnlive/uploads/2021/10/google-docs.jpg",
    description: "Read from Google Docs",
    color: "primary"
  },


  {
    id:5,
    label: "Slides",
    value: "slides",
    avatar: "https://static.vecteezy.com/system/resources/previews/027/179/396/non_2x/google-slide-slides-icon-logo-symbol-free-png.png",
    description: "Read from google slides",
    color: "warning"
  },


  {
    id:6,
    label: "Sheets",
    value: "sheets",
    avatar: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2XgZu9dqz0J5123uURsTXVMjS4xRoSitZCBN5eH8hsU-AXQn1Hld5am0jU0lZh2ld9RQ&usqp=CAU",
    description: "Read from google sheets",
    color: "success"
  },

  {
    id:7,
    label: "Jira",
    value: "jira",
    avatar: "https://wac-cdn.atlassian.com/dam/jcr:bc8030e3-18c5-4013-9d58-fef65ec0f7e1/JSW-Sign.png?cdnVersion=1457",
    description: "Read from jira",
    color: "primary"
  },

  {
    id:8,
    label: "ServiceNow",
    value: "servicenow",
    avatar: "https://i.pinimg.com/736x/e9/e2/b8/e9e2b818226fe17368620379bf8cc302.jpg",
    description: "ServiceNow tickets",
    color: "success"
  },
  {
    id:9,
    label: "External URLs",
    value: "url",
    avatar: "https://e7.pngegg.com/pngimages/88/839/png-clipart-internet-internet-text-logo-thumbnail.png",
    description: "Read from External URLs",
    color: "warning"
  },
  {
    id:1,
    label: "PDF",
    value: "pdf",
    avatar: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Adobe_Acrobat_DC_logo_2020.svg/768px-Adobe_Acrobat_DC_logo_2020.svg.png",
    description: "The second most popular pet in the world",
    color: "danger"
  },

];

export const DatabaseLogo=[
  {
      id:1,
      label: "ChromeDB",
      value: "chromedb",
      avatar: "https://intellyx.com/wp-content/uploads/2023/10/Chroma.png",
      description: "The second most popular pet in the world",
      color: "primary"
  },
  {
    id:2,
    label: "Mongodb",
    value: "mongodb",
    avatar: "https://1000logos.net/wp-content/uploads/2020/08/MongoDB-Emblem.jpg",
    description: "The second most popular pet in the world",
    color: "primary"
},
{
  id:3,
  label: "Mysql",
  value: "mysql",
  avatar: "https://1000logos.net/wp-content/uploads/2020/08/MySQL-Logo.png",
  description: "The second most popular pet in the world",
  color: "primary"
},
{
  id:1,
  label: "Pinecone",
  value: "pinecone",
  avatar: "https://asset.brandfetch.io/idCLuo1dQ8/id9I25vXtV.png",
  description: "The second most popular pet in the world",
  color: "primary"
},
{
  id:1,
  label: "Postgres",
  value: "postgres",
  avatar: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUmnFYeOmmAlNV9_ZTu5cYgS2L55Q1pt9QyA&s",
  description: "The second most popular pet in the world",
  color: "primary"
},
{
  id:1,
  label: "Qdrant",
  value: "qdrant",
  avatar: "https://images.g2crowd.com/uploads/product/image/social_landscape/social_landscape_827796e51e26fd0f8ceb8e7e3d7423ba/qdrant.jpeg",
  description: "The second most popular pet in the world",
  color: "primary"
},
{
  id:9,
  label: "JSON",
  value: "json",
  avatar: "https://cdn.vectorstock.com/i/500p/39/60/font-brackets-icon-linear-logo-mark-in-black-vector-52293960.jpg",
  description: "Contains JSON files",
  color: "success"
},
]