import React, { useState } from 'react';
import { Button, useDisclosure } from "@nextui-org/react";
import KeysTable from './KeysTable';
import { CreateData, CreditUpdate, UpdateData } from "../../controllers/strapiController";
import { useSelector } from "react-redux";
import UpdateKey from './UpdateKey';
import { credits } from '../../redux/actions';
import {useDispatch} from "react-redux";

const Keys = () => {
    // INITIALIZATION
    const [ValidationConditions, setValidationConditions] = useState(false);
    const [reload, setReload] = useState(null);
    const [loader, setLoader] = useState(true);
    const [keysAction, setKeysAction] = useState(false);
    const CollectionName = "keys";
    const UserID = JSON.parse(sessionStorage.getItem("userData"))?.user?.id;
    const [formData, setFormdata] = useState({ Name: '', Description: '' });
    const [formError, setFormError] = useState({ NameError: '', DescriptionError: '' });
    const { isOpen: isApikeyOpen, onOpen: onApikeyOpen, onOpenChange: onApikeyOpenChange } = useDisclosure();
    const spaceData = useSelector(state => state);
    const indexData = spaceData && JSON.stringify(spaceData, null, 2);
    const convertedData = JSON.parse(indexData);
    const [selectedData, setSelectedData] = useState(null);
    const dispatch = useDispatch();

    // API KEY GENERATION
    function generateAPIKey(length) {
        const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let apiKey = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            apiKey += charset[randomIndex];
        }
        return apiKey;
    }

    // Example usage: Generate a 32-character API key
    const apiKeys = generateAPIKey(32);

    // VALIDATION
    const Validation = () => {
        let isValid = true;

        if (formData.Name === '') {
            setFormError((previous) => ({ ...previous, NameError: "App name is required." }));
            isValid = false;
        }

        if (formData.Description === '') {
            setFormError((previous) => ({ ...previous, DescriptionError: "Description is required." }));
            isValid = false;
        } else if (formData.Description && formData.Description.length > 120) {
            setFormError((previous) => ({ ...previous, DescriptionError: "Description should not be more than 40 charecters." }));
            isValid = false;
        }
        return isValid;
    }

    // SUBMIT HANDLER 
    const SubmitHandler = async (onClose) => {
        const spaceID = convertedData?.space?.[0]?.id;
        // CREATE API KEY PAYLOAD
        const payload = {
            Name: formData.Name,
            Description: formData.Description,
            API_Key: `${apiKeys}`,
            isCopied:false,
            users_permissions_user: { disconnect: [], connect: [{ id: UserID, position: { end: true } }] },
            space: { disconnect: [], connect: [{ id: spaceID, position: { end: true } }] }
        }
        // UPDATE API KEY PAYLOAD
        const updatePayload = {
            Name: formData.Name,
            Description: formData.Description,
        }
        const Validate = Validation();
        if (Validate) {
            onClose();
            setLoader(true);
            setValidationConditions(false);
            if (keysAction) {
                const response = await CreateData(CollectionName, payload);
                const updateKey = await CreditUpdate("keys",UserID);
                dispatch(credits(updateKey?.Credit));
                setLoader(false);
                setReload(response);
                setFormdata({ Name: "", Description: "" });
            } else {
                const response = await UpdateData(CollectionName, selectedData, updatePayload)
                setLoader(false);
                setReload(response);
                setFormdata({ Name: "", Description: "" });
            }
        } else {
            setValidationConditions(true);
        }
    }


    return (
        <div>
            <div className='text-3xl font-medium'>
                API Keys
            </div>
            <div className='mt-4 leading-relaxed'>
                Generate an API key to enable secure external access to your data mesh. This allows you to authenticate and authorize external applications, providing controlled access to your data while maintaining security and integrity.
            </div>
            <div className='flex mt-4 pb-8 justify-end'>
                <Button color='danger' onClick={() => { onApikeyOpen(); setKeysAction(true); setFormdata({ Name: '', Description: '' }) }}>
                    + Create an API key
                </Button>
            </div>
            <KeysTable
                reload={reload}
                setReloader={setReload}
                loader={loader}
                setLoader={setLoader}
                onApikeyOpen={onApikeyOpen}
                setFormdata={setFormdata}
                setKeysAction={setKeysAction}
                setSelectedData={setSelectedData}
            />

            <UpdateKey
                setFormdata={setFormdata}
                formData={formData}
                formError={formError}
                ValidationConditions={ValidationConditions}
                SubmitHandler={SubmitHandler}
                isApikeyOpen={isApikeyOpen}
                onApikeyOpenChange={onApikeyOpenChange}
                setKeysAction={setKeysAction}
                keysAction={keysAction}
                setSelectedData={setSelectedData} />
        </div>
    )
}

export default Keys;