export const generateURLString = ({
    fields,
    populate,
    dataFilter,
    relationFilter
  }) => {
    let fieldsstr = "";
    let filterstr = "";
    if ((fields && fields.length > 0) || (populate && populate.length > 0)) {
      if(fields && fields.length > 0){
          for(let i = 0 ; i< fields.length ;i++){
              fieldsstr = fieldsstr + `&fields[${i}]=${fields[i]}`
          }
      }
      if(populate && populate.length > 0){
          for(let i = 0 ; i< populate.length ;i++){
              fieldsstr = fieldsstr + `&populate[${i}]=${populate[i]}`
          }
      }
    } else {
      fieldsstr = "&populate=*"
    }
    if(dataFilter){
      for(let i=0 ; i< dataFilter.length ; i++){
          filterstr = filterstr + `&filters[${dataFilter[i].filterKey}][$eq]=${dataFilter[i].filterValue}`
      }
    }
    if(relationFilter){
      for(let i=0 ; i< relationFilter.length ; i++){
          filterstr = filterstr + `&filters[${relationFilter[i].filterKey}][documentId][$eq]=${relationFilter[i].filterValue}`
      }
    }
    return `${filterstr}${fieldsstr}`;
  };