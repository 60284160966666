import { Tabs, Tab, Chip, Image } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getFilteredData,
} from "../../controllers/strapiController";
import NotFound from "../../components/NotFound";
import { Toaster } from "react-hot-toast";
import Documents from "./CollectionTabs/Documents";
import CollectionMembers from "./CollectionTabs/Collection_Members";
import { collectionSkeleton } from "../../components/Skeleton";
import { colorPicker } from "../../controllers/basicControllers";
import { getSingleDataProduct } from "../../controllers/vectordbController";
import Config from "../DataProducts/Config";
import SettingsNew from "../subPages/SettingsNew";
import Invite from "../../components/Invite";

const SingleCollection = () => {
  const [collectionData, setCollection] = useState(null);
  const [product, setProduct] = useState(null);
  const [Loader, setLoader] = useState(true);
  const [reload, setReload] = useState("");
  const [members,setMembers] = useState([])
  const params = useParams();
  const [notFound, setNotFound] = useState(false);
  const UserDetails = JSON.parse(sessionStorage.getItem("userData"));
  const UserID = UserDetails && UserDetails.user && UserDetails.user.id;
  useEffect(() => {
    const fetchCollection = async () => {
      try {
        const resp = await getSingleDataProduct(
          params._id,
          params._spaceid,
        );
        if (resp && resp.length > 0) {
          setCollection(resp[0]);
          setMembers(resp[0].payload.members ?? [])
        } else {
          setNotFound(true);
        }
        const resp2 = await getFilteredData(
          "data-products",
          [{ filterKey: "Name", filterValue: resp[0].payload.data_product }],
        );
        setProduct(resp2.data[0]);
      } catch (e) {
        console.log(e);
      } finally {
        setLoader(false);
      }
    };
    if (params._spaceid && params._id) {
      fetchCollection();
    }
  }, [reload, params._id, params._spaceid]);

  if (Loader) {
    return collectionSkeleton();
  }

  if (notFound) {
    return <NotFound />;
  }
  return (
    <div className="p-8">
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          style: { boxShadow: "none", border: "1px solid #bdb5b5" },
          success: { duration: 3000 },
        }}
      />
      <div className="text-3xl font-medium flex gap-4 items-center">
        <Image width={60} src={product?.attributes?.Image} />
        {collectionData?.payload?.name}
      </div>
      <div className="mt-8 gap-2 flex flex-wrap">
        {collectionData?.payload?.tags?.map((tag, index) => (
          <Chip
            key={index}
            color={colorPicker(tag.length)}
            variant="flat"
            size="sm"
          >
            {tag}
          </Chip>
        ))}
      </div>
      <div className="mt-4 leading-relaxed ">
        {collectionData && collectionData?.payload?.description}
      </div>
      <div className="flex flex-row">
        <div className="w-full">
          <Tabs
            color={colorPicker(collectionData?.payload?.id)}
            variant="underlined"
            disabledKeys={!String(collectionData?.payload?.user_id) === String(UserID) ? ["Settings", "Members"] : []}
            className="pt-8"
          >
            <Tab key="Documents" title="Documents">
              <Documents
                UserOptions={String(collectionData?.payload?.user_id) === String(UserID) }
                color={colorPicker(collectionData?.payload?.id)}
              />
            </Tab>
            <Tab key="Members" title="Members">
              <Invite invitedIds={members} setInvitedIds={setMembers} productId={collectionData.payload.id} adminId={collectionData?.payload?.user_id} />
            </Tab>
            {collectionData?.payload?.config && (
              <Tab key="Configuration" title="Configuration">
                <Config setReload={setReload} option="update" id={params._id} selectedProduct={product} formData={{config : collectionData?.payload?.config }} />
              </Tab>
            )}
            <Tab key="Settings" title="Settings">
              <SettingsNew data={collectionData} setReload={setReload} />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default SingleCollection;
