import React, { useEffect, useState } from "react";
import {
  CommonCollectionFilterApi,
  DeleteSingleAttribute,
  UpdateData,
} from "../../../controllers/strapiController";
import { useNavigate, useParams } from "react-router-dom";
import {
  Tabs,
  Tab,
  Textarea,
  Switch,
  Button,
  Input,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Modal,
  ModalContent,
  Accordion,
  AccordionItem,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  SelectItem,
  Select,
  Avatar,
} from "@nextui-org/react";
import NotFound from "../../../components/NotFound";
import ServerIssue from "../../../components/ServerIssue";
import Forbidden from "../../../components/Forbbiden";
import { ConnectorSkeleton } from "../../../components/Skeleton";
import { Lock } from "lucide-react";
import ChatBox from "./ChatBox";
import toast, { Toaster } from "react-hot-toast";
import ExecuteCurlComponent from "./CurlCommand";
import WebSocketComponent from "./WebsocketCommand";
import { getDataProducts } from "../../../controllers/vectordbController";
import Deepseek from "../../../Images/deepseek.webp";
import HayaLogo from "../../../Images/haya-logo.png";
import MistralLogo from "../../../Images/mistral.png";
import MetaLogo from "../../../Images/meta.png";
import OpenAILogo from "../../../Images/openAI.png";
const SingleAgent = () => {
  const [agent, setAgent] = useState(null);
  const [agentTitle, setAgentTitle] = useState("");
  const [loader, setLoader] = useState(true);
  const models = [
    {
      name: "Haya",
      icon: HayaLogo,
    },
    {
      name: "Mistral",
      icon: MistralLogo,
    },
    {
      name: "Llama3.1",
      icon: MetaLogo,
    },
    {
      name: "OpenAI",
      icon: OpenAILogo,
    },
    {
      name: "Deepseek",
      icon: Deepseek,
    },
  ];
  const [disabled, setDisabled] = useState(true);
  const [reload, setReload] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [open, setOpen] = useState(false);
  const [tuning, setTuning] = useState({
    role: "",
    goal: "",
    backstory: "",
    memory: false,
    model: "",
    dataProducts: [],
  });
  const [deleteConfirm, setDeleteConfirm] = useState("");
  const [forbidden, setForbidden] = useState(false);
  const navigate = useNavigate();
  const [serverIssue, setServerIssue] = useState(false);
  const [connectionRefused, setConnectonRefused] = useState(false);
  const params = useParams();
  const CollectionName = "agents";
  const [dataProducts, setDataProducts] = useState([]);
  const handleSave = async () => {
    if (tuning.model === "") {
      return;
    }
    try {
      setDisabled(true);
      await UpdateData("agents", params._id, {
        InstructionJSON: tuning,
      });
      setAgent((prev) => ({
        ...prev,
        attributes: {
          ...prev.attributes,
          InstructionJSON: tuning,
        },
      }));
      toast("Saved");
    } catch (e) {
      console.log(e);
    } finally {
      setDisabled(false);
    }
  };
  const handleSaveName = async () => {
    try {
      setDisabled(true);
      await UpdateData("agents", params._id, { Name: agentTitle });
      setAgent((prev) => ({
        ...prev,
        attributes: { ...prev.attributes, Name: agentTitle },
      }));
      toast("Saved");
    } catch (e) {
      console.log(e);
    } finally {
      setDisabled(false);
    }
  };
  const handleDelete = async () => {
    try {
      await DeleteSingleAttribute("agents", agent?.id);
      navigate(`/${params._spaceid}/agents`);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    const url = `filters[id][$in][0]=${params._id}&filters[space][Name][$eq]=${params._spaceid}&[populate][space][on]&[populate][data_product][on]&[populate][author][on]&[populate][inviteds][on]&[populate][requests][on]&[populate][viewed][on]`;
    CommonCollectionFilterApi(CollectionName, url)
      .then((data) => {
        setLoader(false);
        if (data.error) {
          if (data.error.status !== 404 && data.error.status !== 403) {
            setServerIssue(true);
          }
          if (data.error.status === 404) {
            setNotFound(true);
          }
          if (data.error.status === 403) {
            setForbidden(true);
          }
        } else if (data.data.length > 0) {
          setLoader(false);
          setAgent(data.data?.[0]);
          setAgentTitle(data?.data?.[0].attributes.Name);
          if (data?.data?.[0].attributes.InstructionJSON) {
            setTuning(data?.data?.[0].attributes.InstructionJSON);
          }
        } else {
          setNotFound(true);
        }
      })
      .catch((error) => {
        setLoader(false);
        setConnectonRefused(true);
      });
  }, [reload, params._id, params._spaceid]);
  useEffect(() => {
    const fetchProd = async () => {
      try {
        const resp = await getDataProducts(params._spaceid);
        setDataProducts(resp);
      } catch (e) {
        console.log(e);
      }
    };
    if (params._spaceid) {
      fetchProd();
    }
  }, [params._spaceid]);
  console.log("prod", dataProducts);
  if (loader) {
    return ConnectorSkeleton();
  }

  if (notFound) {
    return <NotFound />;
  }

  if (serverIssue) {
    return <ServerIssue />;
  }

  if (connectionRefused) {
    return <ServerIssue />;
  }

  if (forbidden) {
    return <Forbidden />;
  }
  console.log("tuning", tuning);
  return (
    <div className=" h-full flex overflow-y-auto ">
      <Modal isOpen={open} onClose={() => setOpen(false)}>
        <ModalContent>
          <Card className="p-4">
            <CardHeader className="text-red-500 font-bold">
              Confirm Deletion
            </CardHeader>
            <CardBody>
              <p className="text-sm text-gray-600">
                This action is irreversible. Please type{" "}
                <strong>"DELETE"</strong> to confirm.
              </p>
              <Input
                className="mt-4"
                placeholder="Type DELETE"
                value={deleteConfirm}
                onChange={(e) => setDeleteConfirm(e.target.value)}
              />
            </CardBody>
            <CardFooter className="flex items-center justify-end gap-2">
              <Button size="sm" variant="light" onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <Button
                size="sm"
                color="danger"
                disabled={deleteConfirm !== "DELETE"}
                onClick={handleDelete}
              >
                Confirm Delete
              </Button>
            </CardFooter>
          </Card>
        </ModalContent>
      </Modal>
      <div className="flex-1 flex h-full flex-col overflow-y-auto">
        <ChatBox
          title={agent.attributes.Name}
          role={tuning.role}
          goal={tuning.goal}
          backstory={tuning.backstory}
        />
      </div>
      <div className="flex flex-col pt-8 px-4 w-[450px] border-l border-divider overflow-y-auto">
        <Tabs>
          <Tab title="Fine Tune">
            <div className="flex flex-col overflow-y-auto gap-8">
              <div className="flex items-center gap-16">
                <div className=" text-sm">Model</div>
                <Select
                  startContent={(tuning.model !== "" && models.find((a) => a.name === tuning.model) ? <Avatar src={models.find((a) => a.name === tuning.model).icon} size="sm" /> : <></> )}
                  selectionMode="single"
                  onSelectionChange={(e) =>
                    setTuning((prev) => ({
                      ...prev,
                      model: Array.from(e).length > 0 ? Array.from(e)[0] : "",
                    }))
                  }
                  selectedKeys={
                    tuning.model !== "" ? new Set([tuning.model]) : new Set([])
                  }
                >
                  {models.map((a) => (
                    <SelectItem
                      key={a.name}
                      startContent={<Avatar src={a.icon} size="sm" />}
                    >
                      {a.name}
                    </SelectItem>
                  ))}
                </Select>

              </div>
              <Accordion selectionMode="multiple">
                <AccordionItem
                  key="1"
                  aria-label="Accordion 1"
                  title="Parameters"
                >
                  <div className="flex flex-col gap-8 pb-8">
                    <Textarea
                      labelPlacement="outside"
                      label="Role"
                      value={tuning.role}
                      onChange={(e) =>
                        setTuning((prev) => ({ ...prev, role: e.target.value }))
                      }
                    />
                    <Textarea
                      labelPlacement="outside"
                      label="Goal"
                      value={tuning.goal}
                      onChange={(e) =>
                        setTuning((prev) => ({ ...prev, goal: e.target.value }))
                      }
                    />
                    <Textarea
                      labelPlacement="outside"
                      label="Backstory"
                      value={tuning.backstory}
                      onChange={(e) =>
                        setTuning((prev) => ({
                          ...prev,
                          backstory: e.target.value,
                        }))
                      }
                    />
                  </div>
                </AccordionItem>

                <AccordionItem key="4" aria-label="Accordion 4" title="Data">
                  <div className="flex items-center gap-16">
                    <div className=" text-sm">Data Product</div>
                    <Dropdown>
                      <DropdownTrigger>
                        <Button variant="light">
                          {tuning.dataProducts &&
                          tuning.dataProducts.length > 0 ? (
                            <div>{`${
                              dataProducts.find(
                                (a) =>
                                  a.payload.id.toString() ===
                                  tuning.dataProducts[0].toString()
                              )?.payload?.name
                            } ${
                              tuning.dataProducts.length > 1
                                ? `+ ${tuning.dataProducts.length - 1}`
                                : ""
                            }`}</div>
                          ) : (
                            "Select data products"
                          )}
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu
                        closeOnSelect={false}
                        selectionMode="multiple"
                        onSelectionChange={(e) =>
                          setTuning((prev) => ({
                            ...prev,
                            dataProducts: Array.from(e),
                          }))
                        }
                        selectedKeys={
                          tuning.dataProducts !== ""
                            ? new Set(tuning.dataProducts)
                            : new Set([])
                        }
                      >
                        {dataProducts.map((a) => (
                          <DropdownItem key={a.payload.id}>
                            {a.payload.name}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </AccordionItem>
                <AccordionItem
                  key="5"
                  aria-label="Accordion 5"
                  title="Output"
                ></AccordionItem>
              </Accordion>

              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <div className="text-sm">Memory</div>
                  <Switch
                    size="sm"
                    isSelected={tuning.memory}
                    onValueChange={(e) =>
                      setTuning((prev) => ({ ...prev, memory: e }))
                    }
                  />
                </div>
              </div>
              <div className="mt-8 flex items-center justify-end">
                <Button onClick={handleSave} size="sm" color="primary">
                  Save
                </Button>
              </div>
            </div>
          </Tab>
          <Tab title="API">
            <div className="flex flex-col w-full gap-8 overflow-y-auto">
              <Accordion selectionMode="multiple">
                <AccordionItem
                  key="1"
                  aria-label="Accordion 1"
                  title="Stream Socket"
                >
                  <div className="flex flex-col gap-4">
                    <div>Websocket command for streaming the response:</div>
                    <WebSocketComponent />
                  </div>
                </AccordionItem>
                <AccordionItem key="2" aria-label="Accordion 2" title="API">
                  <div className="flex flex-col gap-4">
                    <div>Curl command for executing Rest API:</div>
                    <ExecuteCurlComponent />
                  </div>
                </AccordionItem>
              </Accordion>
            </div>
          </Tab>
          <Tab title="Settings">
            <div className="flex flex-col overflow-y-auto gap-8">
              <Input
                endContent={<Lock className=" text-gray" />}
                label="Agent Unique ID"
                labelPlacement="outside"
                value={agent.attributes.uuid}
                disabled
              />
              <Input
                label="Agent Title"
                labelPlacement="outside"
                value={agentTitle}
                onChange={(e) => setAgentTitle(e.target.value)}
              />

              <Card
                className="border-red-500 border-1 p-1 rounded-2xl bg-red-100"
                shadow="none"
                fullWidth
              >
                <CardHeader className=" text-red-500 font-bold">
                  Danger Zone
                </CardHeader>
                <CardBody className="text-xs">
                  Deleting this agent is a permanent action and cannot be
                  undone. Are you sure you want to proceed?
                </CardBody>
                <CardFooter className="flex items-center justify-end">
                  <Button
                    onClick={() => setOpen(true)}
                    size="sm"
                    color="danger"
                  >
                    DELETE
                  </Button>
                </CardFooter>
              </Card>
              <div className="mt-8 flex items-center justify-end">
                <Button
                  disabled={
                    agentTitle === "" || agentTitle === agent.attributes.Name
                  }
                  onClick={handleSaveName}
                  size="sm"
                  color="primary"
                >
                  Save
                </Button>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default SingleAgent;
