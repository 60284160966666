export const TourSteps = () => {

  const styles = {
    backgroundColor: 'white',
    color: 'black',
  }

  const steps = [
    {
      selector: "#KnowledgeBase",
      content: <p className="">Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis minus eveniet, deleniti assumenda nulla molestiae consequatur repellat numquam, nesciunt obcaecati facere doloribus, magni rerum necessitatibus rem? Atque perferendis nihil velit?</p>,
      style: styles
    },
    {
      selector: "#DatabaseProducts",
      content: <p className="">Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis minus eveniet, deleniti assumenda nulla molestiae consequatur repellat numquam, nesciunt obcaecati facere doloribus, magni rerum necessitatibus rem? Atque perferendis nihil velit?</p>,
      style: styles
    },
    {
      selector: "#Collections",
      content: <p className="">Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis minus eveniet, deleniti assumenda nulla molestiae consequatur repellat numquam, nesciunt obcaecati facere doloribus, magni rerum necessitatibus rem? Atque perferendis nihil velit?</p>,
      style: styles
    },
    {
      selector: "#Connectors",
      content: <p className="">Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis minus eveniet, deleniti assumenda nulla molestiae consequatur repellat numquam, nesciunt obcaecati facere doloribus, magni rerum necessitatibus rem? Atque perferendis nihil velit?</p>,
      style: styles
    },
    {
      selector: "#Tags",
      content: <p className="">Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis minus eveniet, deleniti assumenda nulla molestiae consequatur repellat numquam, nesciunt obcaecati facere doloribus, magni rerum necessitatibus rem? Atque perferendis nihil velit?</p>,
      style: styles
    },
    {
      selector: "#Tool",
      content: <p className="">Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis minus eveniet, deleniti assumenda nulla molestiae consequatur repellat numquam, nesciunt obcaecati facere doloribus, magni rerum necessitatibus rem? Atque perferendis nihil velit?</p>,
      style: styles
    },
    {
      selector: "#Haya",
      content: <p className="">Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis minus eveniet, deleniti assumenda nulla molestiae consequatur repellat numquam, nesciunt obcaecati facere doloribus, magni rerum necessitatibus rem? Atque perferendis nihil velit?</p>,
      style: styles
    },
    {
      selector: "#Knowledgetree",
      content: <p className="">Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis minus eveniet, deleniti assumenda nulla molestiae consequatur repellat numquam, nesciunt obcaecati facere doloribus, magni rerum necessitatibus rem? Atque perferendis nihil velit?</p>,
      style: styles
    },
    {
      selector: "#Keys",
      content: <p className="">Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis minus eveniet, deleniti assumenda nulla molestiae consequatur repellat numquam, nesciunt obcaecati facere doloribus, magni rerum necessitatibus rem? Atque perferendis nihil velit?</p>,
      style: styles
    },
    {
      selector: "#extensions",
      content: <p className="">Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis minus eveniet, deleniti assumenda nulla molestiae consequatur repellat numquam, nesciunt obcaecati facere doloribus, magni rerum necessitatibus rem? Atque perferendis nihil velit?</p>,
      style: styles
    },
    {
      selector: "#Teams",
      content: <p className="">Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis minus eveniet, deleniti assumenda nulla molestiae consequatur repellat numquam, nesciunt obcaecati facere doloribus, magni rerum necessitatibus rem? Atque perferendis nihil velit?</p>,
      style: styles
    },
    {
      selector: "#members",
      content: <p className="">Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis minus eveniet, deleniti assumenda nulla molestiae consequatur repellat numquam, nesciunt obcaecati facere doloribus, magni rerum necessitatibus rem? Atque perferendis nihil velit?</p>,
      style: styles
    },
    {
      selector: "#Archive",
      content: <p className="">Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis minus eveniet, deleniti assumenda nulla molestiae consequatur repellat numquam, nesciunt obcaecati facere doloribus, magni rerum necessitatibus rem? Atque perferendis nihil velit?</p>,
      style: styles
    },
    {
      selector: "#trash",
      content: <p className="">Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis minus eveniet, deleniti assumenda nulla molestiae consequatur repellat numquam, nesciunt obcaecati facere doloribus, magni rerum necessitatibus rem? Atque perferendis nihil velit?</p>,
      style: styles
    },
    {
      selector: "#settings",
      content: <p className="">Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis minus eveniet, deleniti assumenda nulla molestiae consequatur repellat numquam, nesciunt obcaecati facere doloribus, magni rerum necessitatibus rem? Atque perferendis nihil velit?</p>,
      style: styles
    }
  ]

  return steps
}