import React, { useState, useEffect, useMemo } from "react";
import { Button } from "@nextui-org/react";
import { CommonCollectionFilterApi, getAllData } from "../../controllers/strapiController";
import { useParams, useNavigate } from "react-router-dom";
import Forbbiden from "../../components/Forbbiden";
import UnAuthorized from "../../components/UnAuthorized";
import ServerIssue from "../../components/ServerIssue";
import NotFound from "../../components/NotFound";
import { cardSkeleton } from "../../components/Skeleton";
import Cards from "../../components/Cards";
import ViewAllCard from "../../components/ViewAllCard";
import { DatabaseLogo } from "../Connectors/ConnectorData";
import { colorPicker } from "../../controllers/basicControllers";
import { toast } from "react-hot-toast";
import { getDataProducts } from "../../controllers/vectordbController";
import ProductCards from "../../components/ProductCards";

const DataProducts = () => {
  // STATE INITIALIZATION
  const { _spaceid } = useParams();
  const [dataProd,setDataProd] = useState([]);
  const [loader, setLoader] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [forbidden, setForbidden] = useState(false);
  const [serverIssue, setServerIssue] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const LogoData = DatabaseLogo;
  const [products, setProducts] = useState([]);

  // USER INFO
  const userDetails = JSON.parse(sessionStorage.getItem("userData"));
  const userID = userDetails?.user?.id;

  // API RESPONSE
  useEffect(() => {
    const fetchProducts = async () => {
      setLoader(true);
      try {
        const resp2 = await getAllData("data-products");
        const resp = await getDataProducts(_spaceid);
        setProducts(resp);
        console.log("data-prdocits",resp);
        setDataProd(resp2.data)
      } catch (e) {
        console.log(e);
      } finally {
        setLoader(false)
      }
    };
    fetchProducts();
    // COLLECTIONS API
  }, [params._spaceid]);

  // NAVIGATION FOR CREATE
  const navigateHandler = () => {
    navigate("create");
  };
  const sortedProd = useMemo(() => {
    const filteredProd = products.filter((a) => a.payload.public || String(userID) === String(a.payload.user_id) || (a.payload?.invited && a.payload.invited?.includes(String(userID)) ) )
    const coll = filteredProd.filter((a) => a.payload.type === "collections");
    const conn = filteredProd.filter((a) => a.payload.type === "connectors");
    const tool = filteredProd.filter((a) => a.payload.type === "tools");
    return { coll, conn, tool };
  }, [products]);
  // SERVER ERROR CONDITION PAGE RENDER
  if (notFound) return <NotFound />;
  if (serverIssue) return <ServerIssue />;
  if (authorized) return <UnAuthorized message="Authorization error." />;
  if (forbidden) return <Forbbiden />;

  // RETURN UI
  return (
    <div className="p-8">
      <h1 className="text-3xl font-medium">Data Products</h1>
      <div className="mt-4 leading-relaxed">
        Seamlessly integrate your data sources into your data mesh and unify all
        your enterprise data products in one environment.
      </div>
      <div className="flex mt-4 pb-8 justify-end">
        <Button color="danger" onClick={navigateHandler}>
          + Create a data product
        </Button>
      </div>
      <div className="flex flex-col gap-8">
        <div className="w-full">
          <h1 className="text-xl font-medium border-b pb-2 border-divider">
            Collections
          </h1>
          <p className="pt-2">
            Effortlessly connect and integrate diverse data sources with our
            connectors for seamless, consistent data flow across your
            organization.
          </p>

          {loader ? (
            cardSkeleton()
          ) : (
            <>
              {sortedProd.coll.length > 0 ? (
                <div className=" flex items-center gap-8 flex-wrap py-8 ">
                  {sortedProd.coll.map((a) => (
                    <ProductCards product={a} data_prod={dataProd} />
                  ))}
                  
                </div>
              ) : (
                <div className="italic font-medium pt-8 flex text-sm justify-center text-coolGray-400">
                  {" "}
                  You do not have any collections inside your data mesh.
                </div>
              )}
            </>
          )}
        </div>
        <div>
          <h1 className="text-xl font-medium border-b pb-2 border-divider">
            Connectors
          </h1>
          <p className="pt-2">
            Connect and sync structured data across databases effortlessly with
            our advanced connectors, ensuring seamless integration and
            consistency in your data management.
          </p>

          {loader ? (
            cardSkeleton()
          ) : (
            <>
              {sortedProd.conn.length > 0 ? (
                <div className="py-8 gap-8 flex flex-wrap ">
                  {sortedProd.conn.map((a) => (
                    <ProductCards product={a} data_prod={dataProd}  />
                  ))}
                </div>
              ) : (
                <div className="italic font-medium pt-8 flex text-sm justify-center text-coolGray-400">
                  {" "}
                  You do not have any connectors inside your data mesh.
                </div>
              )}
            </>
          )}
        </div>
        <div>
          <h1 className="text-xl font-medium  border-b pb-2 border-divider">
            Tools
          </h1>
          <p className="pt-2">
            Seamlessly connect third-party applications to your data mesh with
            our tools, enhancing integration and data flow across your
            ecosystem.
          </p>

          {loader ? (
            cardSkeleton()
          ) : (
            <>
              {sortedProd.tool.length ? (
                <div className="grid md:grid-cols-2 gap-8 pt-8 sm:grid-cols-1 lg:grid-cols-4">
                  <>
                  {sortedProd.tool.map((a) => (
                    <ProductCards product={a} data_prod={dataProd}  />
                  ))}
                  </>
                </div>
              ) : (
                <div className="italic font-medium pt-8 flex text-sm justify-center text-coolGray-400">
                  You do not have any Tools inside your data mesh.
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DataProducts;
