import React, { useState, useEffect } from 'react'
import { singleUserdocuments, delete_document } from '../../controllers/vectordbController';
import { cardSkeleton } from "../../components/Skeleton";
import SearchIcon from "../../Images/Search";
import { filterUniqueEntries, stringCreater } from "../../controllers/basicControllers";
import { userLoginDetails, getOneData } from '../../controllers/strapiController';
import { getTimeDifference, StringLimit } from "../../controllers/basicControllers";
import { useNavigate } from "react-router-dom";
import DeleteModal from '../../components/DeleteModal';
import { data } from "../../pages/Connectors/ConnectorData";
import { Card, CardHeader, Image, Chip, CardBody, DropdownItem, DropdownMenu, DropdownTrigger, Dropdown, useDisclosure, Input } from "@nextui-org/react";
import EmptyArray from '../../components/EmptyArray';

const DocumentsUploaded = () => {
  const [documents, setDocuments] = useState([]);
  const [loader, setLoader] = useState(true);
  const [selectedDoc, setSelectedDoc] = useState('');
  const [inputText, setInputText] = useState('');
  const [usersData, setUserData] = useState();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onOpenChange: onDeleteOpenChange } = useDisclosure();
  const userData = userLoginDetails();
  const userId = userData?.userId;

  useEffect(() => {
    getOneData("users", userId)
      .then((data) => setUserData(data))
  }, [userId])

  const spaceNames = usersData?.spaces?.map(item => item.Name);

  useEffect(() => {
    if (spaceNames?.length > 0) {
      setLoader(true);
      const promises = spaceNames.map((element) =>
        singleUserdocuments(element, element, userId)
          .then((item) => {
            if (item?.documents?.length > 0) {
              const documentsWithElement = item.documents.map((doc) => ({
                ...doc,
                spaceName: element
              }));
              setDocuments((prevDocuments) => [...prevDocuments, ...documentsWithElement]);
            }
          })
          .catch((error) => {
            console.error(error);
          })
      );

      // Wait for all promises to resolve
      Promise.all(promises).finally(() => {
        setLoader(false);
      });
    }
  }, [usersData]);


  const uniqueDataArray = documents && filterUniqueEntries(documents)

  const Navigate = useNavigate();
  const NavigateHandler = (value) => {
    Navigate(`/${value.spaceName}/doc/${value?.payload?.file_document_id}`)
  }

  const handleDeleteOpen = (value) => {
    onDeleteOpen();
    setSelectedDoc(value);
  }

  const deleteHandler = async () => {
    const response = await delete_document(selectedDoc?.payload?.id, selectedDoc?.spaceName)
    if (response) {
      // setReload(response)
    }
  }

  const finalData = inputText !== '' ? uniqueDataArray?.length > 0 ? uniqueDataArray?.filter(item => item?.payload?.document_title?.toLowerCase()?.includes(inputText?.toLowerCase())) : [] : uniqueDataArray;

  return (
    <div className='px-4'>
      <div className='mt-16 text-3xl'>
        Documents Uploaded
      </div>
      <div className='mt-8'>
        Here are all the documents that you uploaded across all spaces
      </div>
      <div className=' pt-8 flex flex-row justify-between items-center'>
        <div className='w-4/5 '>
          <Input
            placeholder='Search for documents'
            startContent={<SearchIcon className={`text-large text- pointer-events-none flex-shrink-0 `} />}
            onChange={(e) => setInputText(e.target.value)}
            value={inputText}
          />
        </div>
        <div className='flex flex-col items-center justify-center'>
          <p className=''>Total results: {finalData?.length > 0 ? finalData?.length > 10 ? finalData?.length : "0" + finalData?.length : "00"}</p>
        </div>
      </div>
      {loader ? cardSkeleton() : <>
        {finalData?.length > 0 ?
          <div className='pt-8 pb-8 flex flex-wrap gap-8'>
            {finalData?.map((item) => {
              const filteredData = data?.find((arr) => arr.value === item.payload.source);
              return <div onClick={() => NavigateHandler(item)} className='max-w-[560px] w-full'>
                <Card className="max-w-[560px] w-full min-h-[245px] min-w-full w-full h-full border border-divider cursor-pointer " shadow='none'>
                  <CardHeader className="flex gap-3 justify-between px-4 pt-4">
                    <div className='flex justify-between gap-3'>
                      <Image
                        alt="nextui logo"
                        height={40}
                        radius="sm"
                        src={filteredData && filteredData.avatar}
                        width={40}
                      />
                      <div className="flex flex-col">
                        <p className="text-md">{item.payload.document_title}</p>
                        <div className='text-sm text-warmGray-500'>
                          {usersData && stringCreater(usersData.firstname, usersData.email)} • Uploaded {getTimeDifference(item.payload.file_document_id)}
                        </div>
                      </div>
                    </div>
                    <Dropdown placement="bottom-end">
                      <DropdownTrigger>
                        <div className='flex items-center justify-evenly cursor-pointer'>
                          <span className='text-md font-bold text-slate-500'>...</span>
                        </div>
                      </DropdownTrigger>
                      <DropdownMenu aria-label="Profile Actions" variant="flat">
                        <DropdownItem key="edit">
                          <div className='flex items-center gap-2'>
                            <lord-icon
                              src="https://cdn.lordicon.com/pflszboa.json"

                              style={{ width: "16px", height: "16px" }}>
                            </lord-icon>
                            Edit
                          </div>
                        </DropdownItem>
                        <DropdownItem key="system">
                          <div className='flex items-center gap-2'>
                            <lord-icon
                              src="https://cdn.lordicon.com/vuiggmtc.json"

                              trigger="hover"
                              style={{ width: "16px", height: "16px" }}>
                            </lord-icon>
                            Archive
                          </div>
                        </DropdownItem>
                        <DropdownItem onClick={() => handleDeleteOpen(item)} key="configurations" className='text-danger' color="danger">
                          <div className='flex items-center gap-2'>
                            <lord-icon
                              src="https://cdn.lordicon.com/wpyrrmcq.json"
                              colors="primary:#e1426c"
                              style={{ width: "16px", height: "16px" }}>
                            </lord-icon>
                            Delete
                          </div>
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </CardHeader>
                  <CardBody className='flex justify-between'>
                    {/* Make a limit of 4 tags and then +4 more */}
                    <div className='flex gap-4'>
                      {item?.payload?.user_tags?.length > 0 && item?.payload?.user_tags?.map((data) => (
                        <Chip
                          size='sm'
                          color={filteredData && filteredData.color}
                          variant="flat"
                        >
                          {data}
                        </Chip>))}
                    </div>
                    <div className=' mb-4'>
                      <p>{item?.payload?.description && StringLimit(item.payload.description, 190)}</p>
                    </div>
                  </CardBody>
                </Card>
              </div>
            })}
          </div>
          : <>{inputText !== '' ?
            <EmptyArray message={"No related documents found."} />
            :
            <EmptyArray message={"No documents inside collection folder. Click on upload documents to upload your documents."} />
          }</>
        }
      </>}
      <DeleteModal
        isOpen={isDeleteOpen}
        onOpenChange={onDeleteOpenChange}
        deleteData={""}
        setDeleteData={""}
        Collectionname={"document"}
        setLoader={setLoader}
        DeleteHandler={deleteHandler}
      />
    </div>
  )
}

export default DocumentsUploaded;