import React from 'react';
import { Button } from "@nextui-org/react";
import { useNavigate, useParams } from "react-router-dom";
import ToolsTable from './ToolsTable';

const Tools = () => {
    const navigate = useNavigate();
    const params=useParams();

    return (
        <div>
            <div className='text-3xl font-medium'>
                Tools
            </div>
            <div className='mt-4 leading-relaxed'>
                Seamlessly connect third-party applications to your data mesh using our integration tools. Enhance your data ecosystem by enabling smooth data flow, real-time synchronization, and interoperability between external apps and your existing data infrastructure.
            </div>
            <div className='flex mt-4 pb-8 justify-end'>
                <Button color='danger' onClick={() => navigate(`/${params._spaceid}/data-products/create/tool`)}>
                    + Create new tools
                </Button>
            </div>
            <ToolsTable />
        </div>
    )
}

export default Tools;