import { Progress } from "@nextui-org/react";
import React, { useMemo, useState } from "react";
import DataProductSelect from "./DataProductSelect";
import About from "./About";
import Config from "./Config";
import Redaction from "./Redaction";
import ContextCols from "./ContextCols";
import ContextJSON from "./ContextJSON";
import { useNavigate, useParams } from "react-router-dom";

const CreateDatabase = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [finalForm, setFinalForm] = useState({
    data_product: null,
    type: "",
    name: "",
    public : false,
    description: "",
    tags: [],
    domain: null,
  });
  const [currentStep, setCurrentStep] = useState(1);
  const navigate = useNavigate();
  const { _spaceid } = useParams();
  const [schema, setSchema] = useState(null);
  const handleNext = () => {
    setCurrentStep((prev) => prev + 1);
  };
  const handlePrev = () => {
    setCurrentStep((prev) => prev - 1);
  };
  const progressValue = useMemo(() => {
    let value;
    if (!selectedProduct) {
      return 0;
    }
    if (selectedProduct?.attributes?.Type === "collections") {
      value = 25 * (currentStep - 1);
    } else {
      value = 20 * (currentStep - 1);
    }
    return value;
  }, [currentStep, selectedProduct]);

  const renderPage = () => {
    switch (currentStep) {
      case 1:
        return (
          <DataProductSelect
            selectedProduct={selectedProduct}
            setForm={setFinalForm}
            form={finalForm}
            setSelectedProduct={setSelectedProduct}
            handleNext={handleNext}
          />
        );
      case 2:
        return (
          <About
            handleNext={handleNext}
            onPrevious={handlePrev}
            setFormData={setFinalForm}
            formData={finalForm}
            selectedProduct={selectedProduct}
          />
        );
      case 3:
        return (
          <Config
            setSchema={setSchema}
            formData={finalForm}
            selectedProduct={selectedProduct}
            setFormData={setFinalForm}
            handleNext={handleNext}
            handlePrev={handlePrev}
          />
        );
      case 4:
        return selectedProduct?.attributes?.Type === "collections" ? (
          <Redaction
            formData={finalForm}
            setFormData={setFinalForm}
            handleNext={handleNext}
            handlePrev={handlePrev}
          />
        ) : (
          <ContextCols
            formData={finalForm}
            handlePrev={handlePrev}
            handleNext={handleNext}
            schema={schema}
            selectedProduct={selectedProduct}
            setFormData={setFinalForm}
          />
        );
      case 5:
        return selectedProduct?.attributes?.Type === "collections" ? (
          <ContextJSON handlePrev={handlePrev} formData={finalForm} />
        ) : (
          <Redaction
            formData={finalForm}
            setFormData={setFinalForm}
            handleNext={handleNext}
            handlePrev={handlePrev}
          />
        );
      case 6:
        if (selectedProduct?.attributes?.Type === "collections") {
          navigate(`/${_spaceid}/data-products`);
          return;
        }
        return <ContextJSON handlePrev={handlePrev} formData={finalForm} />;
      default:
        return <></>;
    }
  };
  return (
    <div className="p-8">
      <div className="flex flex-row justify-center pt-8 pb-8">
        <Progress
          size="sm"
          radius="sm"
          classNames={{ base: "max-w-lg" }}
          color="secondary"
          value={progressValue}
        />
      </div>

      <div className="pt-8">{renderPage()}</div>
    </div>
  );
};

export default CreateDatabase;
