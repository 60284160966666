import React from 'react';
import { Tooltip, Button } from "@nextui-org/react";
import { toast } from "react-hot-toast";
import { ClipboardDocumentIcon, HandThumbUpIcon, HandThumbDownIcon } from "@heroicons/react/24/outline";

const AdditionalOptions = ({ data, text }) => {

    // COPY TEXT COMPONENT
    const copyTextHandler = () => {
        // Look for the occurrence of "filtered_documents" and exclude everything from that onward
        const filteredIndex = text.indexOf('{"filtered_documents"');
        
        // If "filtered_documents" is found, slice up to that point; otherwise, copy the entire text
        const textToCopy = filteredIndex !== -1 ? text.slice(0, filteredIndex).trim() : text;

        navigator.clipboard.writeText(textToCopy).then(() => {
            toast.success("Response copied successfully.");
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
    };

    // RETURN UI
    return (
        <div className="flex flex-row pl-12">
            <Tooltip content={"Like"}>
                <Button isIconOnly variant="light" size='sm'>
                    <HandThumbUpIcon className="size-4 text-transporent" />
                </Button>
            </Tooltip>
            <Tooltip content={"Copy"}>
                <Button onClick={copyTextHandler} size='sm' isIconOnly variant="light">
                    <ClipboardDocumentIcon className="size-4 text-transporent" />
                </Button>
            </Tooltip>
            <Tooltip content={"Dislike"}>
                <Button isIconOnly variant="light" size='sm'>
                    <HandThumbDownIcon className="size-4 text-transporent" />
                </Button>
            </Tooltip>
        </div>
    );
};

export default AdditionalOptions;
