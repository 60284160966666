import React, { useState } from "react";
import MonacoEditor from "@monaco-editor/react";

const MonacoJsonEditor = ({ value, setValue }) => {
  const handleEditorChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <MonacoEditor
        height="100%" // Makes the editor take full height
        language="json" // Set language mode to JSON
        value={value}
        onChange={handleEditorChange}
        theme="vs-dark" // Optional: Set the theme to dark mode
        options={{
          selectOnLineNumbers: true, // Optional: To allow selecting line numbers
          minimap: {
            enabled: false, // Optional: Disable minimap
          },
        }}
      />
    </div>
  );
};

export default MonacoJsonEditor;
