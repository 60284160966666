// src/reducers.js
import { SET_CREDITS, SET_DATA, SET_DOCUMENTS, SET_NAVIGATE, SET_PAGE, SET_SPACE } from './actions';

const initialState = {
    data: null,
};

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATA:
            return {
                ...state,
                data: action.payload,
            };

        case SET_SPACE: return {
            ...state,
            space: action.payload,
        }

        case SET_DOCUMENTS: return {
            ...state,
            documents: action.payload,
        }

        case SET_NAVIGATE: return {
            ...state,
            ActiveTab: action.payload
        }

        case SET_PAGE: return {
            ...state,
            pageRefresh: action.payload
        }

        case SET_CREDITS: return {
            ...state,
            credits: action.payload
        }

        default:
            return state;
    }

};

export default rootReducer;
