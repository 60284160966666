import React, { useEffect, useRef, useState } from "react";
import { Button, Input, Image } from "@nextui-org/react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {encryptedText,generateOtp,decryptedText} from "../../controllers/basicControllers.js";
import { NavigateSign } from "../../controllers/loginController";
import {
  sendCreateAccountOTP,
} from "../../controllers/strapiController.js";
import toast, { Toaster } from "react-hot-toast";
import CustomToast from "../../components/CustomToast.jsx";

const OTP = () => {
  const navigate = useNavigate();
  const [verificationCode, setVerificationCode] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);

  const inputRefs = useRef([]);
  const location = useLocation();
  const [Loader, setLoader] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const encryptedOTP = localStorage.getItem("user-otp");
  const encryptedEmail = searchParams.get("email");
  const encryptedPassword = searchParams.get("password");
  const encryptedLimit = searchParams.get("limit");
  const path = searchParams.get("path");

  const decryptedOTP = decryptedText(encryptedOTP);
  const [resendTimer, setResendTimer] = useState(25);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (index, value) => {
    const sanitizedValue = value.replace(/\D/, "");
    const updatedCode = [...verificationCode];
    updatedCode[index] = sanitizedValue;
    setVerificationCode(updatedCode);

    if (sanitizedValue !== "" && index < 5) {
      inputRefs.current[index + 1].focus();
    }

    if (/\D/.test(value)) {
      setShowErrorMessage(true);
    } else {
      setShowErrorMessage(false);
    }
  };

  const handleKeyDown = (index, e) => {
    if (
      (e.key === "Backspace" || e.key === "Delete") &&
      index > 0 &&
      verificationCode[index] === ""
    ) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").trim();
    if (/^\d{6}$/.test(pastedData)) {
      setVerificationCode(pastedData.split(""));
      inputRefs.current[5].focus();
    }
  };

  const email = sessionStorage.getItem("email");

  const verifyCode = async () => {
    setErrorMessage("");
    const OTP = verificationCode.join("");;

    if (OTP !== decryptedOTP) {
      setErrorMessage("Verification Code does not match.");
      return;
    }

    localStorage.removeItem("user-otp");
    const decryptedEmail = decryptedText(encryptedEmail);
    const decryptedPassword = decryptedText(encryptedPassword);

    // write the sign-up login.

    try {
      
      const navigate_route = await NavigateSign(
        decryptedEmail,
        decryptedPassword,
        path
      );
      
      if (navigate_route && navigate_route !== "") {
        setLoader(false);
        navigate(navigate_route);
      } else {
        setLoader(false);
      }

    } catch (error) {
      CustomToast("Oops!, something went wrong, please try after some time.","error")
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  useEffect(() => {
    let timerId;

    if (resendTimer > 0) {
      timerId = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(timerId);
    };
  }, [resendTimer]);

  const resendCode = async () => {
    const otpString = generateOtp();
    const encryptedOtp = encryptedText(otpString);
    const decryptedEmail = decryptedText(encryptedEmail);

    const url = `${process.env.REACT_APP_IP_ADDRESS}${location.pathname}${location.search}`;

    setResendTimer(25);

    const res = await sendCreateAccountOTP({
      to: decryptedEmail,
      url,
      otp: otpString,
    });

    localStorage.setItem("user-otp", encryptedOtp);

    toast.success(
      "Email sent successfully, Check your email to verify the OTP!"
    );

    // window.location.reload();
  };

  useEffect(() => {
    

    const decryptedEmail = decryptedText(encryptedEmail);
    const decryptedPassword = decryptedText(encryptedPassword);
    const decryptedLimit = decryptedText(encryptedLimit);

    
    const isValidLink =
      encryptedLimit &&
      encryptedPassword &&
      encryptedEmail &&
      decryptedLimit &&
      decryptedEmail &&
      decryptedPassword;

    const currDate = Date.now();
    const isValidTime =
      decryptedLimit !== null ? currDate <= +decryptedLimit : false;

    if (!isValidLink || !isValidTime || !encryptedOTP || !decryptedOTP) {
      navigate("/sign-up");
    }
  }, [encryptedEmail, encryptedLimit, encryptedPassword]);

  return (
    <>
      <Toaster position="bottom-right" reverseOrder={true} />
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="max-w-md min-h-[500px]">
          <Image
            width={50}
            alt="NextUI hero Image"
            src="Assets/haya-logo.png"
          />
          <h1 className="mb-1.5 mt-8 sm:text-left text-2xl tracking-[-0.16px] text-slate-12 font-bold ">
            Sign in to Haya Data Mesh
          </h1>
          <p className="text-small text-default-400 mb-4">
            A 6 digit PIN has been sent to{" "}
            <span className="font-bold">{email}</span>. Enter the PIN to verify
            your email address.
          </p>
          <div className="flex mb-4 items-center justify-between">
            {verificationCode.map((digit, index) => (
              <Input
                key={index}
                maxLength="1"
                value={digit}
                onChange={(e) => handleInputChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                onPaste={(e) => handlePaste(e)}
                ref={(el) => (inputRefs.current[index] = el)}
                className="mr-2 w-12"
                style={{ textAlign: 'center' }} 
              />
            ))}
          </div>
          {showErrorMessage ? (
            <div className="text-red-500 mb-2">Please enter numbers only.</div>
          ) : errorMessage !== "" ? (
            <div className="text-red-500 mb-2">{errorMessage}</div>
          ) : (
            ""
          )}
          <Button
            className="mb-5 w-full bg-black text-white mt-5 "
            onClick={verifyCode}
          >
            Verify your email address
          </Button>
          <div className="text-small text-default-400 mb-4">
            Resend your one time password (OTP){" "}
            {resendTimer > 0 ? (
              <strong>{formatTime(resendTimer)} seconds</strong>
            ) : (
              <span
                className=" text-blue-500 cursor-pointer underline"
                onClick={resendCode}
              >
                click here
              </span>
            )}
          </div>
          <div className="text-small text-default-400">
            By continuing, you acknowledge that you have read and understood,
            and agree to Audyt’s{" "}
            <span className=" text-blue-500">Terms & Conditions</span> and{" "}
            <span className=" text-blue-500">Privacy Policy.</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default OTP;
