import React, { useState, useEffect } from 'react';
import { Chip, Input, Textarea } from "@nextui-org/react";
import { colorPicker } from '../../controllers/basicControllers';


const EditCondition = ({ formData }) => {
    const [tags, setTags] = useState([]);

    useEffect(() => {
        setTags(formData && formData?.attributes?.Tags?.tags)
    }, [formData])


    const renderTags = () => {
        return tags && tags.map((tag, index) => (
            <Chip
            isDisabled={true}
                key={`${index}`}
                className="mr-2 mb-2"
                size='md'
                color={colorPicker(6)}
                variant="flat"
            >
                {tag}
            </Chip>
        ));
    };

    return (
        <div className="pt-4">
            <div className='cursor-not-allowed'>
            <Input
                isRequired
                isDisabled={true}
                key="collectionName"
                type="text"
                value={formData && formData.attributes.Name}
                label="Collection Name"
                labelPlacement="outside"
                placeholder="Enter your collection folder name"
            />
            </div>
            <div className='cursor-not-allowed'>
            <Textarea
                className={`pt-4 cursor-not-allowed`}
                key={"Description"}
                isDisabled={true}
                isRequired
                type="text"
                value={formData && formData.attributes.Description}
                label="Decription"
                labelPlacement={"outside"}
                placeholder="Enter your collection's Description"
            />
            </div>
            <div className="relative pt-2">
                <lable className="text-sm">Tags</lable>
                <div style={{ Background: "#f4f4f5" }} className={`border rounded-xl  flex flex-col border-divider p-2`}>
                    <input
                        isDisabled={true}
                        className={`bg-transparent border-0 rounded text-sm cursor-not-allowed `}
                        key={"tags"}
                        style={{ outline: 'none' }}
                        type="text"
                        variant="bordered"
                        labelPlacement={"outside"}
                        placeholder="Press enter to add multiple tags"
                    />
                    <div className="pt-4 flex flex-wrap gap-2 cursor-not-allowed">
                        {renderTags()}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default EditCondition;