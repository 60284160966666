import { Dropdown, DropdownMenu, DropdownItem, DropdownTrigger, Select, SelectItem, Chip, Avatar, Card, CardHeader, CardBody, Pagination, CheckboxGroup, Checkbox } from '@nextui-org/react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { data } from "../Connectors/ConnectorData";
import { ThemeProvider as NextThemesProvider } from 'next-themes';
import { DynamicSearch } from '../../controllers/vectordbController';
import { getOneData } from "../../controllers/strapiController";
import { StringLimit } from "../../controllers/basicControllers";
import FetchUserDataAndRenderAvatar from "../Collections/FetchUserDataAndRenderAvatar";
import { searchCard } from '../../components/Skeleton';
import { useSelector } from "react-redux";
import { colorPicker } from '../../controllers/basicControllers';
import CustomToast from '../../components/CustomToast';


const Search = () => {
  // STATE INITIALIZATION
  const params = useParams();
  const [SourceDoc, setSourceDoc] = useState([]);
  const [selectedTags, setSelectedSource] = useState([]);
  const [selected_Col, setSelected_Col] = useState(new Set([]));
  const [selected_Source, setSelected_Source] = useState([]);
  const [selected_Id, setSelected_Id] = useState([]);
  const [responseData, setResponseData] = useState();
  const [UserDetails, setUserDetails] = useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [loader, setLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const reduxdata = useSelector(state => state);
  const indexData = reduxdata && JSON.stringify(reduxdata, null, 2);
  const convertedData = JSON.parse(indexData);
  const vectorname = convertedData?.space?.[0]?.attributes?.VerctorDBCollection;
  const [FinalData, setFinalData] = useState([]);

  // SELECT COLLECTION OPTIONS ONCHANGE
  const handleSelectionChange = (e) => {
    setSelected_Col(new Set(e.target.value.split(",")))
  };

  // FILTER OF PERTICULAR USER AND DOCUMENT SOURCE
  const ArrayOfSource = Array.from(selected_Source)
  const ArrayOfAuthor = Array.from(selected_Id);

  // REMOVING DUPLICATE FILES FROM DOCUMENTS
  const mergeAndRemoveDuplicates = (responseData) => {
    const uniqueData = responseData && responseData
      .flatMap(array => array && array.map(item => item.data))
      .filter((item, index, self) =>
        index === self.findIndex(t => t && item && (t.id === item.id))
      );
    return uniqueData;
  }

  const ReturnedData = mergeAndRemoveDuplicates(responseData);

  // TAGS FILTER
  const Tagsresponse = FinalData && FinalData.map((item) => item.payload.user_tags);
  const SourceResponse = FinalData && FinalData.map((item) => item.payload.source);
  const uniqueArray = [...new Set(SourceResponse)];//to remove duplicate source

  const counts = Tagsresponse && Tagsresponse.reduce((acc, curr) => {
    curr && curr.forEach(item => {
      acc[item] = (acc[item] || 0) + 1;
    });
    return acc;
  }, {});

  const countsArray = Object.keys(counts).map(title => ({
    title: title,
    count: counts[title]
  }));

  // DOCUMENTS RESPONSE 
  useEffect(() => {
    setLoader(true);
    const fetchData = async () => {
      try {
        const sourceData = await DynamicSearch(vectorname, params._spaceid, params._params);
        if (sourceData) {
          setLoader(false);
          setSourceDoc(sourceData.result);
        } else {
          setSourceDoc([]);
          setLoader(false);
        }
      } catch (error) {
        CustomToast("Oops!, something went wrong, please try after some time.","error")
      }
    };
    fetchData();
  }, [params._params]);

  // REMOVE DUPLICATES FROM DOCUMENTS
  useEffect(() => {
    const filterUniqueEntries = (array) => {
      const uniqueEntries = [];
      const uniqueIds = new Set();

      array.forEach((item) => {
        if (!uniqueIds.has(item.payload.file_document_id)) {
          uniqueIds.add(item.payload.file_document_id);
          uniqueEntries.push(item);
        }
      });
      return uniqueEntries;
    };

    const filterData = () => {
      const uniqueDataArray = filterUniqueEntries(SourceDoc);

      const FilteredTags =
        uniqueDataArray &&
        uniqueDataArray.filter((item) => {
          const lowerUserTags = item.payload.user_tags.map((tag) => tag.toLowerCase());
          return (
            selectedTags &&
            selectedTags.every((tag) => lowerUserTags.includes(tag.toLowerCase()))
          );
        });

      const FirstFilteredData =
        selectedTags && selectedTags.length > 0 ? FilteredTags : uniqueDataArray;

      const ArrayOfCol = Array.from(selected_Col);

      const CollectionFilter =
        FirstFilteredData &&
        FirstFilteredData.filter((item) => {
          const StringifyData = item.payload.collection_id.map((col) => col);
          return (
            ArrayOfCol &&
            ArrayOfCol.every((collection) => StringifyData && StringifyData.includes(collection))
          );
        });

      const SecondFilteredData =
        CollectionFilter && CollectionFilter.length > 0 ? CollectionFilter : FirstFilteredData;

      const FilteredData =
        SecondFilteredData &&
        SecondFilteredData.filter((data) => {
          const LowerCase = data && data.payload.source && data.payload.source.toLowerCase();

          return (
            ArrayOfSource && ArrayOfSource.includes(LowerCase)
          );
        });

      const SourceFilter =
        FilteredData && FilteredData.length > 0 ? FilteredData : SecondFilteredData;

      const FilterAuthor =
        SourceFilter &&
        SourceFilter.filter((data) => {
          const LowerCase = data && data.payload.user_id

          return (
            ArrayOfAuthor && ArrayOfAuthor.includes(LowerCase)
          );
        });

      const FinalData =
        FilterAuthor && FilterAuthor.length > 0 ? FilterAuthor : SourceFilter;

      return FinalData;
    };

    let filterResponse = filterData();
    setFinalData(filterResponse);
  }, [SourceDoc, selectedTags, selected_Col, selected_Source, selected_Id, params._params]);
  // selectedTags, SourceDoc, selected_Col,ArrayOfAuthor,ArrayOfSource, selected_Source, selected_Id
  useEffect(() => {
    const fetchData = async () => {
      try {
        const responses = FinalData && await Promise.all(
          FinalData &&
          FinalData.map(async (element) => {
            const NewData = element.payload.collection_id;
            const collectiondata = NewData && await Promise.all(
              NewData.map(async (data) => {
                const respose = await getOneData('collections', data);
                return respose;
              })
            );
            return collectiondata;
          })
        );
        setResponseData(responses);
      } catch (error) {
        CustomToast("Oops!, something went wrong, please try after some time.","error")
      }
    };
    fetchData();
  }, [FinalData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responses = FinalData && await Promise.all(
          FinalData &&
          FinalData.map(async (element) => {
            const responseData = await getOneData('users', element.payload.user_id);
            return responseData;
          })
        );
        setUserDetails(responses);
      } catch (error) {
        CustomToast("Oops!, something went wrong, please try after some time.","error")
      }
    };
    fetchData();
  }, [FinalData]);


  // Assuming your array is named 'dataArray'
  const uniqueDataArray = UserDetails && UserDetails.filter((item, index, self) =>
    index === self.findIndex((t) => (
      t.id === item.id && t.username === item.username // Add other properties if needed
    ))
  );

  // Calculate indexes for pagination
  const recordsPerPage = 10;
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = FinalData && FinalData.slice(indexOfFirstRecord, indexOfLastRecord);

  return (
    <div>
      <div className='flex min-w-full gap-8 items-center'>
        <Select
          isOpen={isOpen}
          onOpenChange={(open) => open !== isOpen && setIsOpen(open)}
          placeholder="Select authors"
          aria-label="Static Actions"
          selectionMode="multiple"
          startContent={
            <lord-icon
              src="https://cdn.lordicon.com/hrjifpbq.json"

              style={{ width: "20px", height: "20px" }}>
            </lord-icon>
          }
          classNames={{ base: "max-w-44" }}
          onChange={(e) => setSelected_Id(new Set(e.target.value.split(",")))}>
          {uniqueDataArray && uniqueDataArray.map((user) => (
            <SelectItem key={user.id} value={user.id} textValue={user.firstname}>
              <div className="flex gap-2 items-center">
                <FetchUserDataAndRenderAvatar userId={user.id} />
                {/* <Avatar alt={user.firstname} className="flex-shrink-0" size="sm" src={user && user.Picture !== null ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${user.Picture.url}` : ""} /> */}
                <div className="flex flex-col">
                  <span className="text-small">{user.firstname}</span>
                  <span className="text-tiny text-default-400">{user.email}</span>
                </div>
              </div>
            </SelectItem>
          ))}
        </Select>
        <Select
          selectionMode="multiple"
          placeholder="Collections"
          aria-label="Collections"
          startContent={
            <lord-icon
              src="https://cdn.lordicon.com/zyzoecaw.json"

              style={{ width: "20px", height: "20px" }}>
            </lord-icon>
          }
          classNames={{ base: "max-w-44" }}
          onChange={handleSelectionChange}>
          {ReturnedData && ReturnedData.map((data) => {
            return <SelectItem key={data.id} aria-label="Collections" textValue={data.attributes.Name} value={data.id}>{data.attributes.Name}</SelectItem>
          })}
        </Select>
        <Select
          items={FinalData}
          placeholder="Select sources"
          aria-label="Select sources"
          selectionMode="multiple"
          value={selected_Source}
          onChange={(e) => setSelected_Source(new Set(e.target.value.split(",")))}
          classNames={{ base: "max-w-44" }}
          startContent={
            <lord-icon
              src="https://cdn.lordicon.com/jkzgajyr.json"
              trigger="hover"

              style={{ width: "20px", height: "20px" }}>
            </lord-icon>
          }
        >
          {uniqueArray && uniqueArray.map((item) => {
            const ComparedArray = data && data.find((arr) => arr.label && item && (arr.label.toLowerCase() === item.toLowerCase()))
            return <SelectItem key={item} value={ComparedArray.label} textValue={ComparedArray.label}>
              <div className='flex gap-2 items-center'>
                <Avatar alt={ComparedArray.name} className="flex-shrink-0" size="sm" src={ComparedArray.avatar} />
                {ComparedArray.label}
              </div>
            </SelectItem>
          })}
        </Select>
      </div>
      <div className='mt-8 flex justify-between'>
        <p>Searched results: "{params._params}"</p>
        <p className="text-md">Found {FinalData.length > 0 ? FinalData.length > 10 ? FinalData.length : `0${FinalData.length}` : 0} Results</p>
      </div>
      {loader ? searchCard() : <>
        {currentRecords && currentRecords.length > 0 ?
          <div className='flex flex-row items-start justify-between'>
            <div className="flex flex-col">
              {/* Search Result Cards */}
              {currentRecords && currentRecords.map((item, index) => {
                const ComparedArray = data && data.find((arr) => arr.label && item && (arr.label.toLowerCase() === item.payload.source.toLowerCase()))
                return <div className='mt-8'>
                  <Card className='w-[900px] card-color border border-divider' shadow='none'>
                    <CardHeader className="flex gap-3 justify-between px-4">
                      <div className='flex gap-4 items-center'>
                        <Avatar alt={ComparedArray.name} radius="sm" className="flex-shrink-0" size="sm" src={ComparedArray.avatar} />
                        <div className='flex flex-col'>
                          <p className="text-md">{item.payload.document_title}</p>
                          <p className="text-small text-default-500">{item.payload.source && item.payload.source?.charAt(0).toUpperCase() + item.payload.source?.slice(1)}</p>
                        </div>
                      </div>
                      <div>
                        <Dropdown placement="bottom-end">
                          <DropdownTrigger>
                            <div className='flex items-center justify-evenly cursor-pointer'>
                              <span className='text-md font-bold text-slate-500'>...</span>
                            </div>
                          </DropdownTrigger>
                          <DropdownMenu aria-label="Profile Actions" variant="flat">
                            <DropdownItem key="edit">
                              <div className='flex items-center gap-2'>
                                <lord-icon
                                  src="https://cdn.lordicon.com/pflszboa.json"
                    
                                  style={{ width: "16px", height: "16px" }}>
                                </lord-icon>
                                Edit
                              </div>
                            </DropdownItem>
                            <DropdownItem key="system">
                              <div className='flex items-center gap-2'>
                                <lord-icon
                                  src="https://cdn.lordicon.com/vuiggmtc.json"
                                  trigger="hover"
                    
                                  style={{ width: "16px", height: "16px" }}>
                                </lord-icon>
                                Archive
                              </div>
                            </DropdownItem>
                            <DropdownItem key="configurations" className='text-danger' color="danger">
                              <div className='flex items-center gap-2'>
                                <lord-icon
                                  src="https://cdn.lordicon.com/wpyrrmcq.json"
                                  colors="primary:#e1426c"
                                  style={{ width: "16px", height: "16px" }}>
                                </lord-icon>
                                Delete
                              </div>
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </CardHeader>
                    <CardBody className='px-4'>
                      <>
                        <div className='flex pt-2 gap-3'>
                          {item && item.payload.user_tags.slice(0, 3).map((tag, index) => (
                            <Chip key={index} size='sm' color={colorPicker(item?.payload?.file_document_id)} variant='flat'>
                              {tag}
                            </Chip>
                          ))}
                          {item && item.payload.user_tags && item.payload.user_tags.length > 3 && (
                            <Chip key={index} size='sm' color={colorPicker(item?.payload?.file_document_id)} variant='flat'>
                              {item.payload.user_tags.length - 3} more
                            </Chip>
                          )}
                        </div>
                        <div className='mt-4 text-sm'>
                          {item.payload.description && StringLimit(item.payload.description, 100)}
                        </div>
                      </>
                    </CardBody>
                  </Card>

                </div>
              })}
              <div className='mt-8 flex items-center justify-center'>
                <Pagination loop showControls
                  color="secondary"
                  page={currentPage}
                  onChange={setCurrentPage}
                  total={FinalData && Math.ceil(FinalData.length / recordsPerPage)}
                  initialPage={1} />
              </div>
            </div>
            {/* Filter */}
            <div className='mt-8'>
              <Card className="max-w-[200px] card-color border border-divider" shadow='none'>
                <CardHeader className="flex gap-3 ">
                  <lord-icon
                    src="https://cdn.lordicon.com/prjooket.json"
                    trigger="hover"
                    target="#Tags"
                    state="morph-home-2"
      
                    style={{ width: "24px", height: "24px" }}>
                  </lord-icon>
                  <div>
                    <div className='text-lg'>Tags</div>
                  </div>
                </CardHeader>
                <CardBody className=' p-8'>
                  <CheckboxGroup
                    value={selectedTags}
                    onChange={setSelectedSource}
                  >
                    {countsArray && countsArray.map((data, index) => {
                      return <Checkbox color='danger' value={data.title}
                      >{data.title}</Checkbox>
                    })}
                  </CheckboxGroup>
                </CardBody>
              </Card>
            </div>
          </div >
          : <div className="flex flex-row items-center justify-center pt-24 text-slate-400">
            No search results found. Try again later.
          </div>}
      </>}
    </div >
  )
}

export default Search;