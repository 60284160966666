import React, { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import { get_one_document } from "../../controllers/vectordbController";
import { Image, Chip, Card, Link, Dropdown, Button, DropdownTrigger, ModalHeader, Input, ModalBody, useDisclosure, NextUIProvider, DropdownItem, DropdownMenu, CardHeader, Divider, CardBody, CardFooter, Avatar, Tooltip } from "@nextui-org/react";
import { getAllData, getOneData } from "../../controllers/strapiController";
import { StringLimit, getTimeDifference } from "../../controllers/basicControllers";
import { data } from "../../pages/Connectors/ConnectorData";
import { ThemeProvider as NextThemesProvider } from 'next-themes';
import ModalPopup from "../Collections/ModalPopup";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import hayalogo from "../../Images/haya-logo.png";
import Loading from "../../components/Loader/Loading";
import { stringCreater } from '../../controllers/basicControllers';
import { useSelector } from "react-redux";
import Tags from '../../components/Tags';
import { toast } from "react-hot-toast";
import { colorPicker } from "../../controllers/basicControllers";

const Documents = () => {
    // STATE INITIALIZATION
    const params = useParams();
    const [documents, setDocuments] = useState([]);
    const [tagInput, setTagInput] = useState('');
    const [tagsData, setTagsData] = useState();
    const [tags, setTags] = useState([]);
    const [Loader, setLoader] = useState(true);
    const [Validatetags, setValidatetags] = useState('');
    const [showSummarize, setShowSummarize] = useState(false);
    const [userData, setUserdata] = useState();
    const [users, setUsers] = useState([]);
    const ref = useRef();
    const UserDetails = JSON.parse(sessionStorage.getItem("userData"));
    const UserID = UserDetails && UserDetails.user && UserDetails.user.id;
    const { isOpen: isModalOpen, onOpen: onModalOpen, onOpenChange: onModalOpenChange } = useDisclosure();
    const [formData, setFormdata] = useState({ Title: '', Tags: tags });
    const reduxdata = useSelector(state => state);
    const indexData = reduxdata && JSON.stringify(reduxdata, null, 2);
    const convertedData = JSON.parse(indexData);
    const vectorname = convertedData?.space?.[0]?.attributes?.VerctorDBCollection;
    const appName = convertedData?.space?.[0]?.attributes?.SpaceName;


    const toggleSummarize = () => {
        setShowSummarize(!showSummarize);
    };

    // DOCUMENT SUMMERY 
    const SummarizeComponent = () => {
        return (
            <div>
                <Card className="max-w-1/2 min-h-[450px] border border-divider p-8" shadow='none'>
                    <CardBody>
                        <div className='flex gap-4 items-center'>
                            <Image radius='full' width={40} alt="NextUI hero Image" src={hayalogo} />
                            <p>Haya</p>
                        </div>
                        <div className='mt-8'>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper mollis sem ac lacinia. Integer tempus neque at varius imperdiet. Aenean sollicitudin venenatis molestie. Nulla vel neque pharetra, semper purus ultricies, consectetur nisl. Cras ac lacinia ex. Cras pellentesque mauris massa. In sit amet lectus id magna maximus sagittis. Proin tristique neque tellus, sit amet ultrices nisi rutrum ut. Etiam sed fringilla orci. Nunc aliquet, justo eu tincidunt viverra, diam justo sagittis nibh, vehicula suscipit orci urna eu ligula. Cras vel elit non ligula rhoncus tempor. Pellentesque eu odio nec massa faucibus cursus eget a urna. Ut eu finibus ligula, vel egestas lectus. Nam egestas mi consequat gravida hendrerit. Maecenas ornare, enim vitae accumsan eleifend, dolor sapien pretium dui, eu aliquet nibh nulla nec libero. Aliquam in turpis leo.
                                Vivamus in porta sapien. Morbi varius id lectus ut luctus. Vivamus lobortis libero sit amet lorem consequat, quis posuere nibh lobortis. Proin fermentum felis eget euismod molestie. Cras et metus malesuada, rutrum sapien sed, bibendum orci. Nam non faucibus risus. Sed nec sem ultrices, dignissim augue ut, tristique massa. Integer euismod sapien vitae vulputate tincidunt.
                            </p>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <div className='flex gap-4'>
                            <Tooltip content='Copy Summary' size='sm'>
                                <Button isIconOnly variant='light' color='warning' size='sm'>
                                    <FontAwesomeIcon icon={faCopy} />
                                </Button>
                            </Tooltip>
                            <Tooltip content='Like summary' size='sm'>
                                <Button isIconOnly color='success' variant='flat' size='sm'>
                                    <FontAwesomeIcon icon={faThumbsUp} />
                                </Button>
                            </Tooltip>
                            <Tooltip content='Dislike summary' size='sm'>
                                <Button isIconOnly variant='flat' color='danger' size='sm'>
                                    <FontAwesomeIcon icon={faThumbsDown} />
                                </Button>
                            </Tooltip>
                        </div>
                    </CardFooter>
                </Card>
            </div>
        );
    };

    // API TO RENDER LIST OF DOCUMENTS
    useEffect(() => {
        get_one_document(vectorname, params._id, appName)
            .then((data) => { setLoader(false); setDocuments(data) })
            .catch((error) => { setLoader(false) })

        getAllData("tags")
            .then((data) => {
                setTagsData(data.data)
                setLoader(false)
            })
            .catch((error) => toast.error("Oops!, something went wrong, please try after some time."))
    }, [params._id, Loader])

    // FILTER THE LIST OF DOCUMENTS
    const filterUniqueEntries = (array) => {
        const uniqueEntries = [];
        const uniqueIds = new Set();

        array && array.forEach(item => {
            if (!uniqueIds.has(item.payload.file_document_id)) {
                uniqueIds.add(item.payload.file_document_id);
                uniqueEntries.push(item);
            }
        });
        return uniqueEntries;
    }

    const uniqueDataArray = filterUniqueEntries(documents);

    const FilteredData = uniqueDataArray && uniqueDataArray.find((data) => data.payload.file_document_id === params._id);
    const UploadAvatar = data && FilteredData && data.find((arr) => arr.value === FilteredData.payload.source);
    const CollectionArray = FilteredData && FilteredData.payload.collection;
    const TimeData = getTimeDifference(params._id)

    useEffect(() => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
    }, [tagInput])

    // API TO RENDER USER
    useEffect(() => {
        if (FilteredData) {
            getOneData("users", FilteredData.payload.user_id)
                .then((data) => setUserdata(data))
                .catch((error) => {
                    toast.error("Something went wrong. Please try again after some time.")
                })
        }
    }, [FilteredData])

    // RENDERING LIST OF USERS
    useEffect(() => {
        getAllData("users")
            .then((data) => setUsers(data))
            .catch((error) => toast.error("Oops!, something went wrong, please try after some time."))
    }, [])

    // INITIAL STATE UPDATON
    const HandleClick = (data) => {
        setFormdata({
            Title: data.payload.document_title,
            Tags: data.payload.user_tags
        })
        setTags(data.payload.user_tags)
        onModalOpen()
    }

    const userName = users && FilteredData && users.find((data) => JSON.stringify(data.id) === FilteredData.payload.user_id)

    // MODAL TO EDIT DOCUMENT
    const EditModal = () => {
        return <>
            <ModalHeader className="-mt-4">Edit Document</ModalHeader>
            <ModalBody>
                <Input
                    isRequired
                    key="outside"
                    type="text"
                    label="Document Title"
                    value={formData.Title}
                    onChange={(e) => setFormdata({ ...formData, Title: e.target.value })}
                    labelPlacement="outside"
                    placeholder="Enter your collection folder name"
                />
                <Tags

                    tagsData={tagsData}
                    setTagsData={setTagsData}
                    tagInput={tagInput}
                    setTagInput={setTagInput}
                    tags={tags}
                    validatetags={Validatetags}
                    setTags={setTags}
                    setValidatetags={setValidatetags}
                    setFormdata={setFormdata}
                    formData={formData}
                    background={"bg-transparent"}
                />
            </ModalBody>
        </>
    }

    // fOOTER FOR EDIT 
    const EditFooter = (onClose) => {
        return <>
            <Button color="default" variant="flat" onClick={() => { setFormdata({}); onClose() }}>Cancel</Button>
            <Button color="secondary" >Create </Button>
        </>
    }

    // LOADER
    if (Loader) {
        return <div className={`absolute z-[100]  inset-0 h-screen w-screen bg-white`}>
            <Loading />
        </div>
    }

    if (FilteredData) {
        return (
            <div className="flex flex-col p-8">
                <div className='flex justify-between items-center gap-3'>
                    <div className="flex flex-row gap-3 items-center">
                        <Image
                            alt="nextui logo"
                            height={40}
                            radius="sm"
                            src={UploadAvatar && UploadAvatar.avatar}
                            width={40}
                        />
                        <p className="text-3xl font-medium">{FilteredData && FilteredData.payload.document_title}</p>
                    </div>
                    <div>
                        <NextUIProvider>
                            <Dropdown placement='bottom-end' className={``}>
                                <DropdownTrigger>
                                    <p className="text-3xl cursor-pointer">...</p>
                                </DropdownTrigger>
                                <NextThemesProvider>
                                    <DropdownMenu className='-mx-4'>
                                        <DropdownItem>
                                            <p className='cursor-pointer'>View Source Document</p>
                                        </DropdownItem>
                                        <DropdownItem onClick={() => HandleClick(FilteredData)}>
                                            <p className='cursor-pointer'>Edit</p>
                                        </DropdownItem>
                                        <DropdownItem color='danger' variant='flat'>
                                            <p className='cursor-pointer'>Delete</p>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </NextThemesProvider>
                            </Dropdown>
                        </NextUIProvider>
                    </div>
                </div>
                <div className="flex flex-wrap gap-3 pt-8">
                    {FilteredData && FilteredData.payload.user_tags && FilteredData.payload.user_tags.map((item, index) => {
                        return <Chip key={item} color={colorPicker(5)} variant="flat" size='sm'>{item}</Chip>
                    })}
                </div>
                <p className="text-md pt-8">{FilteredData && FilteredData.payload.document_description}</p>
                <div className='pt-8 flex items-center gap-4'>
                    <Avatar size='sm' color='danger' name='S' />
                    <div className='text-sm text-warmGray-500'>
                        {userName && stringCreater(userName.firstname, userName.email)} • Uploaded {TimeData}
                    </div>
                </div>
                <div className='mt-8'>
                {/* <Button onClick={()=>{toggleSummarize()}}>Summarize this document</Button> */}
                </div>

                {/* Show's summary of the document */}
                <div className='mt-8'>
                    {showSummarize && <SummarizeComponent />}
                </div>

                <div className="mt-8 max-w-screen-lg m-auto w-full">
                    <Card className="max-w-1/2 h-[600px] border border-divider" shadow='none'>
                        {(FilteredData && FilteredData.payload && FilteredData.payload.source !== "csv") ||
                            (FilteredData && FilteredData.payload && FilteredData.payload.source !== "xslx") ? <iframe
                            src={FilteredData?.payload?.document_url}
                            title="PDF Viewer"
                            className="w-full h-full" />
                            : <div className="h-full flex flex-col justify-center items-center gap-2">
                                <p>Source document cannot be viewed</p>
                                <p>Click on view source to open the document externally.</p>
                                <Button color="secondary" size="sm" onPress={() => window.open(FilteredData.payload.document_url)}>View Source Document</Button>
                            </div>}
                    </Card>
                </div>
                <div className="pt-8">
                    <div className='flex items-center gap-4'>
                        <p className="text-lg font-medium">Similar documents</p>
                    </div>
                    <Divider className='mt-4' />
                    <div className='mt-12 flex flex-wrap gap-8'>
                        {uniqueDataArray && uniqueDataArray.map((item, index) => {
                            const filteredData = data && data.find((arr) => arr.value === item.payload.source);
                            return <div key={`${index}`}>
                                <Card className="max-w-[550px] border border-divider" shadow='none'>
                                    <CardHeader className="flex gap-3 justify-between px-4">
                                        <div className='flex justify-between gap-3'>
                                            <Image
                                                alt="nextui logo"
                                                height={40}
                                                radius="sm"
                                                src={filteredData && filteredData.avatar}
                                                width={40}
                                            />
                                            <div className="flex flex-col">
                                                <p className="text-md">{item.payload.document_title}</p>
                                                <p className="text-small text-default-500">{item.source && item.source?.charAt(0).toUpperCase() + item.source.slice(1, 10)}</p>
                                            </div>
                                        </div>
                                        <Dropdown placement="bottom-end">
                                            <DropdownTrigger>
                                                <div className='flex items-center justify-evenly cursor-pointer'>
                                                    <span className='text-md font-bold text-slate-500'>...</span>
                                                </div>
                                            </DropdownTrigger>
                                            <DropdownMenu aria-label="Profile Actions" variant="flat">
                                                <DropdownItem key="edit">
                                                    <div className='flex items-center gap-2'>
                                                        <lord-icon
                                                            src="https://cdn.lordicon.com/pflszboa.json"

                                                            style={{ width: "16px", height: "16px" }}>
                                                        </lord-icon>
                                                        Edit
                                                    </div>
                                                </DropdownItem>
                                                <DropdownItem key="system">
                                                    <div className='flex items-center gap-2'>
                                                        <lord-icon
                                                            src="https://cdn.lordicon.com/vuiggmtc.json"

                                                            trigger="hover"
                                                            style={{ width: "16px", height: "16px" }}>
                                                        </lord-icon>
                                                        Archive
                                                    </div>
                                                </DropdownItem>
                                                <DropdownItem key="configurations" className='text-danger' color="danger">
                                                    <div className='flex items-center gap-2'>
                                                        <lord-icon
                                                            src="https://cdn.lordicon.com/wpyrrmcq.json"
                                                            colors="primary:#e1426c"
                                                            style={{ width: "16px", height: "16px" }}>
                                                        </lord-icon>
                                                        Delete
                                                    </div>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </CardHeader>
                                    <Divider />
                                    <CardBody>
                                        <p>{item && item.payload.description && StringLimit(item.payload.description, 90)}</p>
                                        <div className='flex gap-4 pt-6'>
                                            {item && item.payload.user_tags.length > 0 && item.payload.user_tags.map((data) => (
                                                <Chip color='default' variant="flat" key={data}> {data} </Chip>))}
                                        </div>
                                    </CardBody>
                                    <Divider />
                                    <CardFooter>
                                        <Link isExternal showAnchorIcon>
                                            Visit source document
                                        </Link>
                                    </CardFooter>
                                </Card>
                            </div>
                        })}
                    </div>
                </div>
                <ModalPopup
                    isOpen={isModalOpen}
                    size="xl"
                    onOpenChange={onModalOpenChange}
                    ModalBodyData={EditModal}
                    footer={EditFooter}
                    scrollBehavior="inside"
                />
            </div>
        )
    }
}

export default Documents;