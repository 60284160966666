import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CommonCollectionFilterApi } from "../../controllers/strapiController";
import RGL, { WidthProvider } from "react-grid-layout";
import ShowChart from "./ShowChart";
import { getItemLayout } from "../../Utils/dashbaord.utils";

import "../../draglayout.css";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
export const defaultLayoutProps = {
  items: 5,
  rowHeight: 30,
  preventCollision: false,
  cols: 12,
};

const ReactGridLayout = WidthProvider(RGL);

const defaultProps = {
  isDraggable: false,
  isResizable: false,
  ...defaultLayoutProps,
};

const SingleDashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [layoutPosition, setLayoutPosition] = useState([]);
  const [dashboardDetails, setDashboardDetails] = useState(null);
  const { _dashboardid, _spaceid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    CommonCollectionFilterApi(
      "dashboards",
      `filters[id][$eq]=${_dashboardid}`
    ).then((data) => {
      const layoutDetails = [];
      const chartinfo = data?.data?.[0]?.attributes?.chartsInfo || [];
      chartinfo.forEach((chart) => {
        layoutDetails.push(chart?.poisitionInfo);
      });
      setLayoutPosition(layoutDetails);
      setDashboardDetails(data?.data?.[0]);
      setIsLoading(false);
    });
  }, []);

  const chartsInfo = dashboardDetails?.attributes?.chartsInfo;

  console.log(chartsInfo);
  
  return (
    <div className="p-8">
      <div className="flex items-center justify-between text-3xl font-medium">
        <span>{dashboardDetails?.attributes?.name}</span>
        <div className="flex items-center gap-4 mr-4">
          <PencilSquareIcon
            className="size-6 cursor-pointer hover:opacity-55"
            onClick={() => navigate("edit")}
          />
        </div>
      </div>
      <div className="my-4 leading-relaxed">
        {dashboardDetails?.attributes?.description}
      </div>

      <div className="flex flex-grap items-start w-full min-h-full">
        {!isLoading && (
          <ReactGridLayout
            {...defaultProps}
            onLayoutChange={(newLayout) => {
              setLayoutPosition(newLayout);
            }}
          >
            {chartsInfo?.map((data) => {
              const layoutInfo = getItemLayout(layoutPosition, data.id);

              return (
                <div
                  key={layoutInfo?.i}
                  data-grid={layoutInfo}
                  className="rounded-sm"
                >
                  <ShowChart data={data} />
                </div>
              );
            })}
          </ReactGridLayout>
        )}
      </div>
    </div>
  );
};

export default SingleDashboard;

/*
-> get the dbConfig, get the result,
->

*/
