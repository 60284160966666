import React, { useState, useEffect } from "react";
import CreateSpace from "./CreateSpace";
import SelectOptions from "./SelectOptions";
import Invitedusers from "./Invitedusers";
import { Button } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { getOneData } from "../../controllers/strapiController";
import SubNavbar from "../../components/SubNavbar";
import Loading from "../../components/Loader/Loading";

const Wizard = () => {
  // STATE INITIALIZATION
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    spaceName: "",
    type: "",
    users: [],
  });
  const [userInfo, setUserInfo] = useState(null);
  const navigate = useNavigate();
  const userDetails = JSON.parse(sessionStorage.getItem("userData"));
  const userID = userDetails && userDetails.user && userDetails.user.id;
  const [loader, setLoader] = useState(false);

  // API RESPONSE
  useEffect(() => {
    getOneData("users", userID).then((data) => setUserInfo(data));
  }, [userID]);

  // WIZARD NEXT BUTTON FUNCTION
  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  // WIZARD PREVIOUS BUTTON FUNCTION
  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  // SWITCH CASE FOR WIZARD
  const renderPage = () => {
    switch (step) {
      case 1:
        return (
          <CreateSpace
            onNext={handleNext}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 2:
        return (
          <SelectOptions
            onNext={handleNext}
            onPrevious={handlePrevious}
            setFormData={setFormData}
            formData={formData}
          />
        );
      case 3:
        return (
          <Invitedusers
            formData={formData}
            onPrevious={handlePrevious}
            setFormData={setFormData}
            userID={userID}
            setLoader={setLoader}
          />
        );
      default:
        return null;
    }
  };

  // LOADING
  if (loader) {
    return (
      <div
        className={`absolute z-[100]  inset-0 h-screen w-screen ${"bg-white"}`}
      >
        <Loading />
      </div>
    );
  }

  // RETURN UI
  return (
    <div className=" flex flex-col h-screen mx-40">
      <div className="w-full">
        <SubNavbar />
      </div>
      <div className="flex h-full items-center">
        <main className="w-[50%]">
          {step === 1 && (
            <div className="flex items-center gap-4 font-semibold">
              <Button
                onClick={() => {
                  if (step === 1) {
                    return navigate(-1);
                  } else {
                    handlePrevious();
                  }
                }}
                isIconOnly
                color="danger"
                radius="full"
                aria-label="Like"
                startContent={<FontAwesomeIcon icon={faArrowLeft} />}
              ></Button>
              Go back
            </div>
          )}
          <p className="pt-12 sm:text-left text-lg text-slate-11 font-normal">
            Hello{" "}
            {userInfo && userInfo.firstname
              ? userInfo.firstname
              : userInfo && userInfo.email}
            , let's get you started
          </p>
          {renderPage()}
        </main>
        <div className="flex-1 pl-12 py-20 h-full">
          {step === 1 ? (
            <div className="border h-full w-full rounded-3xl bg-red-100"></div>
          ) : step === 2 ? (
            <div className="border h-full w-full rounded-3xl bg-blue-100"></div>
          ) : (
            <div className="border h-full w-full rounded-3xl bg-green"></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Wizard;
