import React, { useEffect, useState } from 'react';
import { Button } from '@nextui-org/react';
import { getAllData } from "../../controllers/strapiController";
import { ErrorHandler } from "../../Utils/ErrorHandler";
import NotFound from "../../components/NotFound";
import ServerIssue from "../../components/ServerIssue";
import Forbbiden from "../../components/Forbbiden";
import UnAuthorized from "../../components/UnAuthorized";
import TemplateCard from '../Templates/TemplateCard';

const SelectOptions = ({ onPrevious, onNext, setFormData, formData }) => {
    const [templates, setTemplates] = useState([]);
    const [notFound, setNotFound] = useState(false);
    const [forbidden, setForbidden] = useState(false);
    const [serverIssue, setServerIssue] = useState(false);
    const [authorized, setAuthorized] = useState(false);
    const [selectedCardId, setSelectedCardId] = useState(null); // State for selected card

    useEffect(() => {
        getAllData("templates")
            .then((data) => {
                if (data?.error) {
                    ErrorHandler(data.error, { setNotFound, setForbidden, setServerIssue, setAuthorized });
                } else {
                    setTemplates(data?.data);
                }
            })
            .catch(() => setServerIssue(true));
    }, []);

    const handleCardSelect = (data) => {
        setSelectedCardId(data?.id);
        setFormData((prev) => ({ ...prev, templateSelected: data }))
    };

    if (notFound) return <NotFound />;
    if (serverIssue) return <ServerIssue />;
    if (forbidden) return <Forbbiden />;
    if (authorized) return <UnAuthorized />;

    return (
        <div className='h-full'>
            <div>
                <h1 className='sm:text-left text-2xl tracking-[-0.16px] text-slate-12 font-medium'>
                    Choose a <span className='text-purple leading-snug'> template </span>
                </h1>
            </div>
            <div className='flex-1 overflow-y-auto grid md:grid-cols-2 gap-8 pt-8 sm:grid-cols-1 lg:grid-cols-2'>
                {templates?.map((data, index) => (
                    <TemplateCard
                        key={data.id}
                        data={data}

                        isSelected={data.id === selectedCardId}
                        onSelect={() => handleCardSelect(data)}
                    />
                ))}
            </div>
            <div className="pt-8 pr-2 flex flex-row gap-4 justify-end">
                <Button color="secondary" variant="bordered" onClick={onPrevious}>Previous</Button>
                <Button variant="flat" color="secondary" onClick={onNext}>Next</Button>
            </div>
        </div>
    )
}

export default SelectOptions;
