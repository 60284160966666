import React, { useState } from 'react';
import { Input, Textarea } from "@nextui-org/react";
import ButtonComponent from '../../NextUI/Button';
import SwitchComponent from '../../NextUI/Switch';
import { validateTemplateForm } from '../../Utils/TemplateValidation';

const TemplateForm = ({ setFormData, onNext, formData }) => {
  const [errors, setErrors] = useState({});
  const commonProps = {
    size: 'md',
    placeholder: ' ',
    labelPlacement: 'outside',
    isRequired: true
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "Switch") {
      setFormData((prev) => ({ ...prev, form: { ...prev.form, [name]: e.target.checked } }))
    } else {
      setFormData((prev) => ({ ...prev, form: { ...prev.form, [name]: value } }))
    }
  }

  const handleNext = () => {
    const validationErrors = validateTemplateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      onNext();
    }
  }

  return (
    <div>
      <form className='flex flex-col gap-8'>
        <Input {...commonProps} label="Name" name="Name" placeholder="Enter the name of the template." value={formData?.form?.Name} onChange={handleChange} isInvalid={formData?.form?.Name === '' || formData?.form?.Name?.length < 3 ? !!errors?.Name : ''} errorMessage={errors?.Name} />
        <div className='flex flex-col gap-1'>
          <label className='text-sm'>Space Type</label>
          <SwitchComponent color={"secondary"} defaultSelected={false} size="sm" name="Switch" onChange={handleChange} />
          {formData?.form?.Switch && <p className='text-xs text-default-400'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus, perspiciatis.</p>}
        </div>
        <Textarea {...commonProps} name="Description" label="Description" placeholder="Enter the template description." onChange={handleChange} isInvalid={formData?.form?.Description === '' ? !!errors?.Description : ''} errorMessage={errors?.Description}></Textarea>
      </form>
      <div className='flex justify-end pt-8'>
        <ButtonComponent title="Next" color="danger" size="md" variant="faded" func={handleNext} />
      </div>
    </div>
  )
}

export default TemplateForm;