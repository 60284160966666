import React from 'react';
import { Activity, Clipboard, Codesandbox, Cpu, Edit3 } from '@geist-ui/icons';


export const AgentForm = () => {
    const items=[
        {
            key: "Scratch",
            label: "Scratch",
            description: "Build your instructions from scratch",
            icon: <Activity />,
            schema: {
                "properties": [
                    {
                        "name": "Instruction Name",
                        "type": "input",
                        "key": "InstructionName",
                        "placeholder": "Enter Instruction Name",
                        "required": true
                    },
                    {
                        "name": "Description",
                        "type": "input",
                        "key": "Description",
                        "placeholder": "Add context and describe what your instruction does.",
                        "required": true
                    },
                    {
                        "name": "Instruction Prompt",
                        "type": "textarea",
                        "key": "InstructionPrompt",
                        "placeholder": "Enter your prompt instruction for your agent",
                        "description": "To add variables to your prompt enclose it in {}",
                        "required": true
                    },
                    {
                        "name": "Add variables",
                        "type": "input",
                        "key": "variables",
                        "placeholder": "Enter Instruction Name",
                        "description": "To add multiple variables separate it with a comma",
                        "required": true
                    },
                ]
            }
        },
        {
            key: "Text Generator",
            label: "Text Generator",
            description: "Generates text",
            icon: <Clipboard />,
            schema: {
                "properties": [
                    {
                        "name": "OpenAI Key",
                        "type": "password",
                        "key": "OpenAIKey",
                        "placeholder": "Enter OpenAI key",
                        "required": true
                    },
                ]
            }
        },
        {
            key: "Summarize",
            label: "Summarize",
            icon: <Edit3 />,
            description: "Summarize your content",
            schema: {
                "properties": [
                    {
                        "name": "How do you wnat your summary to be?",
                        "type": "textarea",
                        "key": "OpenAIKey",
                        "placeholder": "Fine tune your prompt to refine your Agent's response.",
                        "description": "",
                        "required": true
                    },
                ]
            }
        },
        {
            key: "Database Executions",
            label: "Database Executions",
            icon: <Codesandbox />,
            description: "Build your instructions from scratch",
            schema: {
                "properties": [
                    {
                        "name": "OpenAI Key",
                        "type": "password",
                        "key": "OpenAIKey",
                        "placeholder": "Enter OpenAI key",
                        "description": "",
                        "required": true
                    },
                ]
            }
        },
        {
            key: "Knowledge Base Querying",
            label: "Knowledge Base Querying",
            icon: <Cpu />,
            description: "Instruct your Agent to query unstructured data",
            schema: {
                "properties": [
                    {
                        "name": "Instruction Name",
                        "type": "input",
                        "key": "InstructionName",
                        "placeholder": "Enter Instruction Name",
                        "required": true
                    },
                    {
                        "name": "Description",
                        "type": "input",
                        "key": "Description",
                        "placeholder": "Add context and describe what your instruction does.",
                        "required": true
                    },
                    {
                        "name": "Fine Tune your prompt",
                        "type": "textarea",
                        "key": "InstructionPrompt",
                        "placeholder": "You can fine tune our Knowledge Base Querying Prompt by giving additional context to it",
                        "required": true
                    },
                ]
            }
        }
    ];
  return items;
}
