import React, { useRef, useState, useEffect } from 'react';
import { Tabs, Tab, Button, Input, Textarea } from "@nextui-org/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { getAllData, uploadFile, CommonCollectionFilterApi, UpdateData } from "../../controllers/strapiController";
import { toast } from "react-hot-toast";
import UnAuthorized from '../../components/UnAuthorized';
import { DocumentUploadApi } from '../../controllers/vectordbController';
import NotFound from '../../components/NotFound';
import ServerIssue from "../../components/ServerIssue";
import Forbbiden from '../../components/Forbbiden';
import { uploadSkeleton } from '../../components/Skeleton';
import { useSelector } from "react-redux";
import Tags from '../../components/Tags';
import CurlComponent from './CurlComponent';
import CustomToast from '../../components/CustomToast';
import { spaceData } from '../../redux/actions';

const Upload = () => {
    // STATE INITIALISATION
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileUpload, setFileUpload] = useState();
    const spaceInfo = useSelector(spaceData);
    const [tags, setTags] = useState([]);
    const [validatetags, setValidatetags] = useState('');
    const [tagInput, setTagInput] = useState('');
    const [collection, setCollectionData] = useState();
    const [loader, setLoader] = useState(true);
    const [validationCondition, setValidationCondition] = useState(false);
    const [serverIssue, setServerIssue] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [forbbiden, setForbidden] = useState(false);
    const [authorized, setAuthorized] = useState(false);
    const ref = useRef();
    const fileInputRef = useRef();
    const params = useParams();
    const [tagsData, setTagsData] = useState();
    const [urlLink, setUrlLink] = useState('');
    const [formInfo, setFormInfo] = useState({ Name: '', Description: '', Tags: [], FileUpload: '' });
    const [formInfoError, setFormInfoError] = useState({ NameError: '', DescriptionError: '', FileError: '', LinkError: '' });
    const userDetails = JSON.parse(sessionStorage.getItem("userData"));
    const userID = userDetails && userDetails.user && userDetails.user.id;
    const navigate = useNavigate();

    const validExtensions = ["pdf", "pptx", "xls", "docs"];
    const URLRegex = /https?:\/\/(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/\S*)?/

    const tabsData = [
        { id: "2", label: "Share a link", key: "Link" },
        { id: "1", label: "Upload a file", key: "upload" },
        { id: "3", label: "Ingest via API", key: "Ingest via API" }
    ];

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    useEffect(() => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
    }, [tagInput])

    // API RENDERING
    useEffect(() => {
        const url = `filters[id][$in][0]=${params._id}&filters[space][Name][$eq]=${params._spaceid}&[populate][space][on]&[populate][author][on]&[populate][inviteds][on]&[populate][requests][on]&[populate][viewed][on]`
        CommonCollectionFilterApi("collections", url)
            .then((data) => {
                if (data?.error) {
                    if (data?.error?.status === 404) { setNotFound(true) }
                    else if (data?.error?.status === 403) { setForbidden(true) }
                    else if (data?.error?.status === 500) { setServerIssue(true) }
                    else if (data?.error?.status === 401) { setAuthorized(true) }
                    else {
                        CustomToast("Oops!, something went wrong, please try after some time.", "error")
                    }
                } else if (data.data.length > 0) {
                    setCollectionData(data.data)
                }
            })
            .catch((error) => {
                CustomToast("Oops!, something went wrong, please try after some time.", "error")
            })

        getAllData("tags")
            .then((data) => {
                if (data?.error) {
                    if (data?.error?.status === 404) { setNotFound(true) }
                    else if (data?.error?.status === 403) { setForbidden(true) }
                    else if (data?.error?.status === 500) { setServerIssue(true) }
                    else if (data?.error?.status === 401) { setAuthorized(true) }
                    else {
                        CustomToast("Oops!, something went wrong, please try after some time.", "error")
                    }
                } else if (data.data.length > 0) {
                    setTagsData(data.data)
                    setLoader(false)
                }
            })
            .catch((error) => {
                CustomToast("Oops!, something went wrong, please try after some time.", "error")
            })
    }, [params._id, params._spaceid])

    // ONCHANGE FUNCTION FOR FILE UPLOAD
    const handleFileChange = (event) => {
        const files = event.target.files;

        if (files && files[0]) {
            const file = files[0];
            setFileUpload(file);
            setSelectedFile(file.name);
            setFormInfo((previous) => ({ ...previous, FileUpload: file }))
        } else {
            setFileUpload(null);
            setSelectedFile(null);
        }
    }

    // TAGS FILTER DROPDOWN
    const FilteredUser = tagsData && tagsData.filter((data) => data?.attributes?.users_permissions_user?.data?.id === userID)
    // TAGS PRESENTS
    const TagsLength = FilteredUser?.[0]?.attributes?.Tags?.tags;
    // FILTER FOR INPUT TEXT RELATED TO TAGS
    const FilteredText = tagInput !== '' ? TagsLength && TagsLength.filter((data) => data && data.toLowerCase().includes(tagInput && tagInput.trim().toLowerCase())) : TagsLength;

    // FINAL DISPLAY TAGS
    const FilteredTags = FilteredText && FilteredText.length > 0 ? FilteredText : []

    const countWords = (str) => {
        // Split the string into words and filter out any empty strings
        return str.split(' ').filter(word => word.length > 0).length;
    };

    const WordCount = countWords(formInfo.Description)
    const fileExtension = formInfo.FileUpload && formInfo.FileUpload.name.split('.').pop().toLowerCase()

    // FORM VALIDATION
    const validateForm = (data) => {
        var isValid = true;

        if (!formInfo.Name) {
            isValid = false;
            setFormInfoError((previous) => ({ ...previous, NameError: "Name is required." }));
        } else {
            setFormInfoError((previous) => ({ ...previous, NameError: null }))
        }

        if (!formInfo.Description) {
            isValid = false;
            setFormInfoError((previous) => ({ ...previous, DescriptionError: "Description is required." }))
        } else if (formInfo.Description !== '' && WordCount > 200) {
            isValid = false;
            setFormInfoError((previous) => ({ ...previous, DescriptionError: "Description Should have less than 200 words." }))
        } else {
            setFormInfoError((previous) => ({ ...previous, DescriptionError: null }))
        }

        if (data === "upload") {
            if (!formInfo.FileUpload) {
                isValid = false;
                setFormInfoError((previous) => ({ ...previous, FileError: "Please select the file." }))
            } else if (!validExtensions.includes(fileExtension)) {
                setFormInfoError((previous) => ({ ...previous, FileError: "Invalid file uploaded. Please upload a pdf, pptx, xls, or docs file." }));
                isValid = false;
            } else {
                setFormInfoError((previous) => ({ ...previous, FileError: null }));
            }
        } else if (data === "Link") {
            if (!urlLink) {
                isValid = false;
                setFormInfoError((previous) => ({ ...previous, LinkError: 'URl is required.' }))
            } else if (!URLRegex.test(urlLink)) {
                isValid = false;
                setFormInfoError((previous) => ({ ...previous, LinkError: 'Enter a Valid URL.' }))
            } else {
                setFormInfoError((previous) => ({ ...previous, LinkError: null }))
            }
        }
        return isValid;
    }

    const TagsRender = formInfo.Tags !== null && TagsLength && formInfo.Tags.filter((data) => !TagsLength.includes(data));
    const newRenderation = TagsRender && FilteredTags && TagsRender.concat(FilteredTags);
    const tagsID = FilteredUser && FilteredUser[0] && FilteredUser[0].id;

    // SUBMIT TO UPLOAD
    const submitHandler = async (data) => {
        const currentTimestamp = Math.floor(Date.now() / 1000);
        const isValid = validateForm(data);
        if (isValid) {
            setLoader(true);
            let payload = {
                source: "pdf",
                document_id: `${currentTimestamp}`,
                document_title: formInfo.Name,
                document_description: formInfo.Description,
                tags: formInfo.Tags,
                user_id: `${userID}`,
                status: "active",
                description: collection?.attributes?.Description,
                metadata: {
                    collection: `${params._id}`
                }

            };

            if (data === "upload") {
                const response = await uploadFile(fileUpload);
                // --------------------Sreevats!!, Here is the uploaded file response--------------------
                const fileId = response?.[0]?.id


                if (response) {
                    const strapiUrl = `${response?.[0]?.url}`;
                    payload.document_url = strapiUrl;
                }
            } else {
                payload.document_url = urlLink;
            }

            const verctorName = spaceInfo[0].attributes.VerctorDBCollection;
            const response = await DocumentUploadApi(verctorName, currentTimestamp, params._spaceid, payload);
            if (response) {
                await UpdateData("tags", tagsID, newRenderation);
                setLoader(false);
                navigate(-1);
                CustomToast("Your document has been queued. ", "success")
            } else {
                setLoader(false);
                navigate(-1);
                CustomToast("Failed to upload document.", "error")
            }
        } else {
            setValidationCondition(true);
        }
    };

    const collectionData = collection && collection[0]
    const buttonDisable = !formInfo.FileUpload && !urlLink && !formInfo.Name && !formInfo.Description && !formInfo.Tags.length > 0
    const userOptions = collectionData?.attributes?.author?.data?.id === userID;

    // if (notFound || (collection && collection.length === 0)) {
    //     return <NotFound />
    // }

    // if (!loader && (authorized || !userOptions)) {
    //     return <UnAuthorized message='Only the author can upload a document.' />
    // }

    // if (serverIssue) return <ServerIssue />
    // if (forbbiden) return <Forbbiden />
    // if (loader) return uploadSkeleton();


    if (true) {
        return (
            <div className='p-8' >
                <div className='text-3xl font-medium '>Upload a Document</div>
                {/* CHANGE THIS SREEVATS */}
                <div className='mt-4 leading-relaxed'> Data connectors can help you to bring external sources directly into your overview. Each connector can be connected to your collectionData folders. You can also have multiple connectors to one collectionData.</div> {/*CHANGE*/}
                <div className="flex w-full flex-col pt-8">
                    <Tabs className="flex flex-row" aria-label="Options" placement="top" radius="md" >
                        {tabsData && tabsData.map((data, index) => {
                            return <Tab key={data.key} title={data.label}>
                                <div className="pt-8  flex flex-col gap-8">
                                    {/* Ingest Via API */}
                                    {data.key === "Ingest via API" ? (<>
                                        <div className='text-2xl font-semibold flex gap-8'>Usage</div>
                                        <div className='text-base'>
                                            Ingest data from various sources using APIs to automate collection and streamline integration into your systems.
                                        </div>
                                        <div className='font-semibold flex mt-4'>Step 1.</div>
                                        <div>
                                            Using the ingest APIs requires an API key. If you do not have an API key, {" "}
                                            <span className='text-primary cursor-pointer' onClick={() => { navigate(`/${params._spaceid}/keys`) }}>
                                                click here
                                            </span>
                                            .
                                        </div>
                                        <div className='font-semibold flex mt-4'>Step 2.</div>
                                        <div>
                                            Use this CURL command and integrate it into your workflow
                                        </div>
                                        <CurlComponent />
                                    </>) : (<>
                                        <Input
                                            className="max-w-md"
                                            isRequired
                                            key="outside"
                                            type="text"
                                            label="Document Title"
                                            labelPlacement="outside"
                                            isInvalid={validationCondition && !formInfo.Name}
                                            errorMessage={validationCondition && !formInfo.Name ? formInfoError.NameError : ""}
                                            onChange={(e) => setFormInfo((previous) => ({ ...previous, Name: e.target.value }))}
                                            placeholder="Give a title to your document"
                                        />
                                        <Textarea
                                            type="textarea"
                                            label="Description"
                                            labelPlacement="outside"
                                            minRows={5}
                                            isInvalid={validationCondition && (!formInfo.Description || (formInfo.Description !== '' && WordCount > 200))}
                                            errorMessage={validationCondition && (!formInfo.Description || (formInfo.Description !== '' && WordCount > 200)) ? formInfoError.DescriptionError : ""}
                                            onChange={(e) => setFormInfo((previous) => ({ ...previous, Description: e.target.value }))}
                                            placeholder="Give a description to your document"
                                        />
                                        {/* Drop down to choose the source */}
                                        {data.key === "upload" ?
                                            <div>
                                                <div className='flex flex-row items-center gap-2'>
                                                    <Button onClick={handleButtonClick} size='sm' variant="bordered" className='pr'> Upload a file </Button>
                                                    <label className='text-xs'>{selectedFile}</label>
                                                    <input ref={fileInputRef} onChange={handleFileChange} type='file' className='hidden' />
                                                </div>
                                                <p className="text-red-500 text-xs">
                                                    {validationCondition && (!formInfo.Name || !validExtensions.includes(fileExtension)) ? formInfoError.FileError : ""}
                                                </p>
                                            </div> :
                                            <div id="URL">
                                                <Input
                                                    id="Link"
                                                    className=""
                                                    isRequired
                                                    key="link"
                                                    type="text"
                                                    label="Document URL"
                                                    labelPlacement="outside"
                                                    // isInvalid={validationCondition && (!urlLink || (urlLink !== '' && !URLRegex.test(urlLink)))}
                                                    errorMessage={validationCondition && (!urlLink || (urlLink !== '' && !URLRegex.test(urlLink))) ? formInfoError.LinkError : ""}
                                                    onChange={(e) => setUrlLink(e.target.value)}
                                                    placeholder="Give a URL for your document"
                                                    startContent={
                                                        <div className="text-xs	font-thin text-slate-400">
                                                            <FontAwesomeIcon className="" icon={faLink} />
                                                        </div>
                                                    } />
                                            </div>}
                                        <Tags

                                            tagsData={tagsData}
                                            setTagsData={setTagsData}
                                            tagInput={tagInput}
                                            setTagInput={setTagInput}
                                            tags={tags}
                                            validatetags={validatetags}
                                            setTags={setTags}
                                            setValidatetags={setValidatetags}
                                            setFormdata={setFormInfo}
                                            formData={formInfo}
                                            background={"bg-transparent"}
                                        />
                                        <div className="pt-8 flex gap-4 justify-end">
                                            <Button color="default" variant="flat" onClick={() => { setFormInfo({}) }}>Cancel </Button>
                                            <Button color="secondary" isDisabled={buttonDisable} onClick={() => { submitHandler(data.key) }}> Upload a file </Button>
                                        </div>
                                    </>)}
                                </div>
                            </Tab>
                        })}
                    </Tabs>
                </div>
            </div>
        )
    }
}

export default Upload;