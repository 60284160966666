import React, { useEffect, useState } from "react";
import { CommonCollectionFilterApi } from "../../controllers/strapiController";
import ViewChart from "../Charts/ViewChart";
import { getSQLQueryResult } from "../../controllers/basicControllers";
import { Spinner } from "@nextui-org/react";
import { getSQLDataFromExternalAPI } from "./dummyApiResponse";
import { getSingleDocument } from "../../controllers/vectordbController";
import { useParams } from "react-router-dom";

// sql = defaultSql,
// chartType = defaultChartType,
// chartInfo = defaultChartInfo,
// data = defaultQueryResult,
// id = defaultId,
// dbConfig = defaultDbConfig,

const ShowChart = ({ data }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [dbConfig, setDbConfig] = useState(null);
  const [queryResult, setQueryResult] = useState([]);

  const { _spaceid } = useParams();
  console.log(_spaceid);

  useEffect(() => {
    const collectionId = data?.collectionId;
    const isSql =
      data?.isSql === undefined || data?.isSql === null || data?.isSql;
    if (!isSql) {
      // fetch data from strapi
      getSQLDataFromExternalAPI(data?.apiEndUrl).then((data) => {
        setQueryResult(data);
        setIsLoading(false);
      });
    } else {
      getSingleDocument(collectionId, _spaceid, "data-products").then((d) => {
        console.log(d);
        const dbData = {
          dbType: d[0]?.payload?.data_product?.toLowerCase(),
          ...d[0]?.payload?.config,
        };

        const queryData = {
          sql: data?.sql_statement,
          dbType: dbData?.dbType,
          dbConfig: { ...dbData, user: dbConfig?.username },
        };

        getSQLQueryResult(queryData).then((queryResponse) => {
          if (queryResponse?.error) {
            setQueryResult([]);
          } else setQueryResult(queryResponse || []);

          setDbConfig(dbData);
          setIsLoading(false);
        });
      });
    }
  }, [data]);

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center w-full h-full">
          <Spinner color="default" />
        </div>
      ) : (
        <ViewChart
          dbConfig={dbConfig}
          id={data?.id}
          data={{ query_result: queryResult }}
          chartType={data?.chart_type}
          sql={data?.sql_statement}
          chartInfo={{
            name: data?.chart_title,
            description: data?.chart_description,
            xAxis: data?.xAxis,
            yAxis: data?.yAxis,
            minThreshold: data?.minThreshold,
            maxThreshold: data?.maxThreshold,
          }}
          cardClasses=""
        />
      )}
    </>
  );
};

export default ShowChart;
