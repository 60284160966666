import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  getAllData,
  getFilteredData,
} from "../../controllers/strapiController";
import { useSelector } from "react-redux";
import { spaceData } from "../../redux/actions";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Image,
  Input,
} from "@nextui-org/react";
import SearchIcon from "../../Images/Search";

const DataProductSelect = ({
  form,
  setForm,
  selectedProduct,
  setSelectedProduct,
  handleNext,
}) => {
  const [searchParams] = useSearchParams(); // Destructure the tuple
  const query = searchParams.get("product"); // Extract the value of 'query'
  const spaceInfo = useSelector(spaceData);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const { _spaceid } = useParams();
  const filteredProducts = useMemo(() => {
    return products.filter((a) =>
      a.attributes.Name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [searchQuery, products]);
  const handleClick = () => {
    if (!selectedProduct) {
      return;
    }
    setForm((prev) => ({
      ...prev,
      type: selectedProduct.attributes.Type,
      data_product: selectedProduct.attributes.Name,
    }));
    handleNext();
  };
  const truncateDescription = (description, charLimit = 100) => {
    return description.length > charLimit
      ? description.slice(0, charLimit) + "..."
      : description;
  };
  useEffect(() => {
    const fetchDataProducts = async () => {
      try {
        const resp = await getAllData("data-products");
        setProducts(resp.data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    const fetchCollections = async () => {
      try {
        const resp = await getFilteredData(
          "data-products",
          [{ filterKey: "Type", filterValue: "collections" }],
          [],
          [],
          []
        );
        setProducts(resp.data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    if (spaceInfo[0]?.id) {
      query === "collections" ? fetchCollections() : fetchDataProducts();
    }
  }, [query, spaceInfo]);
  useEffect(() => {
    setForm({
      data_product: null,
      type: "",
      name: "",
      public: false,
      description: "",
      tags: [],
      domain: null,
    });
  }, [selectedProduct]);
  return (
    <div>
        <h1 className='text-3xl font-medium pb-4'>Add a new {query === "collections" ? "collection" : "data product"}</h1>
        <p className="" >Add a new data product to your datamesh and bring all your enterprise data products inside your space. Your data can be unstructured documents, structured data from databases, or from an exteral source </p>
        <div className="flex items-center justify-end py-8" >
            <Button color="danger" onClick={() => navigate(`/${_spaceid}/data-products/${query === "collections" ? "collections/" : ""}update`) } >Explore</Button>
        </div>
      <Input
        placeholder="Search for a data product"
        className="pb-8"
        startContent={
          <SearchIcon
            className={`text-large text- pointer-events-none flex-shrink-0 `}
          />
        }
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <div className="max-w-[1200px] mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {filteredProducts?.length > 0 ? (
          filteredProducts?.map((data) => (
            <div key={data.id} onClick={() => setSelectedProduct(data)}>
              <Card
                shadow="none"
                className={`border border-divider px-4 py-2 w-full h-[200px] cursor-pointer ${
                  selectedProduct?.id === data.id
                    ? "border-danger"
                    : "border-divider"
                }`}
              >
                <CardHeader className="text-lg gap-2 flex items-center">
                  <Image src={data.attributes.Image} width={20} />
                  <span>{data.attributes.Name}</span>
                </CardHeader>
                <CardBody>
                  <div className="text-default-500">
                    {truncateDescription(data.attributes.Description)}
                  </div>
                </CardBody>
              </Card>
            </div>
          ))
        ) : (
          <div className="text-center w-full col-span-3">No products found</div>
        )}
      </div>
      <div className="pt-8 pr-2 flex flex-row gap-4 justify-end">
        <Button
          onClick={() => navigate(`/${_spaceid}/data-products`)}
          color="danger"
          variant="light"
        >
          Cancel
        </Button>
        <Button
          color="danger"
          isDisabled={!selectedProduct}
          onClick={handleClick}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default DataProductSelect;
