import {CommonCollectionFilterApi} from "../controllers/strapiController";
// validationUtils.js
export const validateTemplateForm = (formData) => {
    const errors = {};
  
    if (!formData.form?.Name) {
      errors.Name = "Name is required.";
    } else if (formData?.form?.Name?.length < 3) {
      errors.Name = "Name must be at least 3 characters long.";
    }
  
    if (!formData.form?.Description) {
      errors.Description = "Description is required.";
    }
  
    return errors;
  };

  // AGENT STEP 1 VALIDATOR
  export const agentValidator=(formData,response,spaceName)=>{
    
    const errors = {};
    let isValid=true;

   
    if (!formData?.Name) {
      isValid=false;
      errors.Name = "Name is required.";
    } else if (formData?.Name?.length < 3) {
      isValid=false;
      errors.Name = "Name must be at least 3 characters long.";
    }else if(response?.data?.length!==0){
      isValid=false;
      errors.Name = "Name of the agent already exists. Try with new name.";
    }
  
    if (!formData?.Context) {
      isValid=false;
      errors.Context = "Context is required.";
    }

    // if (formData?.DataProducts?.length===0) {
    //   isValid=false;
    //   errors.DataProducts = "Data product is required.";
    // }
  
    return {errors,isValid};
  }
  

  // AGENT STEP 2 MODAL VALIDATOR
  export const modalValidator = (formData) => {
    const errors = {};
    let isValid = true;
  
    // Loop through the formData keys and check if the values are empty
    Object.keys(formData)?.forEach((key) => {
      if (formData[key] === '') {
        errors[key] = `${key} is required.`;
        isValid = false;
      }
    });
  
    return { errors, isValid };
  };