import React, { useEffect, useState } from 'react'
import { Avatar, Tabs, Tab } from "@nextui-org/react";
// import Settings from "../../pages/subPages/Settings";
import { CommonCollectionFilterApi } from '../../controllers/strapiController';
import { avatarName, colorPicker } from "../../controllers/basicControllers";
import NotFound from '../../components/NotFound';
import ServerIssue from '../../components/ServerIssue';
import Forbidden from "../../components/Forbbiden";
import { ConnectorSkeleton } from '../../components/Skeleton';
import { useParams } from "react-router-dom";
import Settings from "../../pages/subPages/Settings";
import LogsTable from './LogsTable';

const SingleApiKeys = () => {
    const [apiKeys, setApiKeys] = useState(null);
    const [selected, setSelected] = useState("APICall");
    const [loader, setLoader] = useState(true);
    const [reload, setReload] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [forbidden, setForbidden] = useState(false);
    const [serverIssue, setServerIssue] = useState(false);
    const [connectionRefused, setConnectonRefused] = useState(false);
    const CollectionName = 'keys'
    const params = useParams();


    useEffect(() => {
        const url = `filters[id][$in][0]=${params._keyId}&filters[space][Name][$eq]=${params._spaceid}`
        CommonCollectionFilterApi(CollectionName, url)
            .then((data) => {
                setLoader(false)
                if (data.error) {
                    if (data.error.status !== 404 && data.error.status !== 403) { setServerIssue(true) }
                    if (data.error.status === 404) { setNotFound(true) }
                    if (data.error.status === 403) { setForbidden(true) }
                } else if (data.data?.length > 0) {
                    setLoader(false)
                    setApiKeys(data.data?.[0])
                } else {
                    setNotFound(true)
                }
            })
            .catch((error) => { setLoader(false); setConnectonRefused(true) })

    }, [reload])


    if (loader) {
        return ConnectorSkeleton();
    }

    if (notFound) {
        return <NotFound />
    }

    if (serverIssue) {
        return <ServerIssue />
    }

    if (connectionRefused) {
        return <ServerIssue />
    }

    if (forbidden) {
        return <Forbidden />
    }

    return (
        <div>
            <div className='flex gap-4 items-center'>
                <Avatar
                    radius='sm'
                    src=""
                    width={30}
                    color={colorPicker(apiKeys?.id)}
                    name={avatarName(apiKeys?.attributes?.Name)} />
                <div className='text-3xl font-medium'>{apiKeys?.attributes?.Name}</div>
            </div>
            {/* Description */}
            <div className='mt-8'>
                {apiKeys?.attributes?.Description}
            </div>
            <div className='pt-8'>
                <Tabs
                    aria-label="Options"
                    color={colorPicker(apiKeys?.id)}
                    variant="underlined"
                    selectedKey={selected}
                    defaultSelectedKey={""}
                    onSelectionChange={setSelected}>
                    <Tab key="logs" title="Logs">
                        <LogsTable logs={apiKeys?.attributes?.Logs} />
                    </Tab>
                    <Tab key="Settings" title="Settings" >
                        <Settings collection={apiKeys} setReload={setReload} setLoader={setLoader} collectionName="keys" isHide={false} />
                    </Tab>
                </Tabs>
            </div>
        </div>
    )
}

export default SingleApiKeys;