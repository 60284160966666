import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import input from "./input.css";
import { NextUIProvider } from '@nextui-org/react';
import { Provider } from 'react-redux';
import store from "./redux/store"
import Widget from "react-resolv"

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <React.StrictMode>
    <NextUIProvider>
      <main>
        <Provider store={store}>
          {/* <Widget.ResolvProvider appId={"45b3e9dd6490eac7a8566680e1e930fb"} customerId={`scrum_harshit_demo@gmail.com`}> */}
          <App />
          {/* </Widget.ResolvProvider> */}
        </Provider>
      </main>
    </NextUIProvider>
  </React.StrictMode>,
);

reportWebVitals();
