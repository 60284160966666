const dummyResult = [
  {
    customer: "5Marach_2",
    total_users: 31228,
  },
  {
    customer: "DART237_Testing",
    total_users: 16297,
  },
  {
    customer: "TestSite",
    total_users: 5054,
  },
  {
    customer: "SiteNotConfigured",
    total_users: 3431,
  },
];

export const getSQLDataFromExternalAPI = async (url) => {
  // const data = await fetch(url);
  // const jsonData = await data.json();

  return dummyResult;
};
