import React, { useEffect, useState, useRef } from 'react'
import ModalPopup from './ModalPopup';
import { Button, Input, ModalBody, ModalHeader, Switch, Textarea } from '@nextui-org/react';
import { UpdateData, getAllData } from '../../controllers/strapiController';
import Tags from '../../components/Tags';
import {toast} from "react-hot-toast";
import { colorPicker } from '../../controllers/basicControllers';
import CustomToast from '../../components/CustomToast';

const UpdateCollection = ({ setReload, isWarningOpen, onWarningOpenChange, SelectCollection, setLoader }) => {
    // STATE INITIALIZATION
    const [tagInput, setTagInput] = useState('');
    const [validationCondition, setValidationCondition] = useState(false);
    const [Validatetags, setValidatetags] = useState('');
    const [InitialData, setInitialData] = useState(null);
    const [tagsData, setTagsData] = useState();
    const CollectionName = "collections";
    const ref = useRef();
    const UserDetails = JSON.parse(sessionStorage.getItem("userData"));
    const UserID = UserDetails && UserDetails.user && UserDetails.user.id;
    const [tags, setTags] = useState([]);
    const [formData, setFormdata] = useState({ Name: '', Description: '', Tags: [], isPublic: false });
    const [formError, setFormError] = useState({ NameError: '', DescriptionError: '', });

    // SCROLL WORKS DYNAMICALLY
    useEffect(() => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
    }, [tagInput])

    // TAGS API RENDER
    useEffect(() => {
        getAllData("tags")
            .then((data) => {
                setTagsData(data.data)
            })
            .catch((error) => CustomToast( "Oops!, something went wrong, please try after some time.","error"))
    }, [])

    // COLLECTION STATE UPDATE
    useEffect(() => {
        if (SelectCollection) {
            const TagsData = SelectCollection.attributes.Tags && SelectCollection.attributes.Tags.tags;
            setInitialData(TagsData);
            TagsData && setTags(SelectCollection.attributes.Tags.tags)
            setFormdata({
                Name: SelectCollection.attributes.Name,
                Description: SelectCollection.attributes.Description,
                Tags: TagsData,
                isPublic: SelectCollection.attributes.Public
            })
        }
    }, [SelectCollection])

    // VALIDATION FORM
    const Validation = () => {
        let IsValid = true;

        if (!formData.Name) {
            IsValid = false;
            setFormError((previous) => ({ ...previous, NameError: "Name is required." }));
        } else {
            setFormError((previous) => ({ ...previous, NameError: null }))
        }

        if (!formData.Description) {
            IsValid = false;
            setFormError((previous) => ({ ...previous, DescriptionError: "Description is required." }))
        } else {
            setFormError((previous) => ({ ...previous, DescriptionError: null }))
        }

        return IsValid;
    }


    // Check if the arrays are of the same length
    const lengthMatch = formData.Tags && InitialData && InitialData.length === formData.Tags.length;

    // Check if each element matches
    const elementsMatch = InitialData && InitialData.length > 0 && formData.Tags && formData.Tags.length > 0 ? InitialData.every((element, index) => element === formData.Tags[index]) : null;

    const TagsCompare = lengthMatch && elementsMatch;

    const Disabled = SelectCollection && SelectCollection.attributes &&
        formData.Name === SelectCollection.attributes.Name && formData.Description === SelectCollection.attributes.Description && (TagsCompare === true || TagsCompare === null) && formData.isPublic === SelectCollection.attributes.Public ? true : false;

    // TAGS FILTER DROPDOWN
    const FilteredUser = tagsData && tagsData.filter((data) => data?.attributes?.users_permissions_user?.data?.id === UserID)
    // TAGS PRESENTS
    const TagsLength = FilteredUser?.[0]?.attributes?.Tags?.tags;
    // FILTER FOR INPUT TEXT RELATED TO TAGS
    const FilteredText = tagInput !== '' ? TagsLength && TagsLength.filter((data) => data && data.toLowerCase().includes(tagInput && tagInput.trim().toLowerCase())) : TagsLength;

    // FINAL DISPLAY TAGS
    const FilteredTags = FilteredText && FilteredText.length > 0 ? FilteredText : []

    const TagsRender = formData.Tags && formData.Tags.filter((data) => TagsLength && !TagsLength.includes(data));
    const NewRenderation = TagsRender && FilteredTags && TagsRender.concat(FilteredTags);
    const TagsID = FilteredUser && FilteredUser[0] && FilteredUser[0].id;

    // HANDLER TO UPDATE COLLECTIONS
    const SubmitHandler = async (onClose) => {
        setLoader(true);
        onClose();
        const payload = {
            Name: formData.Name,
            Description: formData.Description,
            Tags: { tags: formData.Tags },
            Public: formData.isPublic
        }

        const TagsPayload = { Tags: { tags: NewRenderation } }

        const Validate = Validation();
        if (Validate) {
            setValidationCondition(false);
            const response = await UpdateData(CollectionName, SelectCollection.id, payload)
            if (response) {
                const updateResponse = await UpdateData("tags", TagsID, TagsPayload)
                setReload(updateResponse)

                setTimeout(() => {
                    setLoader(false);
                    setTagInput('')
                }, 500)

            }
        } else {
            setValidationCondition(true);
        }
    }

          // TAGS INPUT ONCHANGE HANDLER
          const handleChange = (e) => {
            const userInput = e.target.value;
            const regex = /^[a-zA-Z_ -]*$/;
            if (regex.test(userInput)) {
                setFormdata({ ...formData, Name: userInput})
            }
        }

    // POPUP MODAL FOR UPDATE COLLECTIONS
    const ModalBodyData = () => {
        return <> <ModalHeader className="flex flex-col gap-1">Update your collection</ModalHeader>
            <ModalBody className='gap-0'>
                <Input
                    isRequired
                    key="outside"
                    type="text"
                    value={formData.Name}
                    onChange={handleChange}
                    isInvalid={!formData.Name && validationCondition ? !formData.Name || validationCondition : ''}
                    errorMessage={!formData.Name && validationCondition ? formError.NameError : ""}
                    label="Collection Name"
                    labelPlacement="outside"
                    placeholder="Enter your collection folder name"
                />
                <Textarea
                    className='pt-4'
                    key={"description"}
                    isRequired
                    type="text"
                    value={formData.Description}
                    onChange={(e) => { setFormdata({ ...formData, Description: e.target.value }) }}
                    isInvalid={!formData.Description && validationCondition ? !formData.Description || validationCondition : ''}
                    errorMessage={!formData.Description && validationCondition ? formError.DescriptionError : ""}
                    label="Decription"
                    labelPlacement={"outside"}
                    placeholder="Enter your collection's Description"
                />
                <div className='flex flex-col gap-1 pt-4'>
                    <div className='flex gap-2 flex-col'>
                        <p className='text-sm'>Make collection public</p>
                        <Switch isSelected={formData.isPublic} onChange={(e) => { setFormdata({ ...formData, isPublic: !formData.isPublic }) }} size='sm' color="secondary"></Switch>
                    </div>
                    {formData.isPublic ? <p className='text-xs text-slate-400'>By making your collection public anyone inside the space can view and upload your documents.</p> : <></>}
                </div>
                <Tags

                    tagsData={tagsData}
                    setTagsData={setTagsData}
                    tagInput={tagInput}
                    setTagInput={setTagInput}
                    tags={tags}
                    validatetags={Validatetags}
                    setTags={setTags}
                    setValidatetags={setValidatetags}
                    setFormdata={setFormdata}
                    formData={formData}
                    background={"bg-transparent"}
                />
            </ModalBody>
        </>
    }

    // BUTTONS FOR MODAL
    const footerCreate = (onClose) => {
        return <><Button color="default" variant="flat" onClick={() => { onClose() }}>
            Cancel
        </Button>
            <Button isDisabled={Disabled} color={colorPicker(SelectCollection?.id)} onClick={() => { SubmitHandler(onClose) }}>
                Update
            </Button></>
    }

    // RENDER UI
    return (
        <div>
            <ModalPopup
                size="2xl"
                isOpen={isWarningOpen}
                onOpenChange={onWarningOpenChange}
                ModalBodyData={ModalBodyData}
                footer={footerCreate}
                scrollBehavior="inside"
            />
        </div>
    )
}

export default UpdateCollection;