import React from 'react'

export const RightArrow = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_305_31778)">
    <path d="M17.3101 8L21.2501 12L17.3101 16" stroke="#121331" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21.19 12H2.75" stroke="#121331" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_305_31778">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  )
}
