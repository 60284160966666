import React, { useEffect, useRef, useState } from 'react';
import { CircularProgress, Textarea, Image, Avatar } from '@nextui-org/react';
import { RightArrow } from '../../Images/RightArrow';
import { CreditUpdate, getOneData, userLoginDetails } from '../../controllers/strapiController';
import Logo from "../../Images/haya-logo.png";
import { colorPicker } from '../../controllers/basicControllers';
import AdditionalOptions from './AdditionalOptions';
import HayaTableRender from './HayaTableRender';
import DocumentRendering from './DocumentRendering';
import AgentDropdown from './AgentDropdown';
import InitialSearch from './InitialSearch';
import { credits } from '../../redux/actions';
import { useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';

const Haya = () => {
  // STATE INITIALIZATION
  const [chatMessages, setChatMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [FinalResult, setFinalResult] = useState([]);
  const [UserData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const responseTable = [];
  const ref = useRef(null);
  const UserInfo = userLoginDetails();
  const dispatch = useDispatch();
  const UserId = UserInfo?.userId;
  const [visibleOptions, setVisibleOptions] = useState(Array(chatMessages.length).fill(false));
  const context_documents = [];
  const params = useParams();

  // MOUSE OVER TO SHOW COPY,THUMBSUP OPTIONS
  const handleMouseEnter = (index) => {
    setVisibleOptions(prevState => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
  };

  // MOUSE LEAVE TO HIDE COPY,THUMBSUP OPTIONS
  const handleMouseLeave = (index) => {
    setVisibleOptions(prevState => {
      const newState = [...prevState];
      newState[index] = false;
      return newState;
    });
  };

  function getDocs(props) {
    context_documents.push(props);
  }

  console.log("context_documents------>",context_documents)

  function apphend_table(props) {
    responseTable.push(props);
  }

  // API RESPONSE
  useEffect(() => {
    getOneData("users", UserId)
      .then((data) => setUserData(data))
      .catch((error) => error);
  }, [UserId]);


  // "ENTER" KEY TO SEARCH 
  const handleKeyPress = async (event, data) => {
    setLoading(true);


    if (event.key === 'Enter' && inputValue.trim() !== '') {
      const Value = inputValue.trim();
      setChatMessages([...chatMessages, Value]);
      setFinalResult([...FinalResult, { Question: Value, response: { searchResult: "", similarQuestions: [], documents: [] } }])
      setInputValue('');

      const socket = new WebSocket(`${process.env.REACT_APP_WS_IP_ADDRESS}/haya/ws/stream`);
      socket.onopen = () => {
        socket.send(JSON.stringify({ app_id: "DEX", query: Value, collection_name: "DEX", session_id: "444333" }));

      };

      socket.onmessage = (event) => {
        // CreditUpdate("hayaSearch", UserId)
        //   .then(data => dispatch(credits(data?.Credit)))

        setFinalResult((prevResults) => {
          const lastResult = prevResults[prevResults.length - 1];
          if (lastResult && lastResult.Question === Value) {

            const updatedResult = {
              ...lastResult,
              response: {
                ...lastResult.response,
                searchResult: lastResult.response.searchResult + event.data
              }
            };

            return [
              ...prevResults.slice(0, prevResults.length - 1),
              updatedResult
            ];
          } else {
            const newResult = { Question: Value, response: { searchResult: event.data, similarQuestions: [], documents: [] } };

            return [...prevResults, newResult];
          }
        });
        setLoading(false);
      };
      socket.onclose = () => {
      };

      socket.onerror = (error) => {
        setLoading(false);

        setFinalResult((prevResults) => {
          const lastResult = prevResults[prevResults.length - 1];

          if (lastResult && lastResult.Question === Value) {
            const updatedResult = {
              ...lastResult,
              response: {
                ...lastResult.response,
                searchResult: lastResult.response.searchResult + event.data
              }
            };

            return [
              ...prevResults.slice(0, prevResults.length - 1),
              updatedResult
            ];
          } else {
            const newResult = { Question: Value, response: { searchResult: "Server issue. Cannot connect to Haya.", similarQuestions: [], documents: [] } };
            return [...prevResults, newResult];
          }
        });
        if (ref.current) {
          ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      };
    } else if (data) {
      setChatMessages([...chatMessages, data]);
      setInputValue('');
      setLoading(true);

      if (ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  };

  // STRING SPLIT FUNCTION
  const renderBoldText = (text) => {
    const boldRegex = /\*\*(.*?)\*\*/g;
    return text.split(boldRegex).map((part, index) => {
      if (index % 2 === 0) {
        return <span key={index}>{part}</span>;
      } else {
        return <strong key={index}>{part}</strong>;
      }
    });
  };

  const cardClick = (link) => {
    window.open(link, '_blank');
  };


  // RETURN UI
  return (
    <>
      <AgentDropdown setSelectedAgent={setSelectedAgent} selectedAgent={selectedAgent} />
      <div className='flex flex-col justify-between h-[calc(100vh-170px)] overflow-y-hidden scroll-smooth'>
        {chatMessages && chatMessages.length > 0 ? <>
          <div className='overflow-y-auto scroll-smooth'>
            {chatMessages && chatMessages.map((data, index) => {
              const USerProfiled = UserData && UserData.Picture !== null ? `${process.env.REACT_APP_STAGE === 'dev' ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${UserData && UserData.Picture && UserData.Picture.url}` : `${UserData?.Picture?.url}`}` : "";
              return (
                <React.Fragment key={`${index}`}>
                  <div className={index === chatMessages.length - 1 ? `mb-2 t-20` : "mb-2"}>
                    <div className='flex flex-col'>
                      <div className='flex flex-row gap-4 items-center'>
                        <Avatar size='sm' src={USerProfiled} color={colorPicker(UserId)} name={UserData?.email?.slice(0, 1).toUpperCase()} />
                        <p className='font-semibold'>You</p>
                      </div>
                      <p className='pl-12'>{data?.split('\n')?.map(value => <p>{value}</p>)}{loading && index === chatMessages.length - 1 && <span className="cursor-dot"></span>}</p>
                    </div>
                  </div>
                  <div onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={() => handleMouseLeave(index)} className='mb-8 relative' ref={index === chatMessages.length - 1 ? ref : null}>
                    <div className='flex flex-row gap-4 items-center pt-10'>
                      <Avatar src={Logo} size="sm" />
                      <p className='font-semibold'>Haya</p>
                    </div>
                    {FinalResult[index] && FinalResult[index].response &&
                      <>
                        {!FinalResult[index].response.searchResult && FinalResult[index].response.documents.length === 0 && !loading && (
                          <div className="flex justify-center my-4">
                            <h4 className='font-bold'>Interact with your documents</h4>
                          </div>
                        )}
                        {FinalResult[index].response.searchResult && (
                          <div className={`mb-2 ${loading ? '' : 'fade-in'}`}>
                            <div className='flex items-center pl-12'>
                              <div className='w-full'>
                                {FinalResult[index].response.searchResult.split('\n').map((part, index) => {
                                  const tablepart = part.trim().startsWith('|');
                                  const document = part.trim().startsWith('{');
                                  if (part.trim().startsWith('-')) {
                                    return (
                                      <p key={`${index}`} className='leading-loose'>
                                        &bull; {renderBoldText(part.substring(1))}
                                        <br />
                                      </p>
                                    );
                                  } else if (tablepart) {
                                    if (part.trim().includes('----')) {
                                      return (
                                        responseTable && (
                                          <>
                                            <div className='max-h-100px overflow-y-auto'>
                                              <HayaTableRender markdownTable={responseTable} />
                                            </div>
                                          </>
                                        )
                                      );
                                    }
                                    else {
                                      const appendedPart = part.trim() + '\n';
                                      apphend_table(appendedPart);
                                      return null;
                                    }
                                  }
                                  else if (document) {
                                    if (part.trim().includes('filtered_documents')) {
                                      // console.log("document",part.trim())
                                      const dataRendered=getDocs(part.trim())

                                      console.log("********",context_documents)
                                      return (
                                        <>
                                          <div className='max-h-100px overflow-y-auto'>
                                            <DocumentRendering documents={[part.trim()]} />
                                          </div>
                                        </>
                                      );
                                    }
                                  }
                                  else {
                                    return (
                                      <p key={index} className='leading-loose'>
                                        {renderBoldText(part)}
                                        <br />
                                      </p>
                                    );
                                  }
                                })}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    }
                    <div className='h-6'>
                      {visibleOptions?.[index] && <AdditionalOptions data={FinalResult?.[index]} text={FinalResult?.[index]?.response?.searchResult} />}
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
            {loading && (
              <div className="flex justify-center my-4">
                <CircularProgress color="danger" size='lg' aria-label="Loading..." />
              </div>
            )}
          </div>
        </>
          :
          <InitialSearch inputValue={inputValue} setInputValue={setInputValue} handleKeyPress={handleKeyPress} />
        }
        <div className=' w-full flex justify-center'>
          {chatMessages?.length > 0 && <Textarea
            className='w-full px-12'
            size='sm'
            radius='md'
            startContent={<Image
              src={Logo}
              width={25}
              radius='full'
              className="text-large text-default-400 pointer-events-none flex-shrink-0 " />}
            endContent={<div className='flex items-end h-full'><RightArrow textdisabledState={inputValue} /></div>}
            placeholder='Message Haya...'
            variant='flat'
            value={inputValue}
            minRows={1}
            onChange={(event) => { setInputValue(event.target.value) }}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                if (event.shiftKey) {
                  setInputValue(prev => prev + '\n');
                } else {
                  handleKeyPress(event);
                }
              }
            }}
          />}
        </div>
      </div>
    </>
  );
};

export default Haya;