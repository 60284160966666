import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { spaceData } from "../../redux/actions";
import { getFilteredBySingleRelation } from "../../controllers/strapiController";
import {
  Avatar,
  Button,
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Select,
  Switch,
  Textarea,
} from "@nextui-org/react";
import { avatarName } from "../../controllers/basicControllers";

const About = ({
  selectedProduct,
  setFormData,
  formData,
  onPrevious,
  handleNext,
}) => {
  const [domains, setDomains] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState(new Set( formData?.domain ? [formData?.domain] : []));
  const spaceInfo = useSelector(spaceData);
  const [error, setError] = useState("");
  const [inputTag, setInputTag] = useState("");
  const handleAddTags = (e) => {
    if (e.key === "Enter" && e.target.value.trim() !== "") {
      const val = e.target.value.trim();
      if (formData.tags.includes(val)) {
        setError("This tag already exists");
      } else {
        setFormData((prev) => ({ ...prev, tags: [...prev.tags, val] }));
        setError("");
        setInputTag("");
      }
    }
  };
  const handleSubmit = () => {
    if (Array.from(selectedDomain).length > 0) {
      setFormData((prev) => ({
        ...prev,
        domain: String(Array.from(selectedDomain)[0]),
      }));
    }
    handleNext();
  };
  useEffect(() => {
    const fetchDomains = async () => {
      try {
        const resp = await getFilteredBySingleRelation(
          "domains",
          "space",
          spaceInfo[0]?.id,
          ["Name"]
        );
        setDomains(resp.data);
      } catch (e) {
        console.log(e);
      }
    };
    if (spaceInfo[0]?.id) {
      fetchDomains();
    }
  }, [spaceInfo]);
  return (
    <div className="pb-8">
      <h1 className="text-3xl font-medium pb-4 gap-4 flex items-center">
        <Avatar
          src={selectedProduct.attributes.Image}
          name={avatarName(selectedProduct?.attributes?.Name)}
          radius="md"
          size="md"
        />
        {selectedProduct.attributes.Name}
      </h1>
      {selectedProduct.attributes?.Tags?.tags?.length > 0 ? (
        <div className="flex gap-2">
          {selectedProduct.attributes?.Tags.tags.map((item, index) => (
            <div key={index}>
              <Chip variant="flat" className="mb-8" color="secondary">
                {item}
              </Chip>
            </div>
          ))}
        </div>
      ) : null}
      <p>{selectedProduct.attributes.Description}</p>

      <div className="flex flex-col gap-8 pt-8">
        <div>
          <Input
            label="Name of the data product"
            placeholder="Give a name to your data product"
            labelPlacement="outside"
            name="Name"
            value={formData.name}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, name: e.target.value }))
            }
            isRequired
          />
        </div>
        <div>
          <Textarea
            label="Enter context to your data product"
            placeholder="Give context to your data product"
            labelPlacement="outside"
            name="Context"
            value={formData.description}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, description: e.target.value }))
            }
            isRequired
          />
        </div>
        <div className="flex items-center justify-between" >
            <div>Public Access</div>
            <Switch checked={formData.public} onValueChange={(e) => setFormData((prev) => ({...prev , public : e}) ) } ></Switch>
        </div>
        {selectedProduct?.attributes?.Type === "collections" && (
          <div className="flex flex-col gap-1">
            <div className="flex gap-2 flex-col">
              <p className="text-sm">Make collection public</p>
              <Switch
                isSelected={formData?.isPublic}
                name="isPublic"
                onValueChange={(e) =>
                  setFormData((prev) => ({ ...prev, isPublic: e }))
                }
                size="sm"
                color="secondary"
              ></Switch>
            </div>
            {formData.isPublic ? (
              <p className="text-xs text-slate-400">
                By making your collection public anyone inside the space can
                view and upload your documents.
              </p>
            ) : (
              <></>
            )}
          </div>
        )}
        <div>
          <div className="text-sm mb-2">Domain</div>
          <Dropdown className="w-full">
            <DropdownTrigger className="w-full">
              <Button className="w-full flex justify-start">
                {Array.from(selectedDomain).length === 0
                  ? "Select a domain"
                  : domains.find(
                      (a) =>
                        String(a.id) === String(Array.from(selectedDomain)[0])
                    )?.attributes?.Name}
              </Button>
            </DropdownTrigger>
            <DropdownMenu
              selectedKeys={selectedDomain}
              onSelectionChange={setSelectedDomain}
              selectionMode="single"
            >
              {domains.map((a) => (
                <DropdownItem key={a.id}>{a.attributes.Name}</DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>

        <div className="mt-4">
          <div className="text-sm mb-2">Tags</div>
          <div className="w-full flex pb-2 min-h-[80px] justify-between flex-col gap-2 rounded-lg border border-divider">
            <Input
              classNames={{
                innerWrapper:
                  "data-[hover=true]:bg-white group-data-[focus=true]:bg-white shadown-none bg-white",
                inputWrapper:
                  "data-[hover=true]:bg-white group-data-[focus=true]:bg-white shadow-none bg-white",
              }}
              value={inputTag}
              onChange={(e) => setInputTag(e.target.value)}
              onKeyDown={(e) => handleAddTags(e)}
              placeholder="Enter tags"
              className=" border-none outline-none bg-white hover:bg-white rounded-lg"
            />
            <div className="flex px-4 flex-wrap gap-2">
              {formData.tags.map((a) => (
                <Chip
                  className=" cursor-pointer"
                  onClose={() =>
                    setFormData((prev) => ({
                      ...prev,
                      tags: prev.tags.filter((t) => t !== a),
                    }))
                  }
                  size="sm"
                  variant="flat"
                  color="secondary"
                >
                  {a}
                </Chip>
              ))}
            </div>
          </div>
          {error !== "" && (
            <div className="pt-2 text-red-400 text-xs ">{error}</div>
          )}
        </div>
      </div>
      <div className="pt-8 pr-2 flex flex-row gap-4 justify-end">
        <Button variant="light" onClick={onPrevious}>
          Back
        </Button>
        <Button
          color="danger"
          isDisabled={
            formData.type === "" ||
            formData.name === "" ||
            formData.description === ""
          }
          onClick={handleSubmit}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default About;
