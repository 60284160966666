export class AgentManager {
  constructor(setMessages) {
    this.ws = null;
    this.init(setMessages);
  }

  static getInstance(setMessages) {
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      this.ws.close();
    }
    if (!this.instance) {
      this.instance = new AgentManager(setMessages);
    }
    return this.instance;
  }

  init(setMessages) {
    if (this.ws) {
      this.ws.onopen = () => {
        console.log("WebSocket connection established.");
      };

      this.ws.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (!message?.message_id) {
          return;
        }

        setMessages((prev) => {
          if (message?.chunk_number === 1) {
            return prev.length > 0 && !prev[prev.length - 1]?.resp_id
              ? prev.map((msg, index) =>
                  index === prev.length - 1
                    ? {
                        ...msg,
                        resp_id: message.message_id,
                        response: message.chunk,
                      }
                    : msg
                )
              : prev;
          } else if (message?.chunk) {
            return prev.map((msg) =>
              msg?.resp_id === message.message_id
                ? { ...msg, response: (msg.response ?? "") + message.chunk }
                : msg
            );
          } else if (message?.similar_questions) {
            return prev.map((msg) =>
              msg?.resp_id === message.message_id
                ? {
                    ...msg,
                    similar_questions: message.similar_questions?.match(
                      /(?<=\d+\.\s)(.*?)(?=\n\s*\d+\.|$)/gs
                    ),
                  }
                : msg
            );
          }
          return prev;
        });
      };

      this.ws.onclose = () => {
        console.log("WebSocket connection closed.");
      };

      this.ws.onerror = (error) => {
        console.error("WebSocket error:", error);
      };
    }
  }

  checkConnection() {
    return !!this.ws && this.ws.readyState === WebSocket.OPEN;
  }

  reconnect(setMessages) {
    if (this.ws) {
      this.ws.close();
    }
    this.ws = new WebSocket(
      `${process.env.REACT_APP_HAYA_WS_ADDRESS}/agent/stream`
    );
    this.init(setMessages);
    console.log("WebSocket reconnected.");
  }

  sendMessage(prompt, setMessages, title, role, goal, backstory) {
    if (!prompt || prompt.trim() === "") {
      return;
    }

    if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
      console.log("WebSocket is not open. Reconnecting...");
      this.reconnect(setMessages);
      this.ws.onopen = () => {
        console.log("WebSocket reconnected. Sending message...");
        this.sendMessage(prompt, setMessages, title, role, goal, backstory);
      };
      return;
    }

    setMessages((prev) => [...prev, { timestamp: Date.now(), query: prompt }]);

    this.ws.send(
      JSON.stringify({
        agent_id: title,
        goal,
        query: prompt,
        role,
        backstory,
        verbose: true,
      })
    );
    console.log("Message sent successfully.");
  }

  stopMessage(setMessages) {
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      this.ws.close();
    }

    setMessages((prev) => {
      if (!prev.length) return prev;

      const lastMessageIndex = prev.length - 1;
      const lastMessage = prev[lastMessageIndex];
      if (lastMessage?.response && lastMessage?.response !== "") {
        return prev;
      }

      return prev.map((msg, i) =>
        i === lastMessageIndex
          ? { ...msg, response: "Response stopped.", resp_id: Date.now() }
          : msg
      );
    });
  }
}
