import { useState } from "react";
import './App.css';
import { NextUIProvider } from '@nextui-org/react';
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import routes from "./routes.json"
import { useEffect } from 'react';
import ServerIssue from "./components/ServerIssue";
import Loading from "./components/Loader/Loading";
import { ThemeProvider as NextThemesProvider } from 'next-themes';
import { Provider } from 'react-redux';
import store from './redux/store';
import { getComponent } from "./getComponent";
import Widget from "react-resolv"
const App = () => {
  // STATE INITIALIZATION
  const [loader, setLoader] = useState(true);
  const [serverIssue, setServerIssue] = useState(false);

  // SERVER TESTING FUNCTION
  const fetchWithTimeout = (url, options, timeout = 5000) => {
    return Promise.race([
      fetch(url, options),
      new Promise((_, reject) =>
        setTimeout(() => reject(new Error('Timeout')), timeout)
      )
    ]);
  };

  // SERVER FETCHING WHEN APP STARTS
  useEffect(() => {
    document.title = "Haya";
    const checkBackendConnection = async () => {
      try {
        const response = await fetchWithTimeout(`${process.env.REACT_APP_STRAPI_IP_ADDRESS}`);
        await fetchWithTimeout(`${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}`);

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        setLoader(false);
      } catch (error) {
        if (error.message.includes('ECONNREFUSED') || error.message === 'Timeout' || error.TypeError) {
          setServerIssue(true);
        }
        else { setServerIssue(true) }
        setLoader(false);
      }
    };
    checkBackendConnection();
  }, []);

  const renderRoutes = (routes) => {
    return routes.map((route, index) => {
      const { path, element, props, nestedRoutes } = route;
      const Component = getComponent(element);

      return (
        <Route
          key={index}
          path={path}
          element={<Component {...props} />}
        >
          {nestedRoutes && renderRoutes(nestedRoutes)}
        </Route>
      );
    });
  };


  // WHEN SERVER HAVE ISSUE THEN IT WILL RENDER SERVERISSUE PAGE
  if (serverIssue) return <ServerIssue />

  // LOADER
  if (loader) {
    return <div className={`absolute z-[100]  inset-0 h-screen w-screen bg-white`}>
      <Loading />
    </div>
  }

  // ROUTING
  return (
    <NextUIProvider>
      <NextThemesProvider defaultTheme='light'>
        <div className={`text-foreground bg-white`}>
          <Toaster
            position="bottom-right"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              style: { boxShadow: "none", border: "1px solid #bdb5b5" },
              success: { duration: 3000 }
            }} />
          <Provider store={store}>
            {/* <Widget.ChatWidget></Widget.ChatWidget> */}
            <BrowserRouter>
              <Routes>{renderRoutes(routes)}</Routes>
            </BrowserRouter>
          </Provider>
        </div>
      </NextThemesProvider>
    </NextUIProvider>
  );
}

export default App;
