import React from 'react';

export const NavbarItems = (params) => {
    const nav1 = [
        {
            icon: <lord-icon
                src="https://cdn.lordicon.com/zyzoecaw.json"
                trigger="hover"
                state="morph-book"
                target="#KnowledgeBase"

                style={{ width: "16px", height: "16px" }}>
            </lord-icon>,
            title: "Overview",
            path: `/${params._spaceid}/`,
            id: "KnowledgeBase",
        },
        {
            icon: <lord-icon
                src="https://cdn.lordicon.com/tzdwqlbp.json"
                trigger="hover"
                state="morph-book"
                target="#Domains"

                style={{ width: "16px", height: "16px" }}>
            </lord-icon>,
            title: "Domains",
            path: `/${params._spaceid}/domains`,
            id: "Domains",
        },
        {
            icon: <lord-icon
                src="https://cdn.lordicon.com/hqymfzvj.json"
                trigger="hover"
                state="morph-book"
                target="#DatabaseProducts"

                style={{ width: "16px", height: "16px" }}>
            </lord-icon>,
            title: "Data Products",
            path: `/${params._spaceid}/data-products`,
            id: "DatabaseProducts",
        },
        {
            icon: <lord-icon
                src="https://cdn.lordicon.com/jkzgajyr.json"
                trigger="hover"
                target="#Collections"
                state="morph-home-2"

                style={{ width: "16px", height: "16px" }}>
            </lord-icon>,
            title: "Collections",
            path: `/${params._spaceid}/collections`,
            id: "Collections"
        },
        // {
        //     icon: <lord-icon
        //         src="https://cdn.lordicon.com/pqirzoux.json"
        //         trigger="hover"
        //         target="#Connectors"
        //         state="morph-home-2"

        //         style={{ width: "16px", height: "16px" }}>
        //     </lord-icon>,
        //     title: "Connectors",
        //     path: `/${params._spaceid}/connectors`,
        //     id: "Connectors"
        // },
        // {
        //     icon: <lord-icon
        //         src="https://cdn.lordicon.com/pflszboa.json"
        //         trigger="hover"
        //         target="#Tool"
        //         state="morph-home-2"

        //         style={{ width: "16px", height: "16px" }}>
        //     </lord-icon>,
        //     title: "Tools",
        //     path: `/${params._spaceid}/tools`,
        //     id: "Tool"
        // }
    ]
    const nav2 = [

        {
            icon: <lord-icon
                src="https://cdn.lordicon.com/prjooket.json"
                trigger="hover"
                target="#Tags"
                state="morph-home-2"

                style={{ width: "16px", height: "16px" }}>
            </lord-icon>,
            title: "Tags",
            path: `/${params._spaceid}/tags`,
            id: "Tags"
        },
        {
            icon: <lord-icon
                src="https://cdn.lordicon.com/fdxqrdfe.json"
                trigger="hover"
                target="#Haya"
                state="morph-home-2"

                style={{ width: "16px", height: "16px" }}>
            </lord-icon>,
            title: "Haya",
            path: `/${params._spaceid}/haya`,
            id: "Haya"
        },

        // {
        //     icon: <lord-icon
        //         src="https://cdn.lordicon.com/ercyvufy.json"
        //         trigger="hover"
        //         target="#Knowledgetree"
        //         state="morph-home-2"
        //         
        //         style={{ width: "16px", height: "16px" }}>
        //     </lord-icon>,
        //     title: "Knowledge Tree",
        //     path: `/${params._spaceid}/knowledge-tree`,
        //     id: "Knowledgetree"
        // },
        // {
        //     icon: <lord-icon
        //         src="https://cdn.lordicon.com/jfwzwlls.json"
        //         trigger="hover"
        //         target="#Analytics"
        //         state="morph-home-2"
        //         
        //         style={{ width: "16px", height: "16px" }}>
        //     </lord-icon>,
        //     title: "Analytics",
        //     path: `/${params._spaceid}/analytics`,
        //     id: "Analytics"
        // },
        {
            icon: <lord-icon
                src="https://cdn.lordicon.com/trkmrggx.json"
                trigger="hover"
                state="hover"
                target="#Keys"

                style={{ width: "16px", height: "16px" }}>
            </lord-icon>,
            title: "Keys",
            path: `/${params._spaceid}/keys`,
            id: "Keys",
        },
        {
            icon: <lord-icon
                src="https://cdn.lordicon.com/lzgmgrnn.json"
                trigger="hover"
                state="morph-book"
                target="#extensions"

                style={{ width: "16px", height: "16px" }}>
            </lord-icon>,
            title: "Agents",
            path: `/${params._spaceid}/agents`,
            id: "extensions",
        },
        {
            icon: <lord-icon
                src="https://cdn.lordicon.com/rpgflpkp.json"
                trigger="hover"
                state="morph-book"
                target="#extensions"

                style={{ width: "16px", height: "16px" }}>
            </lord-icon>,
            title: "Integrations",
            path: `/${params._spaceid}/integrations`,
            id: "extensions",
        },
    ]

    const nav3 = [
        {
            icon: <lord-icon
                src="https://cdn.lordicon.com/ppyvfomi.json"
                trigger="hover"
                target="#Teams"
                state="morph-home-2"

                style={{ width: "16px", height: "16px" }}>
            </lord-icon>,
            title: "Teams",
            path: `/${params._spaceid}/teams`,
            id: "Teams"
        },
        {
            icon: <lord-icon
                src="https://cdn.lordicon.com/ptvmrrcc.json"
                trigger="hover"
                target="#Templates"
                state="morph-home-2"

                style={{ width: "16px", height: "16px" }}>
            </lord-icon>,
            title: "Templates",
            path: `/${params._spaceid}/templates`,
            id: "Templates",
            condition: "Haya"
        },
        {
            icon: <lord-icon
                src="https://cdn.lordicon.com/pmhwxbpm.json"
                trigger="hover"
                target="#playground"
                state="hover"

                style={{ width: "16px", height: "16px" }}>
            </lord-icon>,
            title: "Workbench",
            path: `/${params._spaceid}/workbench`,
            id: "playground",
            condition: "Haya"
        },
        {
            icon: <lord-icon
                src="https://cdn.lordicon.com/kthelypq.json"
                trigger="hover"
                target="#members"
                state="morph-home-2"

                style={{ width: "16px", height: "16px" }}>
            </lord-icon>,
            title: "Users",
            path: `/${params._spaceid}/users`,
            id: "members"
        },
        // {
        //     icon: <lord-icon
        //         src="https://cdn.lordicon.com/vuiggmtc.json"
        //         trigger="hover"
        //         target="#Archive"
        //         state="morph-home-2"
        //         
        //         style={{ width: "16px", height: "16px" }}>
        //     </lord-icon>,
        //     title: "Archive",
        //     path: `/${params._spaceid}/archive`,
        //     id: "Archive"
        // },

        // {
        //     icon: <lord-icon
        //         src="https://cdn.lordicon.com/wpyrrmcq.json"
        //         trigger="hover"
        //         target="#trash"
        //         state="morph-home-2"
        //         
        //         style={{ width: "16px", height: "16px" }}>
        //     </lord-icon>,
        //     title: "Trash",
        //     path: `/${params._spaceid}/trash`,
        //     id: "trash"
        // },


        {
            icon: <lord-icon
                src="https://cdn.lordicon.com/lecprnjb.json"
                trigger="hover"
                target="#settings"
                state="morph-home-2"

                style={{ width: "16px", height: "16px" }}>
            </lord-icon>,
            title: "Settings",
            path: `/${params._spaceid}/settings`,
            id: "settings"
        }
    ]
    return { nav1, nav2, nav3 }
}

export const SubNavbarItems = () => {

    const navbarItem = [

        {
            icon: <lord-icon
                src="https://cdn.lordicon.com/kthelypq.json"

                trigger="hover"
                target="#AnalyticsData"

                style={{ width: "16px", height: "16px" }}>
            </lord-icon>,
            title: "Profile",
            path: "/profile",
            id: "AnalyticsData"
        },
        {
            icon: <lord-icon
                src="https://cdn.lordicon.com/utpmnzxz.json"
                trigger="hover"
                target="#DocumentUpload"

                style={{ width: "16px", height: "16px" }}>
            </lord-icon>,
            title: "Documents Uploaded",
            path: "/all-docs",
            id: "DocumentUpload"
        },
        {
            icon: <lord-icon
                src="https://cdn.lordicon.com/axteoudt.json"
                trigger="hover"
                target="#helpFeedback"

                style={{ width: "16px", height: "16px" }}>
            </lord-icon>,
            title: "Help & Feedback",
            path: "/help",
            id: "helpFeedback"
        },
        {
            icon: <lord-icon
                src="https://cdn.lordicon.com/mdmniuqr.json"
                trigger="hover"
                target="#credits"
                style={{ width: "16px", height: "16px" }}>
            </lord-icon>,
            title: "Credits",
            path: "/credits",
            id: "credits"
        },
        {
            icon: <lord-icon
                src="https://cdn.lordicon.com/prjooket.json"
                trigger="hover"
                target="#ViewSpace"

                style={{ width: "16px", height: "16px" }}>
            </lord-icon>,
            title: "View All Applications",
            path: "/",
            id: "ViewSpace"
        },
        {
            icon: <lord-icon
                src="https://cdn.lordicon.com/vduvxizq.json"
                trigger="hover"
                target="#LogoutData"

                style={{ width: "20px", height: "20px" }}>
            </lord-icon>,
            title: "Logout",
            path: "/sign-in",
            id: "LogoutData"
        },

    ]

    return navbarItem;
}

export const userDropDownItems = (userNavigate, tourHandler, handleChange, setIsTourOpen, logOutHandler) => {
    const items = [
        {
            title: "Profile",
            path: "Profile",
            id: "Profile",
            Click: userNavigate,
            icon: <lord-icon
                src="https://cdn.lordicon.com/kthelypq.json"
                trigger="hover"
                target="#Profile"
                state="morph-home-2"

                style={{ width: "16px", height: "16px" }}>
            </lord-icon>
        },
        {
            title: "Feedback",
            id: "Onboarding",
            Click: handleChange,
            icon: <lord-icon
                src="https://cdn.lordicon.com/zrtfxghu.json"
                trigger="hover"
                target="#Onboarding"

                style={{ width: "20px", height: "20px" }}>
            </lord-icon>
        },
        // {
        //     title: "Help",
        //     id: "Help",
        //     Click: tourHandler,
        //     icon: <lord-icon
        //         src="https://cdn.lordicon.com/axteoudt.json"
        //         trigger="hover"
        //         target="#Help"
        //         
        //         style={{ width: "20px", height: "20px" }}>
        //     </lord-icon>
        // },
        {
            title: "Logout",
            id: "Logout",
            Click: logOutHandler,
            icon: <lord-icon
                src="https://cdn.lordicon.com/vduvxizq.json"
                trigger="hover"
                target="#Logout"

                style={{ width: "20px", height: "20px" }}>
            </lord-icon>
        },
    ]

    return items
}

