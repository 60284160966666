export class ThreadManager {
  constructor(setMessages, app_id, collection_name, setWebSearch) {
    this.ws = new WebSocket(
      `${process.env.REACT_APP_HAYA_WS_ADDRESS}/haya/ws/stream`
    );
    this.init(setMessages, setWebSearch);
    this.app_id = app_id;
    this.collection_name = collection_name;
  }

  static getInstance(setMessages, app_id, collection_name, setWebSearch) {
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      this.ws.close();
    }
    if (!this.instance) {
      this.instance = new ThreadManager(
        setMessages,
        app_id,
        collection_name,
        setWebSearch
      );
    }
    this.instance.app_id = app_id;
    this.instance.collection_name = collection_name;
    return this.instance;
  }

  init(setMessages, setWebSearch) {
    this.ws.onopen = () => {
      console.log("WebSocket connection established.");
    };

    this.ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (!message?.message_id) {
        return;
      }
      setMessages((prev) => {
        if (message?.chunk_number === 1) {
          setWebSearch(false);
          return prev.length > 0 && !prev[prev.length - 1]?.resp_id
            ? prev.map((msg, index) =>
                index === prev.length - 1
                  ? {
                      ...msg,
                      resp_id: message.message_id,
                      response: message.chunk,
                    }
                  : msg
              )
            : prev;
        } else if (message?.chunk) {
          setWebSearch(false);
          return prev.map((msg) =>
            msg?.resp_id === message.message_id
              ? { ...msg, response: (msg.response ?? "") + message.chunk }
              : msg
          );
        } else if (message?.mode) {
          if (message?.mode === "thinking_started") {
            setWebSearch(true);
          } else {
            setWebSearch(false);
          }
        } else if (message?.filtered_documents) {
          return prev.map((msg) =>
            msg?.resp_id === message.message_id
              ? { ...msg, docs: message.filtered_documents }
              : msg
          );
        } else if (
          message?.sql_statement ||
          message?.sql_result ||
          message?.chart
        ) {
          return prev.map((msg) =>
            msg?.resp_id === message.message_id
              ? {
                  ...msg,
                  chart: {
                    sql_statement: message.sql_statement,
                    sql_result: JSON.parse(message?.sql_result),
                    chart: message?.chart,
                    chart_title:
                      message?.chart_title?.replace(/["`]/g, "") ?? "",
                    chart_description:
                      message?.chart_description?.replace(/["`]/g, "") ?? "",
                  },
                  anomaly: message?.anomaly,
                  data_product: message?.data_product,
                }
              : msg
          );
        }
        if (message?.anomaly) {
          return prev.map((msg) =>
            msg?.resp_id === message.message_id
              ? {
                  ...msg,
                  anomaly: message?.anomaly,
                  data_product: message?.data_product,
                }
              : msg
          );
        }
        if (message?.similar_questions) {
          return prev.map((msg) =>
            msg?.resp_id === message.message_id
              ? {
                  ...msg,
                  similar_questions: message.similar_questions?.match(
                    /(?<=\d+\.\s)(.*?)(?=\n\s*\d+\.|$)/gs
                  ),
                }
              : msg
          );
        }

        return prev;
      });
    };

    //   this.ws.onclose = () => {
    //     console.log("WebSocket connection closed. Attempting to reconnect...");
    //     this.reconnect(setMessages);
    //   };
  }

  checkConnection() {
    if (this.ws.readyState !== WebSocket.OPEN) {
      return false;
    } else {
      return true;
    }
  }

  reconnect(setMessages, setWebSearch) {
    this.ws = new WebSocket(
      `${process.env.REACT_APP_HAYA_WS_ADDRESS}/haya/ws/stream`
    );
    this.init(setMessages, setWebSearch);
  }

  sendMessage(prompt, setMessages, userId, thread_id, setWebSearch) {
    // Check if WebSocket is open before sending the message
    if (this.ws.readyState === WebSocket.CONNECTING) {
      console.log("WebSocket is still connecting. Message will be queued.");
      this.ws.onopen = () =>
        this.sendMessage(prompt, setMessages, userId, thread_id, setWebSearch);
      return;
    } else if (this.ws.readyState !== WebSocket.OPEN) {
      console.log("WebSocket is not open. Reconnecting...");
      this.reconnect(setMessages, setWebSearch);
      this.ws.onopen = () =>
        this.sendMessage(prompt, setMessages, userId, thread_id, setWebSearch);
      return;
    }

    if (!(prompt && prompt !== "")) {
      return;
    }

    setMessages((prev) => [...prev, { timestamp: Date.now(), query: prompt }]);
    this.ws.send(
      JSON.stringify({
        user_id: userId.toString(),
        app_id: this.app_id.toString(),
        query: prompt,
        collection_name: this.collection_name,
        thread_id: thread_id,
      })
    );
  }

  stopMessage(setMessages) {
    if (this.ws && this.ws?.readyState === WebSocket.OPEN) {
      this.ws.close();
    }

    setMessages((prev) => {
      if (!prev.length) return prev; // Prevent errors if prev is empty

      const lastMessageIndex = prev.length - 1;
      const lastMessage = prev[lastMessageIndex];
      if (lastMessage?.response && lastMessage?.response !== "") {
        return prev; // No need to update if last response is not empty
      }

      return prev.map((msg, i) =>
        i === lastMessageIndex
          ? { ...msg, response: "Stopped from generating" }
          : msg
      );
    });
  }
}
